import React, { useState } from "react";
import img1 from "../assets/icons/lineal/shopping-basket.svg";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
function Pricing() {
  const [typePricing, setTypePricing] = useState(true);
  const [hover, setHover] = useState(false);
  const [numberSms, setNumberSms] = useState("");
  const [loading, setLoading] = useState(false);
  const [countries, setcountries] = useState();
  const [search, setsearch] = useState("");

  const handleChange = (e) => {
    handleGetPrice(currency, e.target.value);

    setNumberSms(e.target.value);
    if (+e.target.value > 900) {
      setHover(false);
    } else {
      setHover(true);
    }
  };
  const [t] = useTranslation();
  const { token, user } = useSelector((state) => state.auth);
  const [currency2, setCurrency2] = useState(
    user?.userData?.country?.id || "659074f24d0db9081f87e4cc"
  );
  const [allPakage, setAllPakage] = useState([]);
  const getPricing = async (e) => {
    // console.log(
    //   `https://togethersms.com/priceConfig/find-user-prices?searchField=${e}`
    // );
    console.log(currency2, e);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/fixedPriceConfig/find-user-fixedPrices?searchField=${e}`,
        config
      );
      console.log(res, "ASd");
      setAllPakage(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getDataGetway = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/country/user-countries`,

        config,
        config
      );
      console.log(res.data);
      setLoading(false);
      if (res) {
        setcountries(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  useEffect(() => {
    // console.log(user)
    getPricing(user?.userData?.country?.id || "659074f24d0db9081f87e4cc");
    getDataGetway();
  }, []);

  const [currency, setCurrency] = useState("");
  const [allPrice, setAllPrice] = useState("");

  const handleGetPrice = async (currency, numberSms) => {
    console.log(currency, numberSms);
    // setLoading(true);
    if (currency && numberSms) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(
          `https://togethersms.com/priceConfig/${currency}/${numberSms}`,

          config
        );
        console.log(res);
        setLoading(false);
        if (res.status === 200) {
          setAllPrice(res.data.price * numberSms);
        } else {
          console.log(res);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };
  const navigate = useNavigate();

  return (
    <div>
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-20 pt-md-14 pb-md-22 text-center">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <h1 className="display-1 mb-3 fs-30">Our Pricing</h1>
              <p className="lead mb-0 px-xl-10 px-xxl-13 fs-18">
                We offer great prices, premium products and quality service for
                your business.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* /section */}
      <section className="wrapper">
        <div className="container pb-14 pb-md-16">
          <div className="pricing-wrapper position-relative mt-n18 mt-md-n21 mb-12 mb-md-15">
            <div
              className="shape  primary rellax w-16 h-18"
              data-rellax-speed={1}
              style={{ top: "2rem", right: "-2.4rem" }}
            />
            <div
              className="shape rounded-circle  red rellax w-18 h-18 d-none d-lg-block"
              data-rellax-speed={1}
              style={{ bottom: "0.5rem", left: "-2.5rem" }}
            />
            <div className="pricing-switcher-wrapper switcher">
              <p className="mb-0 pe-3">offer</p>
              <div
                className="pricing-switchers"
                onClick={() => setTypePricing(!typePricing)}
              >
                <div className="pricing-switcher pricing-switcher-active" />
                <div className="pricing-switcher" />
                <div
                  className="switcher-button bg-primary"
                  style={typePricing ? { left: "0px" } : { left: "50%" }}
                />
              </div>
              <p className="mb-0 ps-3">calc message price</p>
            </div>
            {typePricing ? (
              <div className="row gy-6 mt-3 mt-md-5">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={currency2}
                  onChange={(e) => {
                    getPricing(e.target.value);
                    setCurrency2(e.target.value);
                  }}
                  defaultValue={"1"}
                >
                  <option value={"1"}> {t("Select Country")}</option>
                  {countries?.map((e, index) => (
                    <option value={e._id} key={index}>
                      {e.countryName}
                    </option>
                  ))}
                </select>
                {allPakage.length > 0 ? (
                  allPakage.map((e, index) => (
                    <div className="col-md-6 col-lg-4" key={index}>
                      <div className="pricing card text-center">
                        <div className="card-body w-100">
                          <img
                            src={img1}
                            className="svg-inject icon-svg icon-svg-md text-primary mb-3"
                            alt=""
                          />
                          <h4 className="card-title">
                            {e.fixedMessageCount}point
                          </h4>
                          <div className="prices text-dark w-100">
                            <div className="price price-show w-100">
                              <span className="price-currency w-100 px-0">
                                {e.fixedMessagePrice} EGP
                              </span>
                            </div>
                            <button
                              onClick={() => {
                                console.log("e", e);
                                sessionStorage.setItem(
                                  "packegeRecharge",
                                  JSON.stringify(e)
                                );
                                navigate(`/recharge-add/packege`);
                              }}
                              type="submit"
                              className="btn btn-primary rounded-pill btn-login  mt-8"
                            >
                              {t("Pay")}{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    {" "}
                    {!loading && (
                      <h2 className="d-flex align-items-center justify-content-center">
                        Not Found
                      </h2>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className=" col-md-12 col-lg-8 m-auto mt-10 card">
                <form>
                  <div className="card-body">
                    <div className="form-select-wrapper mb-4">
                      <select
                        className="form-select"
                        required
                        onChange={(e) => {
                          handleGetPrice(e.target.value, search);
                          setCurrency(e.target.value);
                        }}
                      >
                        <option value="">Currency</option>
                        {countries?.map((e) => (
                          <option value={e._id}>{e.countryName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-floating mb-4">
                      <input
                        id="textInputExample"
                        type="text"
                        className="form-control"
                        placeholder="Text Input"
                        onClick={() => setHover(true)}
                        onChange={(e) => {
                          setsearch(e.target.value);
                          handleChange(e);
                        }}
                        required
                      />
                      <label htmlFor="textInputExample">SMS no.</label>
                    </div>
                    {hover && (
                      <div
                        className="alert alert-warning alert-icon"
                        role="alert"
                      >
                        <i className="uil uil-exclamation-triangle" /> A simple
                        Amount must be greater than{" "}
                        <div className="alert-link hover">1000 message(s)</div>.
                      </div>
                    )}

                    {/* <div className="form-select-wrapper mb-4">
                      <select className="form-select" required>
                        <option value="">Pay use</option>
                        <option value="one">One</option>
                      </select>
                    </div> */}
                    <div className="">
                      {/* <a class="btn btn-expand btn-primary rounded-pill">
                      <i class="uil uil-arrow-right"></i>
                      <span>Calc</span>
                    </a> */}

                      <button
                        type="submit"
                        className="btn btn-primary rounded-pill btn-login  mb-2"
                        // disabled={loading ? true : false}
                        onClick={() => {
                          sessionStorage.setItem(
                            "packegeRecharge",
                            JSON.stringify({
                              country: currency2,
                              createdAt: "2023-11-25T17:23:13.823Z",
                              currency: currency2,
                              display: true,
                              fixedMessageCount: numberSms,
                              fixedMessagePrice: allPrice,
                              packageType: "Starter Package",
                              updatedAt: "2023-11-25T17:23:13.823Z",
                              _id: "65622d81a65e1ae54ee6af8f",
                            })
                          );
                          navigate(`/recharge-add/packege`);
                        }}
                      >
                        {/* {loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                          </Spinner>
                        ) : null} */}
                        {t("Pay")}{" "}
                      </button>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                      {numberSms > 999 && (
                        <div className="btn btn-primary rounded-pill p-5 d-flex flex-column gap-2">
                          <div className="d-flex gap-2">
                            Quantity:
                            {numberSms}
                          </div>
                          <div className="">
                            Price:
                            {allPrice}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            )}
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-6 mb-14 mb-md-19">
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-circle pe-none btn-primary me-4">
                    {" "}
                    <i className="uil uil-phone-volume" />{" "}
                  </div>
                </div>
                <div>
                  <h4>24/7 Support</h4>
                  <p className="mb-2">
                    Nulla vitae elit libero, a pharetra augue. Donec id elit non
                    mi porta gravida at eget. Fusce dapibus tellus.
                  </p>
                  <div href="#" className="more hover">
                    Learn More
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-circle pe-none btn-primary me-4">
                    {" "}
                    <i className="uil uil-laptop-cloud" />{" "}
                  </div>
                </div>
                <div>
                  <h4>Daily Updates</h4>
                  <p className="mb-2">
                    Maecenas faucibus mollis interdum. Vivamus sagittis lacus
                    vel augue laoreet. Sed posuere consectetur.
                  </p>
                  <a href="#" className="more hover">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-row">
                <div>
                  <div className="icon btn btn-circle pe-none btn-primary me-4">
                    {" "}
                    <i className="uil uil-chart-line" />{" "}
                  </div>
                </div>
                <div>
                  <h4>Market Research</h4>
                  <p className="mb-2">
                    Cras justo odio, dapibus ac facilisis in, egestas eget quam.
                    Praesent commodo cursus magna scelerisque.
                  </p>
                  <a href="#" className="more hover">
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <h2 className="display-4 mb-3 text-center">Pricing FAQ</h2>
          <p className="lead text-center mb-10 px-md-16 px-lg-0">
            If you don't see an answer to your question, you can send us an
            email from our contact form.
          </p>
          <div className="row mb-14 mb-md-18">
            <div className="col-lg-6 mb-0">
              <div id="accordion-1" className="accordion-wrapper">
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-1">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-1"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-1"
                    >
                      Can I cancel my subscription?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-1"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-1"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-2">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-2"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-2"
                    >
                      Which payment methods do you accept?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-2"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-2"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-3">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-3"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-3"
                    >
                      How can I manage my Account?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-3"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-3"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-1-4">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-1-4"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-1-4"
                    >
                      Is my credit card information secure?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-1-4"
                    className="collapse"
                    aria-labelledby="accordion-heading-1-4"
                    data-bs-target="#accordion-1"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div id="accordion-2" className="accordion-wrapper">
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-1">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-1"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-1"
                    >
                      How do I get my subscription receipt?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-1"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-1"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-2">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-2"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-2"
                    >
                      Are there any discounts for people in need?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-2"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-2"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-3">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-3"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-3"
                    >
                      Do you offer a free trial edit?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-3"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-3"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card accordion-item">
                  <div className="card-header" id="accordion-heading-2-4">
                    <button
                      className="collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#accordion-collapse-2-4"
                      aria-expanded="false"
                      aria-controls="accordion-collapse-2-4"
                    >
                      How do I reset my Account password?
                    </button>
                  </div>
                  <div
                    id="accordion-collapse-2-4"
                    className="collapse"
                    aria-labelledby="accordion-heading-2-4"
                    data-bs-target="#accordion-2"
                  >
                    <div className="card-body">
                      <p>
                        Fusce dapibus, tellus ac cursus commodo, tortor mauris
                        condimentum nibh, ut fermentum massa justo sit amet
                        risus. Cras mattis consectetur purus sit amet fermentum.
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur et. Cum sociis natoque penatibus et magnis
                        dis parturient montes, nascetur ridiculus mus. Donec sed
                        odio dui. Cras justo odio, dapibus ac facilisis.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
