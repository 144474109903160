import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loginStart, loginSuccess } from "../store/slices/authSlice";
import { useDispatch } from "react-redux";

function LoginAdminInUser() {
  const { phone, code, token } = useParams();
  console.log(phone, code, token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleVerfiy = async () => {
    let data = {
      emailOrPhoneNumber: phone,
    };
    console.log(token);
    // setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        "https://togethersms.com/user/admin-confirm-mobile-verification/" + code,
        data,
        config
      );
      console.log(res, "res");

      if (res) {
        dispatch(loginStart(res?.data));

        navigate("/");
        console.log(res);
        // setLoading(false);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleVerfiy();
  }, []);
  return <div></div>;
}

export default LoginAdminInUser;
