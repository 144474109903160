import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { logout } from "../../store/slices/authSlice";
import { IoIosSend } from "react-icons/io";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaUsers } from "react-icons/fa6";
import { BiArchiveIn, BiSolidReport } from "react-icons/bi";
import { MdAccessTimeFilled } from "react-icons/md";
import { IoDocumentTextSharp } from "react-icons/io5";
import { ImBlocked } from "react-icons/im";

const CartsPage = () => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/user-dashboard/${user?.userData?.country?.countryName}`,

        config,
        config
      );
      console.log(res);
      setData(res.data);
    } catch (error) {
      console.log(error);
      // if (
      //   error?.response?.data?.message ==
      //     "Token is invalid. Please log in again." ||
      //   "Unauthorized"
      // ) {
      //   console.log("done");
      //   dispatch(logout());
      // }
      //  setError(true);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Row>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box3 rounded-0`}>
          <Link className=" gap-1 w-100" to="/send-message">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("Send SMS")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-primary"}>
                    <IoIosSend className="fs-40 link-primary" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box1 rounded-0`}>
          <Link className=" gap-1 w-100" to="/recharge-archieve">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("Recharge Balance")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-blue"}>
                    <RiMoneyDollarCircleLine className="fs-40 link-blue" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box2 rounded-0`}>
          <Link className=" gap-1 w-100" to="/contacts">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("Groups & Contacts")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-danger"}>
                    <FaUsers
                      className="fs-40 link-primary"
                      style={{ color: "rgb(143, 26, 88)" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box3 rounded-0`}>
          <Link className=" gap-1 w-100" to="/message-finished">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("SMS Reports")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-primary"}>
                    <BiSolidReport className="fs-40 link-primary" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box4 rounded-0`}>
          <Link className=" gap-1 w-100" to="/pending-sms">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("Pending Tasks")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-green"}>
                    <MdAccessTimeFilled
                      className="fs-40 link-primary"
                      style={{ color: "#45c4a0" }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box3 rounded-0`}>
          <Link className=" gap-1 w-100" to="/message-archive">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("SMS Draft")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-primary"}>
                    <BiArchiveIn className="fs-40 link-primary" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box4 rounded-0`}>
          <Link className=" gap-1 w-100" to="/api-doc">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("API Documentation")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-green"}>
                    <IoDocumentTextSharp className="fs-40 link-green" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-4 p-1">
        <Card className={`card-animate box1 rounded-0`}>
          <Link className=" gap-1 w-100" to="/black-list-contact">
            <div className="p-2">
              <div className="d-flex align-items-end justify-content-between mt-4">
                <div>
                  <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                    <span
                      className="counter-value d-flex align-items-center gap-1"
                      data-target="559.25"
                    >
                      {t("Blocked Contacts")}
                    </span>
                  </h4>
                </div>

                <div className="avatar-sm flex-shrink-0">
                  <span className={"avatar-title rounded fs-3 bg-soft-blue"}>
                    <ImBlocked className="fs-40 link-blue" />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </Card>
      </Col>
    </Row>
  );
};

export default CartsPage;
