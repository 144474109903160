import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Contact() {
  const [t, i18n] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section
        className="wrapper wrapper bg-soft-primary"
        // data-image-src="./assets/img/photos/bg3.jpg"
      >
        <div className="container pt-10 pb-10 pt-md-10 pb-md-21 text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3 ">{t("Contact Us")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/contact"}> {t("Contact Us")}</Link>
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light angled upper-end">
        <div className="container pb-11">
          <div className="row mb-14 mb-md-16">
            <div className="col-xl-10 mx-auto mt-n19">
              <div className="card">
                <div className="row gx-0">
                  <div className="col-lg-6 align-self-stretch">
                    <div className="map map-full rounded-top rounded-lg-start">
                      <div style={{ width: "100%" }}>
                        <iframe
                          title="Google Maps"
                          width="100%"
                          height="600"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(Together%20Information%20System)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        >
                          <a href="https://www.gps.ie/">gps tracker sport</a>
                        </iframe>
                      </div>
                    </div>
                    {/* /.map */}
                  </div>
                  {/*/column */}
                  <div className="col-lg-6">
                    <div className="p-10 p-md-11 p-lg-14">
                      <h3 className="fs-20 text-primary mb-3">
                        {t("Together Marketing Company")}
                      </h3>
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            {" "}
                            <i className="uil uil-location-pin-alt" />{" "}
                          </div>
                        </div>
                        <div className="align-self-start justify-content-start">
                          <h5 className="mb-1">Address</h5>
                          <address className="fs-15">
                            {t("Address: 14 Mostafa Elnahas st")}
                            <br className="d-none d-md-block" />
                            {t(", National united bank building")}
                          </address>
                          <address className="fs-15">
                            {t("Cairo, Egypt. BO. 11471")}
                          </address>
                        </div>
                      </div>
                      {/*/div */}
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            {" "}
                            <i className="uil uil-phone-volume" />{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">{t("Phone")}</h5>
                          <p className="fs-15">
                            (+20) 111 113 3657
                            <br />
                          </p>
                        </div>
                      </div>
                      {/*/div */}
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 me-4 mt-n1">
                            {" "}
                            <i className="uil uil-envelope" />{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">{t("E-mail")}</h5>
                          <p className="mb-0 fs-15">
                            <a
                              href="mailto:sandbox@email.com"
                              className="link-body"
                            >
                              info@togetherpro.com
                            </a>
                          </p>
                        </div>
                      </div>
                      {/*/div */}
                    </div>
                    {/*/div */}
                  </div>
                  {/*/column */}
                </div>
                {/*/.row */}
              </div>
              {/* /.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
              <h2 className="display-4 mb-3 text-center">{t("Contact Us")}</h2>
              <p className="lead text-center mb-10">
                {t(
                  "Reach out to us from our contact form and we will get back to you shortly."
                )}
              </p>
              <form
                className="contact-form needs-validation"
                method="post"
                action="./assets/php/contact.php"
                noValidate
              >
                <div className="messages" />
                <div className="row gx-4">
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_name"
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Jane"
                        required
                      />
                      <label htmlFor="form_name">{t("Name")} *</label>
                      <div className="valid-feedback">{t("Looks good!")}</div>
                      <div className="invalid-feedback">
                        {" "}
                        {t("Please enter your name.")}
                      </div>
                    </div>
                  </div>
                  {/* /column */}

                  {/* /column */}
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="jane.doe@example.com"
                        required
                      />
                      <label htmlFor="form_email">{t("Email")} *</label>
                      <div className="valid-feedback"> {t("Looks good!")} </div>
                      <div className="invalid-feedback">
                        {" "}
                        {t("Please provide a valid email address.")}
                      </div>
                    </div>
                  </div>
                  {/* /column */}
                  <div className="col-md-6">
                    <div className="form-floating mb-4">
                      <input
                        id="form_email"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="jane.doe@example.com"
                        required
                      />
                      <label htmlFor="form_email">{t("Message title")} *</label>
                      <div className="valid-feedback"> {t("Looks good!")} </div>
                      <div className="invalid-feedback">
                        {t("Please provide a valid Message title address.")}
                      </div>
                    </div>
                  </div>
                  {/* /column */}
                  <div className="col-12">
                    <div className="form-floating mb-4">
                      <textarea
                        id="form_message"
                        name="message"
                        className="form-control"
                        placeholder="Your message"
                        style={{ height: "150px" }}
                        required
                        defaultValue={""}
                      />
                      <label htmlFor="form_message">{t("Message")} *</label>
                      <div className="valid-feedback"> {t("Looks good!")} </div>
                      <div className="invalid-feedback">
                        {t("Please enter your messsage")}
                      </div>
                    </div>
                  </div>
                  {/* /column */}
                  <div className="col-12 text-center">
                    <input
                      type="submit"
                      className="btn btn-primary rounded-pill btn-send mb-3"
                      defaultValue="Send message"
                    />
                    <p className="text-muted">
                      <strong>*</strong> {t("These fields are required.")}
                    </p>
                  </div>
                  {/* /column */}
                </div>
                {/* /.row */}
              </form>
              {/* /form */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
    </div>
  );
}
