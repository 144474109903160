import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Modal } from "@mantine/core";
import Individuals from "./Individuals";
import SelectNumbers from "./SelectNumbers";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
export default function ModalBox({
  setModalOpened,
  modalOpened,
  setSelectedRows,
  selectedRows,
  deleteGroup,
  setSelectGroupContacts,
  selectedGroupContacts,
}) {
  const [t, i18n] = useTranslation();

  return (
    <>
      <Dialog
        visible={modalOpened}
        style={{ width: "40rem", height: "100%" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={
          <div class="form-select-wrapper  col-12 mb-4 ">
            <div class=" rounded-pill">{t("Select Contacts")}</div>
          </div>
        }
        modal
        className="p-fluid"
        // footer={productDialogFooter}
        onHide={() => setModalOpened(false)}
      >
        <SelectNumbers
          setSelectGroupContacts={setSelectGroupContacts}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          deleteGroup={deleteGroup}
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
          selectedGroupContacts={selectedGroupContacts}
        />
      </Dialog>
      {/* <Modal
        overlayopacity={0.55}
        overlayblur={3}
        size="55%"
        zIndex={10000}
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
      ></Modal> */}
    </>
  );
}
