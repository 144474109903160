import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Aside from "../../components/Aside";
import RechargeArchieveTable from "./RechargeArchieveTable";

function RechargeArchieve() {
  const [t, i18n] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  return (
    <div className="content-wrapper">
      {/* <Header /> */}

      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display31 mb-3">{t("Recharge archieve")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/recharge-archieve"}>
                      {" "}
                      {t("Recharge archieve")}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-7" className="wrapper py-10">
              <div
                onClick={() => {
                  sessionStorage.removeItem("packegeRecharge");
                  navigate("/recharge-add");
                }}
                className="btn  btn-red  mb-5"
              >
                {/* <i className="uil uil-arrow-right"></i> */}
                <span>{t("Add Recharge")}</span>
              </div>
              <div className="card">
                <RechargeArchieveTable />
              </div>
            </section>
          </div>
          {/* <Aside/> */}
        </div>
      </div>
    </div>
  );
}

export default RechargeArchieve;
