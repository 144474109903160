import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/AllLoginAndRegister/Login";
import Register from "./pages/AllLoginAndRegister/Register";
import { useEffect, useState } from "react";
import SendMessage from "./pages/SendMessage/SendMessage";
import Individuals from "./pages/SendMessage/Individuals";
import SendToMobile from "./pages/AllLoginAndRegister/SendToMobile";
import RechargeArchieve from "./pages/ReachrgePoints/RechargeArchieve";
import RechargeAdd from "./pages/ReachrgePoints/RechargeAdd";
import AddSenderName from "./pages/SenderName/AddSenderName";
import SenderNamesList from "./pages/SenderName/SenderNamesList";
import UserInformation from "./pages/UserInformation/UserInformation";
import Contact from "./pages/ContactUs.js/Contact";
import Pricing from "./components/Pricing";
import Footer from "./pages/Footer";
import Header from "./components/Header";
import "./pages/flags.css";
import Contacts from "./pages/Groups/Groups";
import SmsproGroupsGrid from "./pages/Contatcts/SmsproGroupsGrid";

import Confirm from "./pages/SendMessage/Confirm";
import MessageArchive from "./pages/MessageArchieve/MessageArchieve";
import PendingSms from "./pages/PendingSms/PendingSms";
import SendToEmail from "./pages/AllLoginAndRegister/SendToEmail";
import EnterEmail from "./pages/AllLoginAndRegister/EnterEmail";
import SendCodeResetPass from "./pages/AllLoginAndRegister/SendCodeRestPass";
import ResetPassword from "./pages/AllLoginAndRegister/ResetPassword";
import EnterPhone from "./pages/AllLoginAndRegister/EnterPhone";
import CompleteData from "./pages/AllLoginAndRegister/CompleteData";
import { useDispatch, useSelector } from "react-redux";
import Home from "./pages/Home";
import LoginAdminInUser from "./components/LoginAdminInUser";
import BlackList from "./pages/BlackList/BlackList";
import MessageFinished from "./pages/MessageFinished/MessageFinished";
import ApiDoc from "./pages/ApiDoc/ApiDoc";
import { loginSuccess, logout } from "./store/slices/authSlice";
import axios from "axios";
import RechargeAddPackege from "./pages/ReachrgePoints/RechargeAddPackege";
import ContentAddSenderName from "./pages/SenderName/ContentAddSenderName";
import ContentRechargeAdd from "./pages/ReachrgePoints/ContentRecahrgeAdd";
import HeaderLogin from "./components/HeaderLogin";
import SMSCharacterCounter from "./pages/AllLoginAndRegister/SMSCharacterCounter";
import "react-datepicker/dist/react-datepicker.css";
import Success from "./pages/Success/Success";
import SendFaildNumbers from "./pages/MessageArchieve/SendFaildNumbers";
import Blog from "./pages/Blog/Blog";
import Explan from "./pages/Explan/Explan";
import HomeUser from "./pages/HomeUser/HomeUser";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
// import messaging from "./firebase";
// import "./firebase"

function App() {
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  // useEffect(()=>{
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       console.log("Notification permission granted.");
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       console.log("FCM Token:", token);
  //       // Send this token to your backend to save it for later use
  //     })
  //     .catch((err) => {
  //       console.log("Unable to get permission to notify.", err);
  //     });
  // },[])
  // Access the path from the location object
  const currentPath = location.pathname;
  console.log(currentPath);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const page = window.location.pathname.split("/")[1];

  window.addEventListener("scroll", toggleVisibility);
  const [selectedOption, setSelectedOption] = useState(null);
  const getUser = async () => {
    console.log(user);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          // withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/user/${user?.userData?._id}`,

        config
      );
      //   setLoading(false);
      dispatch(loginSuccess(res.data));

      console.log("user", res);
    } catch (error) {
      console.log(error);
      if (
        error?.response?.data?.message ==
          "Token is invalid. Please log in again." ||
        "Unauthorized"
      ) {
        console.log("done");
        dispatch(logout());
      }

      // return custom error message from backend if present
      //   setLoading(false);
    }
  };
  useEffect(() => {
    if (user?.userData?._id) {
      getUser();
    }
  }, [page]);

  const [t, i18n] = useTranslation();

  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getData = async () => {
    try {
      const res = await axios.get(
        `https://togethersms.com/MetaDescription/findAll-metaDesc?lang=${i18n.language}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Accept-Language": i18n.language,
          },
        }
      );
      console.log(res, "meta");
      setData(res?.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{data?.title_en || data?.title_ar}</title>
        <meta property="og:image" content={data?.image_light} />
        <link rel="icon" type="image/svg+xml" href={data?.image_light} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content={data?.description_en || data?.description_ar}
        />
        <link
          rel="icon"
          type="image/svg+xml"
          href="/public/photo_2023-12-12_15-13-04-removebg-preview 1 (1).png"
        />
        <meta charset="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=0"
        />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:image" content={data?.image_light} />
        <link rel="apple-touch-icon" sizes="180x180" href={data?.image_light} />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={data?.image_light}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={data?.image_light}
        />
        <link rel="mask-icon" href={data?.image_light} color="#00b4b6" />
        <meta name="Keywords" content={data?.keywords} />
      </Helmet>
      {currentPath != "/sms-character-counter" && (
        <>{user ? <HeaderLogin /> : <Header />}</>
      )}
      <Routes>
        <Route
          element={<SMSCharacterCounter />}
          path="/sms-character-counter"
        />
        {/* <Route element={<Login />} path="/" /> */}
        <Route element={user ? <HomeUser /> : <Home />} path="/" />
        <Route element={<Login />} path="/login" />
        <Route element={<Pricing />} path="/pricing" />
        <Route element={<SendToMobile />} path="/send-mobile" />
        <Route element={<SendToEmail />} path="/send-email" />
        <Route element={<EnterEmail />} path="/enter-email" />
        <Route element={<EnterPhone />} path="/enter-phone" />
        <Route element={<ResetPassword />} path="/reset-password" />
        <Route element={<SendCodeResetPass />} path="/send-code-rest-pass" />
        <Route element={<Register />} path="/register" />
        <Route element={<CompleteData />} path="/complete-data" />
        <Route
          element={
            user ? (
              <SendMessage
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            ) : (
              <Login />
            )
          }
          path="/send-message"
        />
        <Route element={<SendFaildNumbers />} path="/send-message/:id" />
        <Route element={<Blog />} path="/explans" />
        <Route element={<Explan />} path="/explan/:id" />
        {/* <Route element={<Phone />} path="/phone" /> */}
        <Route
          element={
            <Individuals
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          }
          path="/send-message/individuals"
        />

        <Route
          element={user ? <RechargeArchieve /> : <Login />}
          path="/recharge-archieve"
        />
        <Route
          element={user ? <RechargeAdd /> : <Login />}
          path="/recharge-add"
        />
        <Route
          element={user ? <RechargeAdd /> : <Login />}
          path="/recharge-add/packege"
        />
        <Route
          element={user ? <AddSenderName /> : <Login />}
          path="/add-sender-name"
        />
        <Route
          element={user ? <ContentAddSenderName /> : <Login />}
          path="/content-add-sender-name"
        />
        <Route
          element={user ? <ContentRechargeAdd /> : <Login />}
          path="/content-recharge-add"
        />

        <Route
          element={user ? <SenderNamesList /> : <Login />}
          path="/sender-names-list"
        />
        <Route
          element={user ? <UserInformation /> : <Login />}
          path="/user-info"
        />
        <Route element={<Contact />} path="/contact" />
        <Route element={<Success />} path="/success" />
        <Route element={user ? <Contacts /> : <Login />} path="/contacts" />
        <Route element={<Confirm />} path="/confirm" />
        <Route
          element={user ? <SmsproGroupsGrid /> : <Login />}
          path="/smspro-groups_grid/:id"
        />
        <Route element={<ApiDoc />} path="/api-doc" />
        <Route
          element={<LoginAdminInUser />}
          path="/home/:phone/:code/:token"
        />

        <Route
          element={user ? <MessageArchive /> : <Login />}
          path="/message-archive"
        />
        <Route
          element={user ? <BlackList /> : <Login />}
          path="/black-list-contact"
        />
        <Route
          element={user ? <MessageFinished /> : <Login />}
          path="/message-finished"
        />
        <Route
          element={user ? <PendingSms /> : <Login />}
          path="/pending-sms"
        />
      </Routes>

      {currentPath != "/sms-character-counter" && (
        <>
          {" "}
          <Footer />
        </>
      )}
      {/* <div
        className={`scroll-to-top ${isVisible ? "visible" : ""}`}
        onClick={scrollToTop}
      >
        &#9650;
      </div> */}
    </>
  );
}

export default App;
