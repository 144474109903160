import axios from "axios";
import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import { logout } from "../../store/slices/authSlice";

const Widgets = () => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [last, setlast] = useState([]);
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/user-dashboard/${user?.userData?.country?.countryName}`,

        config,
        config
      );
      console.log(res);
      setData(res.data);
    } catch (error) {
      console.log(error);
      // if (
      //   error?.response?.data?.message ==
      //     "Token is invalid. Please log in again." ||
      //   "Unauthorized"
      // ) {
      //   console.log("done");
      //   dispatch(logout());
      // }
      //  setError(true);
    }
  };
  const getDatalast = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/payment/get-last-payment`,

        config
      );
      console.log(res, "res,res");
      setlast(res.data);
    } catch (error) {
      console.log(error);
      // if (
      //   error?.response?.data?.message ==
      //     "Token is invalid. Please log in again." ||
      //   "Unauthorized"
      // ) {
      //   console.log("done");
      //   dispatch(logout());
      // }
      //  setError(true);
    }
  };
  useEffect(() => {
    getDatalast();
    getData();
  }, []);
  return (
    <Row className="mb-5">
      <Col xl={3} md={6} className="mb-2 p-1">
        <Card className={`card-animate box3 rounded-0`}>
          <div className="p-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  {t("Current Balance")}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-danger mb-4">
                  <span
                    className="counter-value d-flex align-items-center gap-1"
                    data-target="559.25"
                  >
                    <CountUp
                      start={0}
                      // prefix={"Egp"}
                      // suffix={"N"}
                      separator={","}
                      end={data?.currentBalance}
                      decimals={"0"}
                      duration={4}
                    />
                    {t("EGP")}
                  </span>
                </h4>
              </div>

              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-primary"}>
                  <i
                    style={{ color: "#fab758" }}
                    className={`uil-dollar-alt fs-40  link-blue`}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </Card>
      </Col>

      <Col xl={3} md={6} className="mb-2 p-1">
        <Card className={`card-animate box4 rounded-0`}>
          <div className="p-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  {t("Total Send")}
                </p>
              </div>
              {/* <div className="flex-shrink-0">
                <h5 className={"fs-14 mb-0 text- success"}>
                  <i
                    className={"fs-13 align-middle ri-arrow-right-up-line"}
                  ></i>
                  20 %
                </h5>
              </div> */}
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span
                    className="counter-value d-flex align-items-center gap-1"
                    data-target="559.25"
                  >
                    <CountUp
                      start={0}
                      // prefix={"Egp"}
                      // suffix={"N"}
                      separator={","}
                      end={data?.totalSentPoints}
                      decimals={"0"}
                      duration={4}
                    />
                    {t("SMS")}
                  </span>
                </h4>
              </div>

              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-green"}>
                  <i
                    style={{ color: "#45c4a0" }}
                    className={` uil-message fs-40  link-blue`}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </Card>
      </Col>

      <Col xl={3} md={6} className="mb-2 p-1">
        <Card className={`card-animate box2 rounded-0`}>
          <div className="p-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  {t("Last Top Up")}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-danger mb-4">
                  <span
                    className="counter-value d-flex align-items-center gap-1"
                    data-target="559.25"
                  >
                    {console.log(last, "last")}
                    <CountUp
                      start={0}
                      // prefix={"Egp"}
                      // suffix={"N"}
                      separator={","}
                      end={last?.paidAmount ? last?.paidAmount : 0}
                      decimals={"0"}
                      // duration={4}
                    />
                    {t("EGP")}
                  </span>
                </h4>
              </div>

              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-danger"}>
                  <i
                    style={{ color: "rgb(143, 26, 88)" }}
                    className={`uil-dollar-alt fs-40  link-blue`}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col xl={3} md={6} className="mb-2 p-1">
        <Card className={`card-animate box1 rounded-0`}>
          <div className="p-2">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 overflow-hidden">
                <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                  {t("Your Clients")}
                </p>
              </div>
              {/* <div className="flex-shrink-0">
                <h5 className={"fs-14 mb-0 text- success"}>
                  <i
                    className={"fs-13 align-middle ri-arrow-right-up-line"}
                  ></i>
                  20 %
                </h5>
              </div> */}
            </div>
            <div className="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 className="fs-20 fw-semibold ff-secondary mb-4">
                  <span
                    className="counter-value d-flex align-items-center gap-1"
                    data-target="559.25"
                  >
                    <CountUp
                      start={0}
                      // prefix={"Egp"}
                      // suffix={"N"}
                      separator={","}
                      end={data?.totalClients}
                      decimals={"0"}
                      duration={4}
                    />
                    {t("Numbers")}
                  </span>
                </h4>
              </div>

              <div className="avatar-sm flex-shrink-0">
                <span className={"avatar-title rounded fs-3 bg-soft-success"}>
                  <i
                    // style={{ color: "#3f78e0" }}
                    className={` uil-users-alt fs-40  link-blue`}
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default Widgets;
