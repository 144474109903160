import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import PaymentForm from "../../components/PatmentForm";
import Aside from "../../components/Aside";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import ModelRechargeAdd from "./ModelSenderRecharge";

function RechargeAdd() {
  const [t, i18n] = useTranslation();
  const packege = JSON.parse(sessionStorage.getItem("packegeRecharge"));
console.log(packege, "packege");
  const [typePayment, setTpePayment] = useState("");
  const [typePaymentOffline, setTpePaymentOffline] = useState("vodafoneCash");
  const { user, token } = useSelector((state) => state.auth);
  const [hover, setHover] = useState(false);
  const [numberSms, setNumberSms] = useState(packege?.fixedMessageCount || "");
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(packege?.currency || "");
  const [allPrice, setAllPrice] = useState(packege?.fixedMessagePrice || "");
  const toast = useRef(null);
  const [nameCountry, setNameCountry] = useState("");

  const [countries, setcountries] = useState([]);
  const URL = process.env.URL_SERVER;
  const [contentSenderName, setcontentSenderName] = useState(false);

  const handleChange = (e) => {
    console.log(e);
    handleGetPrice(currency, e.target.value);

    setNumberSms(e.target.value);
    if (+e.target.value > 900) {
      setHover(false);
    } else {
      setHover(true);
    }
  };

  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/country/user-activated-countries`,

        config,
        config
      );
      console.log(res.data);
      if (res) {
        setcountries(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataGetway();
  }, []);

  const handleGetPrice = async (currency, numsetAllPriceberSms) => {
    if (countries.length == 1) {
      currency = countries[0]._id;
    }
    console.log(currency, numsetAllPriceberSms);
    if (currency && numsetAllPriceberSms) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(
          `https://togethersms.com/priceConfig/${currency}/${numsetAllPriceberSms}`,

          config
        );
        console.log(res);
        setLoading(false);
        if (res.status === 200) {
          setAllPrice(res.data?.price * numsetAllPriceberSms);
          // setPrice(res.data.groups);
        } else {
          console.log(res);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        setAllPrice("not found");

        //  setError(true);
      }
    }
  };
  const navigate = useNavigate("");

  const sendData = (e) => {
    // handleSend(e);
    let data = {
      paymentMethod: typePayment,
      paidAmount: allPrice,
      points: +numberSms,
      nameCountry,
      currency,
    };
    console.log(data, "data");
    if (countries.length == 1) {
      data.currency = countries[0]._id;
      data.nameCountry = countries[0].countryName;
    }

    sessionStorage.setItem(
      "contentAddRechage",
      JSON.stringify({
        data,
      })
    );
    if (allPrice) {
      setcontentSenderName(true);
      // navigate("/content-recharge-add")
    }
  };

  const handleSend = async (e) => {
    e.preventDefault();

    setLoading(true);
    let data = {
      paymentMethod: typePaymentOffline,
      paidAmount: allPrice,
      points: +numberSms,
      country: currency,
    };
    if (countries.length == 1) {
      data.country = countries[0]._id;
    }
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/payment/create?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res, "resres");
      if (res.status === 201) {
        setLoading(false);

        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
        if (typePayment !== "online") {
          navigate("/recharge-archieve");
        }
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <Toast ref={toast} />

      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-8col-lg-7 col-xl-6 mx-auto">
              <h1 className="display31 mb-3">{t("Recharge Your Balance")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/recharge-archieve"}>
                      {" "}
                      {t("Recharge archieve")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/recharge-add">{t("Recharge Your Balance")}</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-7" class="wrapper py-10">
              <div class="card">
                <div class="card-body">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (typePayment === "online") {
                        sendData(e);
                      } else {
                        handleSend(e);
                      }
                    }}
                  >
                    <div className="d-flex align-items-center mb-4 ">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Select Country")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-6  col-12">
                        <div class="form-floating ">
                          <div className="form-select-wrapper">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={async (e) => {
                                const [alphanumericCode, name] =
                                  e.target.value.split(",");

                                setCurrency(alphanumericCode);
                                console.log(alphanumericCode);
                                // setPriceSenderName(numericValue);
                                // setCountryName(alphanumericCode);
                                setNameCountry(name);

                                await handleGetPrice(e.target.value, numberSms);
                              }}
                            >
                              {countries.length > 1 ? (
                                <>
                                  <option selected>
                                    {" "}
                                    {t("Select Country")}
                                  </option>
                                  {countries?.map((e) => (
                                    <option value={[e?._id, e?.countryName]}>
                                      {e.countryName}
                                    </option>
                                  ))}
                                </>
                              ) : (
                                <>
                                  {" "}
                                  <option selected>
                                    {" "}
                                    {t("Select Country")}
                                  </option>
                                  <option
                                    value={[
                                      countries[0]?._id,
                                      countries[0]?.countryName,
                                    ]}
                                    selected
                                  >
                                    {" "}
                                    {countries[0]?.countryName}
                                  </option>
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 ">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Request balance")}
                        </label>
                      </div>
                      <div class="form-floating  col-md-6 col-12">
                        <div class="form-floating ">
                          <input
                            id="textInputExample"
                            type="number"
                            class="form-control"
                            placeholder="Text Input"
                            value={numberSms}
                            disabled={packege}
                            onClick={() => setHover(true)}
                            onChange={(e) => handleChange(e)}
                            required
                          />
                          <label for="textInputExample">
                            {t("Request balance")}
                          </label>
                        </div>
                      </div>
                    </div>
                    {hover && (
                      <div
                        className="alert alert-warning alert-icon col-12 col-md-8"
                        role="alert"
                      >
                        <i className="uil uil-exclamation-triangle" /> A simple
                        Amount must be greater than{" "}
                        <a className="alert-link hover">1000 message(s)</a>.
                      </div>
                    )}
                    <div className="d-flex align-items-center mb-4 mb-4">
                      <div class="form-select-wrapper  col-2 d-md-block">
                        <label className="text-orange ">{t("Price")}</label>
                      </div>
                      <div class="form-floating  col-md-8 col-12">
                        <span>{allPrice} EGP</span>
                      </div>
                    </div>
                    {/* <div className="d-flex align-items-center mb-4 ">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">{t("Notes")}</label>
                      </div>
                      <div class="form-floating  col-md-6 col-12">
                        <div class="form-floating ">
                          <textarea
                            id="textareaExample"
                            className="form-control"
                            placeholder="Textarea"
                            style={{ height: "150px" }}
                            required
                            defaultValue={""}
                          />
                          <label for="textInputExample">{t("Notes")}</label>
                        </div>
                      </div>
                    </div> */}

                    <div class="form-floating d-flex flex-wrap gap-4 mb-4">
                      <h4 className="text-primary">{t("Payment method")} :</h4>
                      <div
                        class="form-check"
                        onClick={() => setTpePayment("online")}
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={typePayment === "online"}
                        />
                        <label class="form-check-label" for="flexRadioDefault1">
                          {" "}
                          {t("Online Payment")}
                        </label>
                      </div>
                      <div
                        class="form-check mb-4"
                        onClick={() => setTpePayment("offline")}
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          checked={typePayment === "offline"}
                        />
                        <label class="form-check-label" for="flexRadioDefault2">
                          {" "}
                          {t("Offline Payment")}
                        </label>
                      </div>
                    </div>
                    {typePayment === "offline" && (
                      <div className="">
                        <div
                          class="form-check mb-2"
                          onClick={() => setTpePaymentOffline("vodafoneCash")}
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="typePyment"
                            id="vodafoneCash"
                            checked={typePaymentOffline === "vodafoneCash"}
                          />
                          <label class="form-check-label" for="Vodafone">
                            {" "}
                            {t("Vodafone Cash: 01066661015")}
                          </label>
                        </div>
                        <div
                          class="form-check  mb-2"
                          onClick={() => setTpePaymentOffline("bank")}
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="typePyment"
                            id="CIB"
                            checked={typePaymentOffline === "bank"}
                          />
                          <label class="form-check-label" for="CIB">
                            {" "}
                            {t(
                              "CIB Bank:  100034877275  - Account Name Together - Abbas El akkad Branch."
                            )}
                          </label>
                        </div>{" "}
                        <div
                          class="form-check mb-4"
                          onClick={() => setTpePaymentOffline("office")}
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="typePyment"
                            id="Office"
                            checked={typePaymentOffline === "office"}
                          />
                          <label class="form-check-label" for="Office">
                            {" "}
                            {t("Request a collection from your Office :" + " ")}
                            <span className="text-primary">
                              {t("50 EGP will add to the invoice.")}
                            </span>
                          </label>
                        </div>
                        {typePayment === "offline" && (
                          <button
                            type="submit"
                            className="btn btn-primary rounded-pill btn-login  mb-2"
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                {/* Loading...{" "} */}
                              </Spinner>
                            ) : null}
                            {t("Send")}{" "}
                          </button>
                        )}
                      </div>
                    )}
                    {typePayment === "online" && (
                      <button
                        type="submit"
                        className="btn btn-primary rounded-pill btn-login  mb-2"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            {/* Loading...{" "} */}
                          </Spinner>
                        ) : null}
                        {t("Send")}{" "}
                      </button>
                    )}
                  </form>

                  <br />
                  <div
                    style={{ borderTop: "1px solid #ccc" }}
                    className="pt-3 mt-5 fs-12 w-100"
                  >
                    {t(
                      " Please Valued customers, fill all fields to let us receive your request correctly .."
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <Aside/> */}
        </div>
      </div>
      <ModelRechargeAdd
        contentSenderName={contentSenderName}
        setcontentSenderName={setcontentSenderName}
      />
    </div>
  );
}

export default RechargeAdd;
