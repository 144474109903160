import React, { useEffect, useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";

function Login() {
  const [t] = useTranslation();
  const { user, error } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const toast = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (error) navigate("/login");
    if (user) navigate("/");
  }, [navigate, user, error]);

  const handleLogin = async (e) => {
    setLoading(true);
    let user = {
      emailOrPhoneNumber: email,
      password,
    };
    console.log(user);
    setTimeout(() => {
      dispatch(loginUser({ user, setLoading, navigate }));
    });
    console.log(error, "userrrrrrrr");
  };

  useEffect(() => {
    if (error) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
    }
  }, [error]);

  return (
    <div className="content-wrapper">
      <Toast ref={toast} />

      <section className="wrapper  bg-gradient-primary">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Login")} </h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Login")}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto ">
              <div className="card">
                <div className="card-body p-5 text-center">
                  <h2 className="mb-3 text-start">{t("Welcome Back")} </h2>
                  <p className="lead mb-6 text-start">
                    {t("Fill your email and password to sign in.")}
                  </p>
                  <form
                    className="text-start mb-3"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleLogin();
                    }}
                  >
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        id="loginEmail"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="loginEmail">
                        {t("Email Or Phone Number")}
                      </label>
                    </div>
                    <div className="form-floating password-field mb-4">
                      <input
                        className="form-control"
                        id="loginPassword"
                        name="password"
                        type={passwordShow ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        {passwordShow ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>

                      <label htmlFor="loginPassword">{t("Password")}</label>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {" "}
                        </Spinner>
                      ) : null}
                      {t("Login")}{" "}
                    </button>
                  </form>
                  <p className="mb-1">
                    <Link to="/enter-email" className="hover">
                      {t("Forgot Password?")}
                    </Link>
                  </p>
                  <p className="mb-0">
                    {t("Don't have an account?")}{" "}
                    <Link to="/register" className="hover">
                      {t("Register")}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
