import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import axios from "axios";
import { Button } from "primereact/button";
import Avatar from "react-avatar";
import { loginSuccess, logout } from "../../store/slices/authSlice";
import { Alert, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import StaffPassword from "./StaffPassword";

function UserInformation() {
  const { user, token } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation();
  const toast = useRef();
  useEffect(() => {
    // window.scrollTo(0, 0);
  }, []);
  const URL = process.env.URL_SERVER;

  const [deleteAccount, setDeleteAccount] = useState("");
  const handleDelete = async () => {
    // setLoading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/user/delete/${user._id}?lang=${i18n.language}`,
        config,
        config
      );
      if (res) {
        // getData(currentPage);
        setDeleteAccount(false);
        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.message,
          life: 3000,
        });
      } else {
        console.log(res);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      // setLoading(false);
      //   toast.current.show({
      //     severity: "error",
      //     summary: "Error",
      //     detail: error,
      //     life: 3000,
      //   });
      //  setError(true);
    }
  };
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [confirm_password, setConfirmPassword] = useState();
  const [password, setPassword] = useState(false);
  const [companyName, setcompanyName] = useState(false);
  const dispatch = useDispatch();
  const handleUpdate = async () => {
    console.log(user?.userData?._id);
    const data = {};
    if (passwordCurrent) {
      data.oldPassword = passwordCurrent;
    }
    if (password) {
      data.newPassword = password;
    }
    if (confirm_password) {
      data.confirmPassword = confirm_password;
    }
    if (country) {
      data.companyName = country;
    }
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/user/update`,
        data,
        config
      );
      if (res) {
        console.log(res);
        // getData(currentPage);
        setDeleteAccount(false);
        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
      } else {
        console.log(res);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      if (
        error?.response?.data?.message ==
          "Token is invalid. Please log in again." ||
        "Unauthorized"
      ) {
        console.log("done");
        dispatch(logout());
      }
    }
  };
  const [image, setImage] = useState("");
  const [imageLoading, setImageLoading] = useState("");

  const createImage = async () => {
    setImageLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/upload`,
        { image },
        config
      );
      //   setLoading(false);
      console.log(res);
      console.log(`https://togethersms.com/user/${user.data.userData._id}`);
      setImageLoading(false);

      if (res.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${token}`,
            },
          };
          const res = await axios.get(
            `https://togethersms.com/user/${user.data.userData._id}`,

            config
          );
          //   setLoading(false);
          dispatch(loginSuccess(res));
          console.log("user", res);
        } catch (error) {
          console.log(error);
        }
      }
      console.log(res);
      return res.data;
    } catch (error) {
      setImageLoading(false);

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      // return custom error message from backend if present
      console.log(error);
      //   setLoading(false);
    }
  };

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
  const [passwordCurrent, setPasswordCurrent] = useState(false);
  const [passwordCurrentShow, setPasswordCurrentShow] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    console.log(token);

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/reset-password`,
        { password, confirmPassword: confirm_password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) {
        //   dispatch(verifyEmail());
        navigate("/login");
        setLoading(false);
      } else {
        console.log(res);
        // setError(true);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
    }
  };
  const validatePassword = () => {
    if (password === confirm_password) {
      setMessage(false);
      // handleSubmit();
    } else {
      setMessage(true);
      console.log("kjlk");
    }
  };
  const [countries, setcountries] = useState();
  const [country, setCountry] = useState(user?.userData?.country?._id);
  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(config);

      const res = await axios.get(
        `https://togethersms.com/country/user-countries`,

        config,
        config
      );
      console.log(res.data);
      if (res) {
        setcountries(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getDataGetway();
  }, []);
  return (
    <div className="content-wrapper">
      <Toast ref={toast} />
      {/* <Header /> */}

      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display31 mb-3">{t("Control panel")}</h1>
            </div>
          </div>
        </div>
      </section>
      <div className=" rounded h-100 p-4">
        <form
          className="mt-6 space-y-6 container-fluid  text-center bg-light rounded-3 p-6"
          onSubmit={(e) => {
            e.preventDefault();
            createImage();
          }}
        >
          <h6 className="mb-4">Update User Image</h6>
          <input type="hidden" name="_method" defaultValue="patch" />{" "}
          <div className="mb-3">
            {user?.userData?.image ? (
              <img
                className="rounded-circle me-lg-2 m-auto "
                alt=""
                style={{ width: "50px", height: "50px" }}
                src={user?.userData?.image}
              />
            ) : (
              <Avatar
                name={
                  user?.userData?.firstName + " " + user?.userData?.lastName
                }
              />
            )}
          </div>
          <div className="mb-3 w-50 m-auto text-center">
            <input
              name="image"
              type="file"
              required
              autoComplete="username"
              className="form-control"
              id="exampleInputPassword1"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary text-center"
            fdprocessedid="0o123u"
            disabled={imageLoading}
          >
            Update Profile
          </button>
        </form>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto ">
            <section id="snippet-7" className="wrapper py-10">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-4 col-md-2 d-md-block">
                      <label className="text-orange ">{t("Email	")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span>{user?.userData?.email}</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-4 col-md-2 d-md-block">
                      <label className="text-orange ">{t("Real name")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      {user?.userData?.firstName +
                        " " +
                        user?.userData?.lastName}{" "}
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-4 col-md-2 d-md-block">
                      <label className="text-orange ">
                        {t("Phone Number")}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span>{user?.userData?.phoneNumber}</span>
                    </div>
                  </div>

                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">
                        {t("Current Password")}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        className="form-control"
                        id="loginPassword"
                        name="password"
                        //   value={validation.values.password || ""}
                        type={passwordCurrentShow ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => setPasswordCurrent(e.target.value)}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() =>
                          setPasswordCurrentShow(!passwordCurrentShow)
                        }
                      >
                        {passwordShow ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>
                      <label htmlFor="loginPassword">
                        {t("Current Password")}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">
                        {t("New Password")}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        className="form-control"
                        id="loginPassword"
                        name="password"
                        //   value={validation.values.password || ""}
                        type={passwordShow ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        {passwordShow ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>
                      <label htmlFor="loginPassword">{t("New Password")}</label>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">
                        {t("Confirm Password")}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        className="form-control"
                        id="loginPassword"
                        name="password"
                        //   value={validation.values.password || ""}
                        type={passwordShowConfirm ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() =>
                          setPasswordShowConfirm(!passwordShowConfirm)
                        }
                      >
                        {passwordShow ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>
                      <label htmlFor="loginPassword">
                        {t("Confirm Password")}
                      </label>
                    </div>
                  </div>

                  {/* <div className="form-floating password-field mb-4 col-12 ">
                    <input
                      className="form-control"
                      id="loginPassword"
                      name="password"
                      //   value={validation.values.password || ""}
                      type={passwordShowConfirm ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() =>
                        setPasswordShowConfirm(!passwordShowConfirm)
                      }
                    >
                      {passwordShowConfirm ? (
                        <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                      ) : (
                        <i className="ri-eye-fill uil uil-eye align-middle"></i>
                      )}
                    </button>
                    <label htmlFor="loginPassword">
                      {t("Confirm Password")}
                    </label>
                  </div> */}
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">{t("Country")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <div className="form-select-wrapper mb-4 ">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setCountry(e.target.value)}
                          value={country}
                        >
                          <option value={"1"}>{t("Select Country")}</option>
                          {countries?.map((e) => (
                            <option value={e._id}>{e.countryName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">{t("City")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        id="textInputExample"
                        type="text"
                        className="form-control"
                        placeholder="Text Input"
                      />
                      <label htmlFor="textInputExample">{t("City")}</label>
                    </div>
                  </div> */}
                  {/* <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">{t("Address")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        id="textInputExample"
                        type="text"
                        className="form-control"
                        placeholder="Text Input"
                      />
                      <label htmlFor="textInputExample">{t("Address")}</label>
                    </div>
                  </div> */}
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">
                        {t("Company name")}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        id="textInputExample"
                        type="text"
                        className="form-control"
                        placeholder="Text Input"
                      />
                      <label htmlFor="textInputExample">
                        {t("Company name")}
                      </label>
                    </div>
                  </div>
                  {/* <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-2 d-md-block d-none">
                      <label className="text-orange ">{t("Mobile")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <input
                        id="textInputExample"
                        type="text"
                        className="form-control"
                        placeholder="Text Input"
                      />
                      <label htmlFor="textInputExample">{t("Mobile")}</label>
                    </div>
                  </div> */}

                  {/* <div className="d-flex align-items-center  mb-4 ">
                    <div className="col-2 d-md-block d-none"></div>
                    <div
                      style={{ border: "1px solid #eee" }}
                      className="p-1  col-md-6 col-12"
                    >
                      <div className="form-check  ">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label "
                          htmlFor="flexCheckDefault"
                        >
                          {" "}
                          {t("I Agree Agreement")}
                        </label>
                      </div>
                    </div>
                  </div> */}

                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill btn-login mb-2"
                    disabled={loading ? true : false}
                    onClick={() => handleUpdate()}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        {/* Loading...{" "} */}
                      </Spinner>
                    ) : null}
                    {t("Edit")}
                  </button>
                </div>
              </div>
              <Dialog
                visible={deleteAccount}
                style={{ width: "32rem" }}
                breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                header="Confirm"
                modal
                footer={
                  <React.Fragment>
                    <Button
                      label="No"
                      icon="pi pi-times"
                      outlined
                      onClick={() => setDeleteAccount(false)}
                    />
                    <Button
                      label="Yes"
                      icon="pi pi-check"
                      severity="danger"
                      onClick={() => handleDelete()}
                    />
                  </React.Fragment>
                }
                onHide={() => setDeleteAccount(false)}
              >
                <div className="confirmation-content">
                  Are you sure to delete your Account ?
                  <i
                    className="pi pi-exclamation-triangle mr-3"
                    style={{ fontSize: "2rem" }}
                  />
                </div>
              </Dialog>
            </section>
          </div>
          <StaffPassword toast={toast} />
          {/* <Aside/> */}
        </div>
      </div>
    </div>
  );
}

export default UserInformation;
