// src/DataTable.js
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import data from "./data";

const columns = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Phone",
    selector: "phone",
    sortable: true,
  },
];

const DataTableComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const filteredData = data.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.phone.includes(searchTerm) // Search in phone number
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleRowSelected = (rows) => {
    setSelectedRows(rows.map((r) => r.id));
  };

  const rowCheckboxProps = (row) => ({
    className: "form-check-input", // Add the class to the checkbox
    checked: selectedRows.includes(row.id),
    onChange: () => handleRowSelected(row),
  });

  return (
    <div>
      <input
        type="text"
        placeholder="Search by name or phone"
        className=" mb-6 form-control"
        value={searchTerm}
        onChange={handleSearch}
      />
      <DataTable
        // title="Sample Data Table"
        columns={columns}
        data={filteredData}
        selectableRows
        selectableRowsComponentProps={{ color: "primary" }}
        selectableRowCheckboxProps={rowCheckboxProps}
        pagination
        highlightOnHover
      />
    </div>
  );
};

export default DataTableComponent;
