import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
function CompleteData() {
  const [t] = useTranslation();
  const [firstName, setFristName] = useState();
  const [lastName, setLastName] = useState();
  const [companyName, setCompanyName] = useState();
  const [loading, setLoading] = useState(false);
  const [companyAddress, setCompanyAddress] = useState("");
  const navigate = useNavigate("");

  const toast = useRef();
  const handleSubmit = async () => {
    const token = localStorage.getItem("tokenSms");
    setLoading(true);
    let data = {
      firstName,
      lastName,
      companyAddress,
      companyName,
    };
    console.log(data);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/signup/completeData`,
        data,
        config
      );
      if (res) {
        //  dispatch(adminVerfiy(data));
        localStorage.setItem("tokenSms", res.data.token);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Your account has been created successfully",
          life: 3000,
        });
        setTimeout(() => {
          console.log("res", res);

          navigate("/login");
          setLoading(false);
        }, 1000);

        // setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //  setError(true);
    }
  };
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <Toast ref={toast} />

      <section
        className="wrapper  bg-gradient-primary"
        data-image-src="./assets/img/photos/bg18.png"
      >
        <div className="container  pt-md-5 pb-md-12 text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Complete Data")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Complete Data")}
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-8 col-xl-8 col-xxl-8 mx-auto mt-n10">
              <div className="card">
                <div className="card-body p-5 text-center">
                  <h2 className="mb-3 text-start"> {t("Complete Data")}</h2>
                  <p className="lead mb-6 text-start">
                    {t("Registration takes less than a minute.")}
                  </p>
                  <form
                    className="text-start mb-3 row"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="form-floating mb-4 col-12 col-xl-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        id="loginName"
                        onChange={(e) => setFristName(e.target.value)}
                      />
                      <label htmlFor="loginName">{t("First Name")}</label>
                    </div>
                    <div className="form-floating mb-4 col-12 col-xl-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        id="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label htmlFor="lastName">{t("Last Name")}</label>
                    </div>

                    {/* <div className="form-floating mb-4 col-12 col-xl-6">
                      <PhoneInput
                        country={"eg"} // Set the default country
                        value={""} // Set the initial value
                        onChange={(value, country) => {
                          setPhoneNumber(value);
                          setCountry(country);
                        }}
                      />
                    </div> */}
                    {/* <div className="form-floating mb-4 col-12 col-xl-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="phone"
                        id="phoneNumber"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                      <label htmlFor="phoneNumber">
                        {t("Second Phone Number")}
                      </label>
                    </div> */}

                    <div className="form-floating mb-4 col-12 col-xl-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Company Address"
                        id="companyAddress"
                        onChange={(e) => setCompanyAddress(e.target.value)}
                      />
                      <label htmlFor="companyAddress">
                        {t("Company Address")}
                      </label>
                    </div>
                    <div className="form-floating mb-4 col-12 col-xl-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Company Name"
                        id="companyName"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <label htmlFor="companyName">{t("Company Name")}</label>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {" "}
                          {/* Loading...{" "} */}
                        </Spinner>
                      ) : null}
                      {t("Complete Data")}
                    </button>
                  </form>
                  {/* /form */}
                  <p className="mb-0">
                    {" "}
                    {t("Already have an account?")}
                    <Link to="/login" className="hover">
                      {t("Login")}
                    </Link>
                  </p>

                  {/*/.social */}
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </div>
  );
}

export default CompleteData;
