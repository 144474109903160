import axios from "axios";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function Blog({}) {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState();
  const { user, token } = useSelector((state) => state.auth);

  const getData = async (e) => {
    try {
      const res = await axios.get(
        `https://togethersms.com/Tutorial/get-user-tutorials`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res);
      setData(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display-1 mb-3">قسم الشروحات </h1>
              <p className="lead px-xxl-10"></p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-2" className="wrapper pt-10 ">
              {data?.map((e, index) => (
                <Link to={`/explan/${e._id}`} key={index}>
                  <h2
                    className="mb-5 text-primary cursor-pointer"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    {index + 1}. {e?.tutorialName}
                  </h2>
                </Link>
              ))}
              {/* <Link to="/explan/2">
                <h2
                  className="mb-5 text-primary cursor-pointer"
                  style={{ textDecoration: "underline" }}
                >
                  {" "}
                  2. {t("طريقة تفعيل إسم شركتك على شبكات المحمول")}
                </h2>
              </Link>
              <Link to="/explan/3">
                <h2
                  className="mb-5 text-primary cursor-pointer"
                  style={{ textDecoration: "underline" }}
                >
                  3. كيفية إضافة رصيد
                </h2>
              </Link>
              <Link to="/explan/4">
                <h2
                  className="mb-5 text-primary cursor-pointer"
                  style={{ textDecoration: "underline" }}
                >
                  4. طريقة إرسال رسالة
                </h2>
              </Link>
              <Link to="/explan/5">
                <h2
                  className="mb-5 text-primary cursor-pointer"
                  style={{ textDecoration: "underline" }}
                >
                  5. طريقة إنشاء جروب أرقام عن طريق شيب اكسيل
                </h2>
              </Link> */}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
