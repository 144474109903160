import React, { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { GiCancel } from "react-icons/gi";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";

function SelectGroup({
  setGroupSelected,
  groupSelected,
  setSelectGroupId,
  selectGroupId,
}) {
  const { user, token } = useSelector((state) => state.auth);

  const [globalFilter, setGlobalFilter] = useState("");
  const [addGroup, setAddGroup] = useState(false);
  const [t] = useTranslation();
  const [selectGroup, setSelectGroup] = useState(true);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getDataSearch = async (page, value) => {
    console.log(page);

    console.log(value);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/group/find-group-notBlacklist` +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      setData(res.data.groups);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage]);

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };
  const onRowSelect = (event) => {
    setGroupSelected(event.value);
    const idArray = event.value.map((item) => item.group._id);
    setSelectGroupId(idArray);
  };

  const printSelected = () => {
    setAddGroup(true);
    // setSelectGroup(false);
    const selectedData = groupSelected.map((row) => {
      return {
        _id: row.group._id,
        name: row.group.name,
        // count: row.contacts.length
      };
    });
    // console.log("Selected Rows:", selectedData[0].count);
  };
  const header = (
    <div className="row ">
      <div className="form-floating col-6 mb-4 d-flex align-items-center">
        <input
          id="textInputExample"
          type="text"
          className="form-control"
          placeholder="Text Input"
          // value={globalFilter}
          onInput={(e) => {
            setGlobalFilter(e.target.value);
            setCurrentPage(1);

            getDataSearch(1, e.target.value);
          }}
        />
        <label htmlFor="textInputExample">{t("Search")}</label>
      </div>
      {/* <div className="col-6 d-flex justify-content-end ">
        <GiCancel
          onClick={() => setSelectGroup(false)}
          className="text-primary cursor-pointer fs-20"
        />
      </div> */}
    </div>
  );
  const deleteGroup = (id) => {
    setGroupSelected(groupSelected.filter((row) => row.group._id !== id));
    setSelectGroupId(selectGroupId.filter((row) => row !== id));
  };
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <div className="form-select-wrapper  col-12 mb-4 ">
        <h2 className=""> {t("Select Group")}</h2>
        {/* <div
          className="btn btn-yellow rounded-pill"
          onClick={() => {
            setSelectGroup(true);
          }}
        >
          {t("Select Group")}
        </div> */}
      </div>
      {selectGroup && (
        <div className="card-body mt-0 pt-0">
          <DataTable
            selection={groupSelected}
            onSelectionChange={onRowSelect}
            // paginator
            loading={loading}
            rows={10}
            rowsPerPageOptions={[5, 10, 20]}
            className="pt-0"
            globalFilter={globalFilter}
            header={header}
            style={{ minHeight: "50vh", border: "1px solid #ccc" }}
            lazy
            width={"100%"}
            ref={dt}
            value={data}
            // header={header}
            selectionMode={"checkbox"}
            totalRecords={20}
          >
            <Column selectionMode="multiple" style={{ width: "3rem" }} />
            <Column
              body={(rowData) => {
                return <>{rowData.group.name}</>;
              }}
              header="Group"
            />
            <Column
              body={(rowData) => {
                return <>{rowData.totalContacts}</>;
              }}
              header="Count"
            />
            <Column
              body={(rowData) => {
                return (
                  <>
                    {rowData?.group?.isPending && (
                      <div className="d-flex gap-2 align-items-center">
                        Pending ...
                      </div>
                    )}
                  </>
                );
              }}
              header="Status"
            />
          </DataTable>
          <Paginator
            first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
            rows={10} // Set the number of items per page
            totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
            onPageChange={onPageChange}
          />
          {/* <div
            className="btn btn-expand btn-primary rounded-pill "
            onClick={printSelected}
          >
            <i className="uil uil-arrow-right"></i>
            <span>{t("Apply")}</span>
          </div> */}
        </div>
      )}{" "}
      {selectGroupId && (
        <div className="">
          <ul className="row">
            {groupSelected?.map((e) => (
              <li className="d-flex mb-2  gap-5 align-items-center">
                {e.group.name} ({e.totalContacts})
                <GiCancel
                  onClick={() => deleteGroup(e.group._id)}
                  className="text-primary cursor-pointer"
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default SelectGroup;
