import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";

const ApexChart = () => {
  const [t, i18n] = useTranslation();
  const { user, token } = useSelector((state) => state.auth);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Total Success Number",
        type: "column",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
      },
      stroke: {
        width: [0, 4],
      },
      title: {
        text: "Total Success Number",
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1],
      },
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      xaxis: {
        type: "category", // Change type to "category" instead of "datetime"
      },
      yaxis: [
        {
          title: {
            text: "Total Success Number",
          },
        },
        {
          opposite: true,
          title: {
            text: "Total Success Number",
          },
        },
      ],
    },
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(
          `https://togethersms.com/MessageRecord/user-dashboard-chart`,
          config
        );
        console.log(res);
        setChartData((prevState) => ({
          ...prevState,
          series: res?.data,
        }));
      } catch (error) {
        console.log(error);
        // Handle errors
      }
    };

    getData();
  }, [token]);

  return (
    <Card className="p-2">
      <div className="w-100">
        <div id="chart">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={350}
          />
        </div>
        <div id="html-dist"></div>
      </div>
    </Card>
  );
};

export default ApexChart;
