import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Details({ data, setTypeProccess, toast, setLoading, loading }) {
  const { user, token } = useSelector((state) => state.auth);

  const [t, i18n] = useTranslation();
  const handleCancel = async (e) => {
    setLoading(true);
    console.log(e);

    const data = { campaignId: e._id };
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/MessageRecord/${e._id}/cancel`,
        data,
        config
      );
      console.log(res);
      setLoading(false);
      if (res.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      setTypeProccess("resume");
      console.log(error);
    }
  };

  return (
    <div className="p-2">
      <div className="card col-12 ">
        <div className="card-body">
          <h5 className="text-primary">Information {data.name}</h5>
          <div className="d-flex align-items-center  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block">
              <label className="text-orange ">{t("Message :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span>{data?.message}</span>
            </div>
          </div>
          <div className="d-flex align-items-center  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block ">
              <label className="text-orange ">{t("Sender Name :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span>{data?.senderName}</span>
            </div>
          </div>
          <div className="d-flex align-items-center  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block ">
              <label className="text-orange ">{t("Status :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span className="text-primary fw-bold">Running</span>
            </div>
          </div>
          <div className="d-flex align-items-center  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block ">
              <label className="text-orange ">{t("Sent/Total :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span className=" fw-bold">
                {data.successPhoneNumbers.length} /{" "}
                {data.totalPhoneNumbersLength}
              </span>
            </div>
          </div>
          {/* <div className="d-flex align-items-center  mb-4 fs-15 ">
              <div className="form-select-wrapper  col-2 d-md-block ">
                <label className="text-orange ">{t("Send To :")}</label>
              </div>
              <div className="form-floating  col-md-10 col-12">
                {data?.totalPhoneNumbers?.length > 0 ? (
                  <>
                    <div>
                      {data?.totalPhoneNumbers.slice(0, 2).map((e) => (
                        <span>{e},</span>
                      ))}
                      <span
                        className="cursor-pointer"
                        // onClick={() => downloadArrayAsFile()}
                      >
                        {" "}
                        {t("More Upload File")}
                      </span>
                    </div>
                  </>
                ) : (
                  <>notfound</>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center  mb-4 fs-15 ">
              <div className="form-select-wrapper  col-3 d-md-block ">
                <label className="text-orange ">
                  {t("Send Successfully to :")}
                </label>
              </div>
              <div className="form-floating  col-md-9 col-12">
                <span>
                  {data?.successPhoneNumbers?.length > 0 ? (
                    <>
                      {" "}
                      <div>
                        {data?.successPhoneNumbers.slice(0, 2).map((e) => (
                          <span>{e},</span>
                        ))}
                        {t("More Upload File")}
                      </div>
                    </>
                  ) : (
                    <>not found</>
                  )}
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center  mb-4 fs-15 ">
              <div className="form-select-wrapper  col-2 d-md-block ">
                <label className="text-orange ">
                  {t("Faild Sent To :")}
                  <div>
                    {data?.failedPhoneNumbers.slice(0, 2).map((e) => (
                      <span>{e},</span>
                    ))}
                  </div>{" "}
                </label>
              </div>
              <div className="form-floating  col-md-10 col-12">
                <span></span>
              </div>
            </div> */}
          <div className="d-flex align-items-center  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block ">
              <label className="text-orange ">{t("Message Cost :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span>{data?.totalPoints / data?.totalPhoneNumbersLength}</span>
            </div>
          </div>
          <div className="d-flex align-items-center flex-wrap  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block ">
              <label className="text-orange ">{t("Actual spent :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span>{data?.actuallySentPoints}</span>
            </div>
          </div>
          <div className="d-flex align-items-center  mb-4 fs-15 ">
            <div className="form-select-wrapper  col-2 d-md-block ">
              <label className="text-orange ">{t("Sent Cost :")}</label>
            </div>
            <div className="form-floating  col-md-10 col-12">
              <span>{data?.totalPoints}</span>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-0">
            <div className="d-flex gap-3  mt-0">
              <button
                className="btn btn-primary rounded-pill mt-0 mb-0 text-nowrap"
                disabled={loading}
                onClick={() => {
                  handleCancel(data);
                  setTypeProccess("resume");
                }}
              >
                {t("Stop")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
