import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Dialog } from "primereact/dialog";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import "react-tooltip/dist/react-tooltip.css";

import { Tooltip } from "react-tooltip";

export default function SenderNameTable() {
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [addNumberInGroup, setAddNumberInGroup] = useState(false);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user, token } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");
  const [uploadedExcelData, setUploadedExcelData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();
  const [t, i18n] = useTranslation();
  const URL = process.env.URL_SERVER;

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [name, setName] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);

  const getData = async (page) => {
    setLoadingData(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/get-User-CompanyActivations` +
          "?page=" +
          page,

        config
      );
      console.log(res);
      setLoadingData(false);
      if (res.status === 200) {
        setData(res.data.companyActivations);
        setTotalPages(res.data.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoadingData(false);
      console.log(error);

      //  setError(true);
    }
  };
  const getDataSearch = async (value, page) => {
    setLoadingData(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/get-User-CompanyActivations` +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      setLoadingData(false);
      if (res) {
        setData(res.data.companyActivations);

        setTotalPages(res.data.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoadingData(false);
      console.log(error);

      //  setError(true);
    }
  };

  ///sendData
  const sendData = async (e) => {
    e.preventDefault();
    setName("");
    if (name != "") {
      hideDialog();
    }
    setLoading(true);
    let data = {
      name,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/Group/create`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        getData();
        // setLoading(false);
        setLoading(false);
        setName("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getData(currentPage);
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };
  const [countryName, setCountryName] = useState(false);
  const [senderName, setSenderName] = useState(false);
  const [activity, setActivity] = useState(false);
  const [facebookLink, setFacebookLink] = useState(false);
  const [websiteLink, setWebsiteLink] = useState(false);
  const [commercialRegister, setcommercialRegister] = useState("");
  const [taxId, settaxId] = useState("");
  const [delegation, setdelegation] = useState("");

  const handleEdit = async () => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    const formData = new FormData();
    formData.append("websiteLink", websiteLink);
    formData.append("facebookLink", facebookLink);
    formData.append("activity", activity);
    formData.append("senderName", senderName);
    formData.append("countryName", countryName);
    formData.append("commercialRegister", commercialRegister);
    formData.append("taxId", taxId);
    formData.append("delegation", delegation);
    console.log(countryName);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-by-admin/${editRow.user._id}/${editRow._id}`,
        formData,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const [openBlock, setOpenBlock] = useState("");

  const handleAccept = async (editRow) => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(
      `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`
    );

    const data = { activationStatus: "Activated" };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handleUnBlock = async (editRow) => {
    console.log(editRow._id);
    console.log(user.token);
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(
      `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`
    );

    const data = { activationStatus: "Activation Rejected" };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${user.token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/CompanyActivation/update-activation-status/${editRow.user._id}/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      setOpenBlock("");
      if (res) {
        getData(currentPage);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.message,
          life: 3000,
        });

        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setOpenBlock("");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handleDelete = async () => {
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/CompanyActivation/delete/${rowDelete._id}`,

        config
      );
      console.log(res);
      if (res) {
        getData(currentPage);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //   toast.current.show({
      //     severity: "error",
      //     summary: "Error",
      //     detail: error,
      //     life: 3000,
      //   });
      //  setError(true);
    }
  };

  ///////////

  const openNew = () => {
    setProduct();
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditRow("");
    setSubmitted(false);
    setProductDialog(false);
  };
  const handleAddOneInGrroup = () => {
    setAddNumberInGroup(true);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setEditRow(product);
    setProductDialog(true);
  };

  const [rowDelete, setRowDelete] = useState("");

  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
      <span className="p-input-icon-left col-12 col-md-6">
        <i className="pi pi-search" />
        <div className="form-floating mb-4">
          <input
            id="textInputExample"
            type="search"
            onInput={(e) => {
              setCurrentPage(1);
              setGlobalFilter(e.target.value);

              getDataSearch(e.target.value, currentPage);
            }}
            placeholder="Search..."
            className="form-control"
          />
          <label htmlFor="textInputExample">{t("Search...")}</label>
        </div>
      </span>
    </div>
  );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <Link to={`/smspro-groups_grid/${rowData.group._id}`}>
        {rowData.group.name}
      </Link>
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <i
            className="uil uil-edit fs-17 text-blue cursor-pointer"
            icon="pi pi-pencil mr-2"
            onClick={() => {
              editProduct(rowData);
            }}
          ></i>

          <div className="d-flex gap-2">
            <Button
              className=" bg-danger py-1 px-2"
              onClick={() => confirmDeleteProduct(rowData)}
            >
              <i className="ri-delete-bin-2-line bg-danger"></i>
            </Button>
          </div>

          <i className="uil uil-copy  fs-17 text-green cursor-pointer"></i>

          <i
            className="uil uil-plus-circle fs-17 text-green cursor-pointer"
            onClick={handleAddOneInGrroup}
          ></i>
        </div>
      </React.Fragment>
    );
  };

  const handleCompleteData = (e) => {
    // let data = {
    //   data: e,
    // };
    // console.log(data);

    sessionStorage.setItem(
      "contentAddSenderName",
      JSON.stringify({
        data: e,
      })
    );
    navigate("/content-add-sender-name");
  };

  const handlePaymob = async () => {
    let data = {
      api_key: API,
    };
    try {
      let request = await fetch("https://accept.paymob.com/api/auth/tokens", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      let response = await request.json();
      console.log(response);

      let token = response.token;
      secondStep(token);
    } catch (error) {
      console.log(error);
    }
  };

  const API =
    "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2libUZ0WlNJNkltbHVhWFJwWVd3aUxDSndjbTltYVd4bFgzQnJJam94T1RnNGZRLnNFVjl0YnNCdTA2d3lHTDVBRTdIT3ZQYUVqZjVETll0aGhORUJiVDQ2YzBlbUtlelNFWld6WUxONUwzZldJeDFCdFhBMUU4QW1XOTUyYnNjT1Q5ZmlR"; // your api here
  const integrationID = 2942;

  async function secondStep(token) {
    let data = {
      auth_token: token,
      delivery_needed: "false",
      amount_cents: "100",
      currency: "EGP",
      items: [
        {
          name: "SenderName",
          amount_cents: "500",
          // description: "Smart Watch",
          // quantity: "1",
        },
      ],
    };

    let request = await fetch(
      "https://accept.paymob.com/api/ecommerce/orders",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    let response = await request.json();

    let id = response.id;
    console.log(response);

    thirdStep(token, id);
  }

  async function thirdStep(token, id) {
    let data = {
      auth_token: token,
      amount_cents: "100",
      expiration: 3600,
      order_id: id,
      billing_data: {
        apartment: "803",
        email: "user.data.user.email",
        floor: "42",
        first_name: "Clifford",
        street: "user.data.user.id",
        building: "8028",
        phone_number: "+86(8)9135210487",
        shipping_method: "PKG",
        postal_code: "01898",
        city: "Jaskolskiburgh",
        country: "CR",
        last_name: "Nicolas",
        state: "TstTogether",
      },
      currency: "EGP",
      integration_id: integrationID,
    };

    let request = await fetch(
      "https://accept.paymob.com/api/acceptance/payment_keys",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    let response = await request.json();

    console.log(response);
    let TheToken = response.token;
    cardPayment(TheToken);
  }

  async function cardPayment(token) {
    let iframURL = `https://accept.paymob.com/api/acceptance/iframes/5440?payment_token=${token}`;
    // window.location.href = iframURL;
    window.open(iframURL, "_blank");
  }

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        {/* <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar> */}

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          lazy
          loading={loadingData}
          ref={dt}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          header={header}
          //   selectionMode={"checkbox"}
          //   totalRecords={20}
        >
          {/* <Column selectionMode="multiple" exportable={false}></Column> */}

          <Column
            className="fs-16"
            // body={nameBodyTemplate}
            field="senderName"
            header="Sender Name"
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-16"
            // body={nameBodyTemplate}
            body={(rowData) => {
              return <div>{rowData.paidAmountCents.toLocaleString("en")}</div>;
            }}
            header="Cost"
            style={{ minWidth: "5rem" }}
          ></Column>
          {/* <Column
            className="fs-16"
            field="countryName"
            header="Country Name"
            style={{ minWidth: "4rem" }}
          ></Column> */}
          <Column
            className="fs-16"
            // field="activationStatus"
            header="Active"
            body={(rowData) => {
              return (
                <>
                  {rowData.activationStatus == "Activation in progress" ? (
                    <div className="text-blue d-flex align-items-center gap-1">
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          minWidth: "10px",
                          borderRadius: "50%",
                          backgroundColor: "blue",
                          display: "block",
                        }}
                      ></span>{" "}
                      {rowData.activationStatus}
                    </div>
                  ) : rowData.activationStatus == "Activated" ? (
                    <div className="text-green d-flex align-items-center gap-1">
                      <span
                        style={{
                          width: "10px",
                          height: "10px",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          display: "block",
                        }}
                      ></span>{" "}
                      {rowData.activationStatus}
                    </div>
                  ) : (
                    <div className="text-danger d-flex align-items-center gap-1">
                      <a className="my-anchor-element text-danger d-flex align-items-center gap-1">
                        {" "}
                        <span
                          style={{
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            backgroundColor: "red",
                            display: "block",
                          }}
                        ></span>{" "}
                        {rowData.activationStatus}
                      </a>
                      <Tooltip anchorSelect=".my-anchor-element" place="top">
                        {rowData?.rejectedReasons}
                      </Tooltip>{" "}
                    </div>
                  )}
                </>
              );
            }}
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            body={(rowData) => {
              return (
                <>
                  {rowData.isPaid ? (
                    <div className="text-green fs-15 fw-bold"> true</div>
                  ) : (
                    <div className="text-red fs-15 fw-bold "> false</div>
                  )}
                </>
              );
            }}
            header="isPaid"
            className="fs-14 "
          />
          {/* <Column
            className="fs-16"
            body={(rowData) => {
              return (
                <AiOutlineDelete
                  className="uil uil-backspace fs-20 text-red cursor-pointer"
                  onClick={() => {
                    confirmDeleteProduct(rowData);
                    setSelectDelete(rowData);
                  }}
                />
              );
            }}
            header="Cancle"
            style={{ minWidth: "4rem" }}
          ></Column> */}
          <Column
            className="fs-16"
            field="paymentMethod"
            body={(rowData) => {
              return (
                <>
                  {rowData.paymentMethod ? (
                    <>{rowData.paymentMethod}</>
                  ) : (
                    <>Online</>
                  )}
                </>
              );
            }}
            header="Paid Method"
            style={{ minWidth: "4rem" }}
          ></Column>
          <Column
            className="fs-16"
            body={(rowData) => {
              return (
                <>
                  {!rowData.isPaid && (
                    <>
                      {rowData.activationStatus == "Activation Rejected" ? (
                        ""
                      ) : (
                        <div
                          className="text-primary cursor-pointer"
                          onClick={() => handleCompleteData(rowData)}
                        >
                          Deraft
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            }}
            header="Deraft"
            style={{ minWidth: "4rem" }}
          ></Column>
          {/* <Column
            className="fs-16"
            body={(rowData) => {
              return (
                <>
                  {rowData.isPaid ? (
                    "Paied Is True"
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login  mb-2"
                      disabled={loading ? true : false}
                      onClick={() => handlePaymob()}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                      
                        </Spinner>
                      ) : null}
                      {t("Pay")}
                    </button>
                  )}
                </>
              );
            }}
            header="Pay"
            style={{ minWidth: "4rem" }}
          ></Column> */}
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />

      <Dialog
        visible={productDialog}
        style={{ width: "45rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow?.senderName ? t("Edit Group Name") : t("Group Name")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) =>
                editRow?.senderName ? handleEdit() : sendData(e)
              }
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            sendData();
          }}
        >
          <div className="card-body">
            <div className="d-flex align-items-center mb-4">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Select Country")}</label>
              </div>
              <div className="form-floating  col-md-10  col-12">
                <div className="form-floating ">
                  <div className="form-select-wrapper">
                    <select
                      className="form-select"
                      required
                      aria-label="Default select example"
                      onChange={(e) => setCountryName(e.target.value)}
                      defaultValue={editRow.countryName}
                    >
                      <option selected> {t("Select Country")}</option>
                      <option value={"Egypt"}>Egypt </option>
                      <option value={"Qatar"}>Qatar </option>
                      <option value={"Saudi Arabia"}>Saudi Arabia</option>
                      <option value={"United Arab Emirates"}> </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Sender Name")}</label>
              </div>
              <div className="form-floating  col-md-10  col-12">
                <div className="form-floating ">
                  <input
                    id="textInputExample"
                    type="text"
                    className="form-control"
                    placeholder="Text Input"
                    defaultValue={editRow.senderName}
                    onChange={(e) => setSenderName(e.target.value)}
                    required
                  />
                  <label for="textInputExample">{t("Sender Name")}</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange "> {t("Type Of Activity")}</label>
              </div>
              <div className="form-floating  col-md-10  col-12">
                <div className="form-floating ">
                  <input
                    onChange={(e) => setActivity(e.target.value)}
                    required
                    id="textInputExample"
                    defaultValue={editRow.activity}
                    type="text"
                    className="form-control"
                    placeholder="Text Input"
                  />
                  <label for="textInputExample">{t("Type Of Activity")}</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Facebook Link")}</label>
              </div>
              <div className="form-floating  col-md-10  col-12">
                <div className="form-floating ">
                  <input
                    onChange={(e) => setFacebookLink(e.target.value)}
                    id="textInputExample"
                    defaultValue={editRow.facebookLink}
                    type="text"
                    className="form-control"
                    placeholder="Text Input"
                  />
                  <label for="textInputExample">{t("Facebook Link")}</label>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange "> {t("Website Link")}</label>
              </div>
              <div className="form-floating  col-md-10  col-12">
                <div className="form-floating ">
                  <input
                    onChange={(e) => setWebsiteLink(e.target.value)}
                    id="textInputExample"
                    defaultValue={editRow.websiteLink}
                    type="text"
                    className="form-control"
                    placeholder="Text Input"
                  />
                  <label for="textInputExample">{t("Website Link")}</label>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex align-items-center mb-4">
                <div className="form-select-wrapper  col-4 d-md-block ">
                  <label className="text-orange ">
                    {t("Commercial register")}
                  </label>
                </div>
                <div className=" col-md-4  col-6 d-flex  justify-content-end">
                  <button
                    label="Upload Excel"
                    icon="pi pi-upload"
                    className="btn btn-primary rounded-xl  fs-14"
                    onClick={() => document.getElementById("register").click()}
                  >
                    {t("Commercial register")}
                    <i className="uil uil-export  mx-2"></i>
                  </button>
                </div>
                <input
                  onChange={(e) => setcommercialRegister(e.target.files[0])}
                  id="register"
                  type="file"
                  accept=".pdf ,.png,.jpg, .xls"
                  style={{ display: "none" }}
                  //   onChange={handleUploadExcel}
                />
              </div>
              <div className="d-flex align-items-center mb-4">
                <div className="form-select-wrapper mb-4 col-4  d-md-block ">
                  <label className="text-orange ">{t("Tax id")}</label>
                </div>
                <div className="mb-4 col-md-4  col-6 d-flex  justify-content-end">
                  <button
                    label="Upload Excel"
                    icon="pi pi-upload"
                    className="btn btn-primary rounded-xl  fs-14"
                    onClick={() => document.getElementById("tax").click()}
                  >
                    {t("Tax id")}
                    <i className="uil uil-export  mx-2"></i>
                  </button>
                </div>
                <input
                  onChange={(e) => settaxId(e.target.files[0])}
                  id="tax"
                  type="file"
                  accept=".pdf ,.png,.jpg, .xls"
                  style={{ display: "none" }}
                  //   onChange={handleUploadExcel}
                />
              </div>
              <div className="d-flex align-items-center mb-4mb-4">
                <div className="form-select-wrapper mb-4 col-6 d-md-block ">
                  <label className="text-orange ">
                    {t("Delegation (Download Sample File)")}
                  </label>
                </div>
                <div className="mb-4 col-md-5  col-5">
                  <button
                    label="Upload Excel"
                    icon="pi pi-upload"
                    className="btn btn-primary rounded-xl  fs-14"
                    onClick={() =>
                      document.getElementById("Delegation").click()
                    }
                  >
                    {t("Delegation (Download Sample File)")}
                    <i className="uil uil-export  mx-2"></i>
                  </button>
                </div>
                <input
                  onChange={(e) => setdelegation(e.target.files[0])}
                  id="Delegation"
                  type="file"
                  accept=".pdf ,.png,.jpg, .xls"
                  style={{ display: "none" }}
                  //   onChange={handleUploadExcel}
                />
              </div>

              <div className="form-check my-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  {" "}
                  {t("Default checkbox")}
                </label>
              </div>
            </div>
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete ")}
              <b>{rowDelete?.group?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete the selected products?")}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
