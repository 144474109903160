import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { IoMdClose } from "react-icons/io";
import { Button } from "primereact/button";

function ContentAddSenderName() {
  const { token } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation();
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate("");
  const [typePayment, setTpePayment] = useState("offline");
  const [check, setCheck] = useState(true);
  const [typePaymentOffline, setTpePaymentOffline] = useState("vodafoneCash");
  const toast = useRef();
  const { user } = useSelector((state) => state.auth);
  const dataContent = JSON.parse(
    sessionStorage.getItem("contentAddSenderName")
  );
  console.log(dataContent, "dataContent");
  const API =
    "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2libUZ0WlNJNkltbHVhWFJwWVd3aUxDSndjbTltYVd4bFgzQnJJam94T1RnNGZRLnNFVjl0YnNCdTA2d3lHTDVBRTdIT3ZQYUVqZjVETll0aGhORUJiVDQ2YzBlbUtlelNFWld6WUxONUwzZldJeDFCdFhBMUU4QW1XOTUyYnNjT1Q5ZmlR"; // your api here
  const integrationID = 5432;
  // const integrationID = 2942;
  useEffect(() => {
    handlePaymob();
  }, []);

  const handlePaymob = async () => {
    let data = {
      api_key: API,
    };
    try {
      let request = await fetch("https://accept.paymob.com/api/auth/tokens", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      let response = await request.json();
      console.log(response);

      let token = response.token;
      secondStep(token);
    } catch (error) {
      console.log(error);
    }
  };

  async function secondStep(token) {
    console.log(dataContent?.data?.paidAmountCents);
    let data = {
      auth_token: token,
      delivery_needed: "false",
      amount_cents:
        dataContent?.data?.priceSenderName ||
        dataContent?.data?.paidAmountCents,
      currency: "EGP",
      items: [
        {
          name: "SenderName",
          amount_cents:
            dataContent?.data?.priceSenderName ||
            dataContent?.data?.paidAmountCents,
          // description: "Smart Watch",
          // quantity: "1",
        },
      ],
    };

    let request = await fetch(
      "https://accept.paymob.com/api/ecommerce/orders",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    let response = await request.json();

    let id = response.id;
    console.log(response);

    thirdStep(token, id);
  }

  async function thirdStep(token, id) {
    let data = {
      auth_token: token,
      amount_cents:
        (+dataContent?.data?.priceSenderName ||
          dataContent?.data?.paidAmountCents) * 100,
      expiration: 3600,
      order_id: id,
      billing_data: {
        apartment: "803",
        email: user?.userData?.email,
        floor: "42",
        first_name: "SenderName",
        street: user?.userData?._id,
        building: "8028",
        phone_number: "+86(8)9135210487",
        shipping_method: "PKG",
        postal_code: "01898",
        city: "Jaskolskiburgh",
        country:
          dataContent?.data?.countryId || dataContent?.data?.country?._id,
        last_name: "Nicolas",
        state: dataContent?.data?.senderName,
      },
      currency: "EGP",
      integration_id: integrationID,
    };
    console.log(data, "cuuu");

    let request = await fetch(
      "https://accept.paymob.com/api/acceptance/payment_keys",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    let response = await request.json();

    console.log(response);
    let TheToken = response.token;
    cardPayment(TheToken);
  }
  const [iframe, setIfreame] = useState("");
  async function cardPayment(token) {
    setLoading(false);
    let iframURL = `https://accept.paymob.com/api/acceptance/iframes/5440?payment_token=${token}`;
    setIfreame(
      `https://accept.paymob.com/api/acceptance/iframes/5440?payment_token=${token}`
    );
    // window.location.href = iframURL;
    // window.open(iframURL, "_blank");
  }
  const [priceSenderName, setPriceSenderName] = useState();
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <Toast ref={toast} />

      {/* <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-12 col-lg-12 col-xl-12 mx-auto">
              <h1 className="display31 mb-3">{t("Content Recharge")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/recharge-archieve"}>
                      {" "}
                      {t("Recharge archieve")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/recharge-add">{t("Recharge Your Balance")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/content-recharge-add">
                      {t("Content Recharge")}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section> */}
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-auto order-2">
            <section id="snippet-7" className="wrapper py-10">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">
                        {t("Country	")} :
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder">
                        {dataContent?.data?.nameCountry ||
                          dataContent?.data?.country?.countryName ||
                          dataContent?.data?.countryName}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">
                        {t("Sender Name	")} :
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder">
                        {dataContent?.data?.senderName}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">
                        {t("Activity")} :{" "}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder">
                        {dataContent?.data?.activity}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">{t("Price")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder text-green">
                        {dataContent?.data?.priceSenderName ||
                          dataContent?.data?.paidAmountCents}{" "}
                        EGP
                      </span>
                    </div>
                  </div>
                  <iframe
                    className="w-100 "
                    style={{ height: "130vh " }}
                    src={iframe}
                  ></iframe>

                  {/* <div className="d-flex align-items-center  mb-4 ">
                    <div className="col-2 d-md-block d-none"></div>
                    <div
                      style={{ border: "1px solid #eee" }}
                      className="p-1  col-md-6 col-12"
                    >
                      <div className="form-check  ">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label "
                          htmlFor="flexCheckDefault"
                        >
                          {" "}
                          {t("I Agree Agreement")}
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
          {/* <Aside/> */}
        </div>
      </div>
    </div>
  );
}

export default ContentAddSenderName;
