import React, { useEffect, useState } from "react";

import img from "../../assets/together-domain-hosting-company.png";
import { useTranslation } from "react-i18next";

import { Toast } from "primereact/toast";
import { useRef } from "react";

export default function SMSCharacterCounter({}) {
  const [charCount, setCharCount] = useState(1120);

  const [msgsCount, setMsgsCount] = useState(0);
  const [language, setLanguage] = useState("");
  const [maxDigits, setMaxDigits] = useState("");

  const [t] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toast = useRef(null);

  const isEnglish = () => {
    const valid =
      "\n\r\t\v" +
      "abcdefghijklmnopqrstuvwxyz\\ /* +-1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ.?؛×÷‘ٌ[]ـ؟()&%@!#=_^$~',|\":}{<>;";
    let lang = "en";
    const text = document.querySelector("textarea[name=msg]").value;

    for (let i = 0; i < text.length; i++) {
      const temp = "" + text.substring(i, i + 1);
      if (valid.indexOf(temp) === -1) {
        lang = "ar";
        break;
      }
    }

    if (lang === "en") {
      setLanguage("en");
      setMaxDigits(1120);
    } else {
      setLanguage("ar");
      setMaxDigits(490);
    }

    return lang;
  };

  // const handleSmsPress = () => {
  //   const lang = isEnglish();
  //   let maxDigits = lang === "ar" ? 490 : 1120;
  //   // setTextInput(document.querySelector("textarea[name=msg]").value);
  //   console.log(document.querySelector("textarea[name=msg]").value);

  //   const msgLength =
  //     document.querySelector("textarea[name=msg]").value.length +
  //     document.querySelector("textarea[name=msg]").value.split("\n").length -
  //     1;
  //   const newline = document
  //     .querySelector("textarea[name=msg]")
  //     .value.split("\n").length;
  //   console.log(
  //     document.querySelector("textarea[name=msg]").value.split("\n").length
  //   );
  //   console.log(maxDigits);
  //   if (msgLength > maxDigits) {
  //     console.log("good");
  //     document.querySelector("textarea[name=msg]").value = document
  //       .querySelector("textarea[name=msg]")
  //       .value.substr(0, maxDigits - newline);
  //     console.log(
  //       document.querySelector("textarea[name=msg]").value.substr(0, maxDigits)
  //     );
  //     maxDigits = msgLength;
  //   }

  //   const digitsLimit = maxDigits - msgLength;
  //   setCharCount(digitsLimit);
  // };
  const handleSmsPress = () => {
    const lang = isEnglish();
    let maxDigits = lang === "ar" ? 490 : 1120;
    // setTextInput(document.querySelector("textarea[name=msg]").value);
    console.log(document.querySelector("textarea[name=msg]").value);

    const msgLength =
      document.querySelector("textarea[name=msg]").value.length +
      document.querySelector("textarea[name=msg]").value.split("\n").length -
      1;
    const newline = document
      .querySelector("textarea[name=msg]")
      .value.split("\n").length;
    console.log(
      document.querySelector("textarea[name=msg]").value.split("\n").length
    );
    console.log(maxDigits);
    if (msgLength > maxDigits) {
      console.log("good");
      document.querySelector("textarea[name=msg]").value = document
        .querySelector("textarea[name=msg]")
        .value.substr(0, maxDigits - newline);
      console.log(
        document.querySelector("textarea[name=msg]").value.substr(0, maxDigits)
      );
      maxDigits = msgLength;
    }

    let msgsCount = 1;
    const digitsLimit = maxDigits - msgLength;
    setCharCount(digitsLimit);

    if (msgLength > 70 && lang === "ar") {
      msgsCount = Math.ceil(msgLength / 67);
      setMsgsCount(Math.ceil(msgLength / 67));
    } else if (msgLength > 160 && lang === "en") {
      msgsCount = Math.ceil(msgLength / 153);
      setMsgsCount(Math.ceil(msgLength / 153));
    } else {
      if (lang === "ar") {
        msgsCount = Math.ceil(msgLength / 70);
        setMsgsCount(Math.ceil(msgLength / 70));
      } else {
        msgsCount = Math.ceil(msgLength / 160);
        setMsgsCount(Math.ceil(msgLength / 160));
      }

      if (msgLength === 0) {
        // msgLength = "0";
        msgsCount = 1;
      }
    }

    // document.querySelector("#digits").innerHTML = msgLength;
    // document.querySelector("#digits_limit").innerHTML = digitsLimit;
    // document.querySelector("#msg_num").innerHTML = msgsCount;
  };

  const textareaRef = useRef(null);

  const copyToClipboard = async () => {
    try {
      // Check if the textarea reference is available
      if (textareaRef.current) {
        // Select the text in the textarea
        textareaRef.current.select();
        // Use the Clipboard API to copy the selected text to the clipboard
        await navigator.clipboard.writeText(textareaRef.current.value);
        // Deselect the text (optional)
        textareaRef.current.setSelectionRange(0, 0);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Text copied!",
          life: 3000,
        });
      }
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
  };
  return (
    <>
      <div className="content-wrapper">
        {/* <Header /> */}
        <Toast ref={toast} />
        <section className="wrapper  bg-soft-primary">
          <div className="container pt-5 text-center">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                <h1 className="display-1 mb-3">
                  <img src={img} alt="" />
                </h1>
                <p className="lead px-xxl-10"></p>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 p-0 m-auto order-2">
              <section id="snippet-2" className="wrapper pt-4">
                <div className="card">
                  <div className="card-body p-2">
                    <div className="d-flex align-items-center mb-4 ">
                      <div class="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">{t("Message")}</label>
                      </div>
                      <div class="form-floating col-md-8  col-12">
                        <div className="form-floating ">
                          <textarea
                            dir={language === "ar" ? "rtl" : "ltr"}
                            ref={textareaRef}
                            id="textareaExample"
                            className="form-control "
                            name="msg"
                            placeholder="Textarea"
                            style={{ height: "150px" }}
                            required
                            onChange={handleSmsPress}
                            maxLength={maxDigits}
                          />
                          <label htmlFor="textareaExample">
                            {t("Message")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class=" fs-15 mb-4" role="alert">
                      {/* <div className="fs-17 d-flex align-items-center ">
                        {t("Number of characters")} :
                        <span className="text-primary fs-30 mx-1">
                          {" "}
                          {" " + charCount}
                        </span>{" "}
                        {t("character")}
                      </div> */}
                      <div className="fs-17 d-flex align-items-center ">
                        - {t("characters") + " "} :
                        <span className="text-primary fs-30 mx-1">
                          {language === "ar"
                            ? 490 - charCount
                            : 1120 - charCount}
                        </span>
                        {/* {t("character")} */}
                      </div>
                      <div className="fs-17 d-flex align-items-center ">
                        - {t("SMS cost")} :
                        <span className="text-primary fs-30 mx-1">
                          {msgsCount}{" "}
                        </span>
                        {t("message")}
                      </div>
                    </div>
                    <div className="d-flex gap-4 justify-content-between col-lg-4 col-12 flex-wrap mb-3">
                      <button
                        className="btn btn-primary rounded-pill btn-login  mb-2"
                        onClick={copyToClipboard}
                      >
                        Copy
                      </button>
                      <button
                        className="btn btn-red rounded-pill btn-login  mb-2"
                        onClick={() => {
                          document.querySelector("textarea[name=msg]").value =
                            "";
                          if (language === "ar") {
                            setCharCount(490);
                          } else {
                            setCharCount(1120);
                          }
                          setMsgsCount(0);
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <Aside /> */}
          </div>
        </div>
      </div>
    </>
  );
}
