import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useTranslation } from "react-i18next";
import Aside from "../../components/Aside";

function Confirm() {
  const [t, i18n] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content-wrapper">
      {/* <Header /> */}

      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display31 mb-3">{t("Confirm")}</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-7" class="wrapper py-10">
              <div class="card">
                <div class="card-body">
                  <div class="card shadow-lg card-border-top border-primary">
                    <div class="card-body">
                      <div className="text-center ">
                        <label className="text-orange ">{t("Send to :")}</label>
                        <span>122 Individual</span>
                      </div>
                      <div className="text-center ">
                        <label className="text-orange ">
                          {t("Message Cost :")}
                        </label>
                        <span>122</span>
                      </div>
                      <div className="text-center ">
                       
                        {t("At a rate of messages")}
                        <label className="text-orange mx-1"> 2 </label>
                        {/* <span>122</span> */}
                        
                        {t("per phone number")}
                      </div>
                      <div className="text-center ">
                        <label className="text-orange ">
                          {t("Message will be sent after confirm")}
                        </label>
                      </div>
                      <div className="text-center">
                        <a
                          href="/"
                          class="btn btn-expand btn-primary rounded-pill my-4"
                        >
                          <i class="uil uil-arrow-right"></i>
                          <span>{t("Confirm")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <Aside/> */}
        </div>
      </div>
    </div>
  );
}

export default Confirm;
