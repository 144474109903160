import React, { useEffect, useState } from "react";

import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import Aside from "../../components/Aside";
import MessageFinishedTable from "./MessageFinishedTable";
import TableArchive from "../MessageArchieve/TableArchive";
export default function MessageFinished({ selectedOption, setSelectedOption }) {
  const [textInput, setTextInput] = useState("");
  const [charCount, setCharCount] = useState(0);
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setTextInput(inputValue);
    setCharCount(inputValue.length);
  };
  const options = Array.from({ length: 60 }, (_, index) => index);
  const currentDate = new Date();
  const [timeSend, setTimeSend] = useState("now");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [t, i18n] = useTranslation();
  const [modalOpened, setModalOpened] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display-1 mb-3">{t("SMS Reports")} </h1>
              <p className="lead px-xxl-10"></p>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/message-finished"}> {t("SMS Reports")}</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-2" className="wrapper pt-10 ">
              <button
                className="btn  btn-primary  mb-5"
                onClick={() => handleReload()}
              >
                Reload
              </button>
              <h2 className="mb-5"> {t("SMS Reports")}</h2>

              <MessageFinishedTable />
              {/* <MessageFinishedTable /> */}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
