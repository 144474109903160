import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useTranslation } from "react-i18next";
import { GiCancel } from "react-icons/gi";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "../../components/Select";
import { Paginator } from "primereact/paginator";
const data = [
  { id: 1, name: "John Doe", phone: "123-456-7890" },
  { id: 2, name: "Jane Smith", phone: "987-654-3210" },
  { id: 3, name: "Jasrse Smith", phone: "987-654-3210" },
  { id: 4, name: "Jane Smith", phone: "932487-654-3210" },
  { id: 5, name: "srde Smith", phone: "98234654-3210" },
  { id: 6, name: "Jsd Smith", phone: "987-63244-3210" },
  { id: 7, name: "J Smith", phone: "987-654-3450" },
  // Add more data entries
];

function SelectNumbers({
  selectedOption,
  setSelectedOption,
  setModalOpened,
  modalOpened,
  setSelectedRows = { setSelectedRows },
  selectedRows = { selectedRows },
  deleteGroup = { deleteGroup },
  setSelectGroupContacts,
  selectedGroupContacts,
}) {
  const { user, token } = useSelector((state) => state.auth);
  const URL = process.env.URL_SERVER;

  const [globalFilter, setGlobalFilter] = useState("");
  const [addGroup, setAddGroup] = useState(false);
  const [t, i18n] = useTranslation();
  const [selectGroup, setSelectGroup] = useState(true);

  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loadinggroup, setLoadinggroup] = useState();

  const fetchData = async () => {
    // You should replace this with your actual data fetching logic.
    setLoadinggroup(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/Group/get-user-groups` + "?page=" + page,

        config
      );
      console.log(res);
      setLoadinggroup(false);
      if (res.status === 200) {
        if (res.data.groups.length > 0) {
          setData((prevItems) => [...prevItems, ...res.data.groups]);
        } else {
          setHasMore(false);
        }

        setTotalPages(res.data.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoadinggroup(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, [page]);

  const [openGroup, setOpenGroup] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);

  const [dataContact, setDataContact] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState();

  const getDataSearch = async (page, value) => {
    if (!value) {
      value = "";
    }
    setLoading(true);
    console.log(
      `https://togethersms.com/contact/get-group-contacts/` +
        openGroup?.group?._id +
        "?page=" +
        page +
        "&searchField=" +
        value
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/contact/get-group-contacts/` +
          openGroup?.group?._id +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      if (res.status === 200) {
        setDataContact(res.data.group.contact);
        setTotalPages(res.data.totalContacts / 10);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage, openGroup]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const onRowSelect = (event) => {
    setSelectedRows(event.value);
    const idAndNameArray = event.value.map((item) => ({
      groupId: item.group[0]._id,
      contactsId: [item._id],
    }));
    console.log(idAndNameArray, "dsd");
    setSelectGroupContacts(idAndNameArray);
  };

  const printSelected = () => {
    console.log(selectedRows);
    setAddGroup(true);
    setSelectGroup(false);
    const selectedData = selectedRows.map((item) => ({
      groupId: openGroup._id,
      contactsName: [item.name],
    }));
    console.log("Selected Rows:", selectedData);
  };
  const header = (
    <div className="table-header">
      <div class="form-floating col-md-6 mb-4">
        <input
          id="textInputExample"
          type="text"
          class="form-control"
          placeholder="Text Input"
          onInput={(e) => {
            setGlobalFilter(e.target.value);
            setCurrentPage(1);

            getDataSearch(1, e.target.value);
          }}
          // value={globalFilter}
          // onChange={(e) => setGlobalFilter(e.target.value)}
        />
        <label for="textInputExample">{t("Search")}</label>
      </div>
    </div>
  );

  return (
    <>
      <div className="card">
        <div className="card-body p-2">
          <div
            className="form-select-wrapper"
            style={{
              position: "relative",
            }}
          >
            <Select
              setPage={setPage}
              loading={loadinggroup}
              page={page}
              data={data}
              hasMore={hasMore}
              setOpenGroup={setOpenGroup}
              setOpenSelect={setOpenSelect}
              setSelectGroup={setSelectGroup}
              openGroup={openGroup}
            />
          </div>
        </div>
        {openGroup && (
          <>
            {selectGroup && (
              <div className="card-body p-3">
                <DataTable
                  value={dataContact}
                  selection={selectedRows}
                  onSelectionChange={onRowSelect}
                  loading={loading}
                  header={header}
                >
                  <Column selectionMode="multiple" style={{ width: "3rem" }} />
                  <Column field="phoneNumber" header="Phone" />
                  <Column field="name" header="Name" />
                </DataTable>
                <Paginator
                  first={currentPage * 10 - 10}
                  rows={10}
                  totalRecords={totalPages * 10}
                  onPageChange={onPageChange}
                />

                <div
                  class="btn btn-expand btn-primary rounded-pill mt-5"
                  onClick={printSelected}
                >
                  <i class="uil uil-arrow-right"></i>
                  <span>{t("Apply")}</span>
                </div>
              </div>
            )}{" "}
          </>
        )}
        {addGroup && (
          <div className="mt-4">
            <ul className="row p-1">
              {selectedRows?.map((e) => (
                <li className="d-flex col-md-6 mb-3 justify-content-between align-items-center">
                  {e.phoneNumber} ({e?.group[0]?.name}){" "}
                  <GiCancel
                    onClick={() => deleteGroup(e._id)}
                    className="text-primary cursor-pointer"
                  />
                </li>
              ))}
            </ul>
            <div className="card-body d-flex gap-3">
              <div
                onClick={() => setModalOpened(false)}
                class="btn btn-soft-yellow rounded-pill"
              >
                {t("Ok")}
              </div>
              <div
                onClick={() => setSelectGroup(true)}
                class="btn btn-soft-yellow rounded-pill"
              >
                {t("Edit")}
              </div>
            </div>
          </div>
        )}

        {/*/.card-footer */}
      </div>
    </>
  );
}

export default SelectNumbers;
