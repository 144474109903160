import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { Link, useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";
import { logout } from "../../store/slices/authSlice";
import AddContact from "../Contatcts/AddContact";
import AddFileContact from "../Contatcts/AddFileContact";
export default function ProductsDemo() {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [productDialogContact, setProductDialogContact] = useState(false);
  const [addNumberInGroup, setAddNumberInGroup] = useState(false);

  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { user, token } = useSelector((state) => state.auth);
  const [editRow, setEditRow] = useState("");

  const [t, i18n] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [phoneNumber, setphoneNumber] = useState("");
  const [change, setchange] = useState(false);
  const [nameContact, setNameContact] = useState("");
  const getDataSearch = async (page, value) => {
    console.log(token);
    setLoading(true);
    if (!value) {
      value = "";
    }
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/Group/get-user-groups?lang=${i18n.language}` +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      setLoading(false);
      if (res.status === 200) {
        setData(res.data.groups);
        setTotalPages(res.data.total / 10);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (
        error?.response?.data?.message ==
          "Token is invalid. Please log in again." ||
        "Unauthorized"
      ) {
        console.log("done");
        dispatch(logout());
      }
      //  setError(true);
    }
  };

  ///sendData
  const sendData = async (e) => {
    e.preventDefault();
    setName("");
    if (name != "") {
      hideDialog();
    }
    setLoading(true);
    let data = {
      name,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      // console.log(`https://togethersms.com/Group/create?lang=${i18n.language}`);
      const res = await axios.post(
        `https://togethersms.com/Group/create?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        // getData();
        getDataSearch(currentPage, globalFilter);
        setLoading(false);
        setLoading(false);
        setName("");
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: t(res.data.message),
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage, change]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const handleEdit = async () => {
    hideDialog();
    setLoading(true);
    setEditRow("");
    console.log(token);
    let data = {
      name,
    };
    console.log(editRow);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/Group/update/${editRow.group._id}?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage, globalFilter);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: t(res.data.message),
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handleDelete = async () => {
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");

    const data = {
      groupIds: [rowDelete.group._id],
    };
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*/*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/Group/delete?lang=${i18n.language}`,
        config
        // Include the data as a separate property in the config object
      );

      console.log(res);
      if (res) {
        getDataSearch(currentPage, globalFilter);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: t(res.data.message),
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const deleteMultipleHandle = async () => {
    hideDeleteProductsDialog();
    setSubmitted(true);
    console.log("user", user);
    console.log("user", token);

    const idArray = selectedProducts.map((item) => item.group._id);
    const data = {
      groupIds: idArray,
    };
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*/*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/Group/delete`,
        config
        // Include the data as a separate property in the config object
      );

      console.log(res);
      if (res) {
        getDataSearch(currentPage, globalFilter);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: t(res.data.message),
          life: 3000,
        });
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
    // try {
    //   const config = {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       withCredentials: false,
    //       Authorization: `Bearer ${token}`,
    //     },
    //   };
    //   const res = await axios.delete(
    //     `https://togethersms.com/Group/delete`,
    //     data,
    //     config
    //   );
    //   console.log(res);
    //   if (res.status === 200) {
    //     setEditRow("");
    //     // getData();
    //     setDeleteProductDialog(false);
    //     toast.current.show({
    //       severity: "success",
    //       summary: "Successful",
    //       detail: "Deleted Successful",
    //       life: 3000,
    //     });
    //   } else {
    //     console.log(res);
    //     setEditRow("");
    //     setDeleteProductDialog(false);
    //   }
    // } catch (error) {
    //   // setLoading(false);
    //   console.log(error);
    //   setEditRow("");
    //   setDeleteProductDialog(false);

    //   //  setError(true);
    // }
  };

  const [exp, setExport] = useState("");

  // useEffect(() => {

  // }, [token, data]);

  ///////////

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setEditRow("");
    setSubmitted(false);
    setProductDialog(false);
  };
  const handleAddOneInGrroup = (e) => {
    setProductDialogContact(e);
    setAddNumberInGroup(true);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setEditRow(product);
    setProductDialog(true);
  };

  const [rowDelete, setRowDelete] = useState("");
  const exportToExcel = async () => {
    const idArray = selectedProducts.map((item) => item.group._id);
    const array = {
      groupIds: idArray,
    };
    console.log(array);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      };
      const res = await axios.post(
        `https://togethersms.com/Group/export-selectedUser-group`,
        array,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        // getData();
        if (res) {
          const blob = new Blob([res.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      } else {
        console.log(res);
        setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }

    fetch(`https://togethersms.com/Group/export-selectedUser-group`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(array),
    })
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          console.log(response.json());
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const exportAll = () => {
    fetch(`https://togethersms.com/Group/export-allUser-groups`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response);
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {});
  };
  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const leftToolbarTemplate = () => {
    return (
      <div>
        <div className="flex flex-wrap  fs-5 gap-3">
          <button
            label="Delete"
            icon="pi pi-trash"
            severity="danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedProducts || !selectedProducts.length}
            className="btn btn-danger rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Delete")}
            <i className="uil uil-shopping-basket mx-2"></i>
          </button>
          <button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openNew}
            className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Create Group")}
            <i className="uil uil-plus mx-2"></i>
          </button>{" "}
          <button
            label="Export Selected to Excel"
            icon="pi pi-file-excel"
            onClick={exportToExcel}
            disabled={!selectedProducts || !selectedProducts.length}
            className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Export")}
            <i className="uil uil-import mx-2"></i>
          </button>{" "}
          <button
            label="Export Selected to Excel"
            icon="pi pi-file-excel"
            onClick={exportAll}
            className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Export All")}
            <i className="uil uil-import mx-2"></i>
          </button>
        </div>
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
          <span className="p-input-icon-left col-12 col-md-12">
            <i className="pi pi-search" />
            <div className="form-floating mt-4">
              <input
                id="textInputExample"
                type="search"
                onInput={(e) => {
                  setGlobalFilter(e.target.value);
                  setCurrentPage(1);

                  getDataSearch(1, e.target.value);
                }}
                placeholder="Search..."
                className="form-control"
              />
              <label htmlFor="textInputExample">{t("Search...")}</label>
            </div>
          </span>
        </div>
      </div>
    );
  };

  // const header = (

  // );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteMultipleHandle}
      />
    </React.Fragment>
  );

  const nameBodyTemplate = (rowData) => {
    return (
      <Link
        to={`/smspro-groups_grid/${rowData.group._id}`}
        className="d-flex align-items-center gap-2 fs-20 fw-bold"
      >
        {rowData.group.name} <span className="fs-10 mb-0">(Click here)</span>
      </Link>
    );
  };

  const updateStatus = async (e) => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    setLoading(true);

    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const status = await axios.patch(
        `https://togethersms.com/group/update-group-blacklist/${e.group._id}`,
        config,
        config
      );
      console.log(status);
      if (status.status === 200) {
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${token}`,
            },
          };
          const res = await axios.get(
            `https://togethersms.com/Group/get-user-groups` +
              "?page=" +
              currentPage +
              "&searchField=" +
              globalFilter,

            config
          );
          console.log(status);
          setLoading(false);
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: t(status?.data?.message),
              life: 3000,
            });
            setLoading(false);
            setData(res.data.groups);
            setTotalPages(res.data.total / 10);
          } else {
            console.log(res);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);

          //  setError(true);
        }
      } else {
        console.log(status);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const handleToggleChange = (rowData, value) => {
    updateStatus(rowData);

    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData.group._id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    // console.log(rowData.group.blackList);
    return (
      <InputSwitch
        checked={!rowData.group.blackList || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData?.group?.isPending ? (
          <div className="d-flex gap-2 align-items-center">Pending</div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <i
              className="uil uil-edit fs-17 text-blue cursor-pointer"
              icon="pi pi-pencil mr-2"
              onClick={() => {
                editProduct(rowData);
              }}
            ></i>
            <AiOutlineDelete
              className="uil uil-backspace fs-17 text-red cursor-pointer"
              onClick={() => confirmDeleteProduct(rowData)}
            />

            <i
              onClick={() => setImportExcel(rowData)}
              className="uil uil-copy  fs-17 text-green cursor-pointer"
            ></i>

            <i
              className="uil uil-plus-circle fs-17 text-green cursor-pointer"
              onClick={() => handleAddOneInGrroup(rowData)}
            ></i>
          </div>
        )}
      </React.Fragment>
    );
  };
  const [uploadExcel, setUploadExcel] = useState("");
  const [expContact, setExportContact] = useState("");
  const [err, setError] = useState("");
  const [importExcel, setImportExcel] = useState(false);
  const [loadingsaveProduct, setloadingsaveProduct] = useState(false);

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          lazy
          loading={loading}
          width={"100%"}
          ref={dt}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          // header={header}
          selectionMode={"checkbox"}
          totalRecords={20}
        >
          <Column selectionMode="multiple" exportable={false}></Column>

          <Column
            className="fs-15 fw-bold"
            body={nameBodyTemplate}
            field="name"
            header="Name"
            sortable
            style={{ minWidth: "5rem" }}
          ></Column>
          <Column
            className="fs-15 fw-bold"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2 fs-20 fw-bold">
                    {rowData.totalContacts.toLocaleString("en")}
                  </div>
                </React.Fragment>
              );
            }}
            header="Contatcs Number"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-15 "
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData?.group?.updatedAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header="Last Import Date"
            sortable
            style={{ minWidth: "7rem" }}
          ></Column>

          <Column
            className="fs-15 "
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData?.group?.createdAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header={t("Created At")}
            sortable
            style={{ minWidth: "2rem" }}
          ></Column>
          <Column
            className="fs-15 fw-bold"
            header="Active"
            body={statusBodyTemplate2}
            style={{ minWidth: "2rem" }}
          ></Column>

          <Column
            className="fs-15 fw-bold"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />
      <AddContact
        productDialog={productDialogContact}
        setProductDialog={setProductDialogContact}
        setchange={setchange}
        name={nameContact}
        phoneNumber={phoneNumber}
        setphoneNumber={setphoneNumber}
        setName={setNameContact}
        toast={toast}
        change={change}
        id={productDialogContact?.group?._id}
      />
      <AddFileContact
        uploadExcel={uploadExcel}
        setUploadExcel={setUploadExcel}
        setchange={setchange}
        toast={toast}
        change={change}
        id={importExcel?.group?._id}
        setExport={setExportContact}
        setError={setError}
        setImportExcel={setImportExcel}
        setloadingsaveProduct={setloadingsaveProduct}
        setProductDialog={setProductDialog}
        importExcel={importExcel}
      />
      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={editRow?.group?.name ? t("Edit Group Name") : t("Group Name")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) =>
                editRow?.group?.name ? handleEdit() : sendData(e)
              }
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <form>
          <div className="field">
            <label htmlFor="name" className="font-bold">
              {t("Name")}
            </label>
            <InputText
              id="name"
              onChange={(e) => setName(e.target.value)}
              required
              defaultValue={editRow?.group?.name}
              autoFocus
              className={classNames({
                "p-invalid": submitted && !product.name,
              })}
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete ")}
              <b>{rowDelete?.group?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete the selected products?")}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
