import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Link } from "react-router-dom";

function ContentRechargeAdd() {
  const [t] = useTranslation();
  const { user, token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);

  const toast = useRef(null);
  const [countries, setcountries] = useState();
  const URL = process.env.URL_SERVER;

  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/country/user-activated-countries`,

        config,
        config
      );
      console.log(res.data);
      if (res) {
        setcountries(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getDataGetway();
  }, []);

  const dataContent = JSON.parse(sessionStorage.getItem("contentAddRechage"));

  const API =
    "ZXlKaGJHY2lPaUpJVXpVeE1pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2libUZ0WlNJNkltbHVhWFJwWVd3aUxDSndjbTltYVd4bFgzQnJJam94T1RnNGZRLnNFVjl0YnNCdTA2d3lHTDVBRTdIT3ZQYUVqZjVETll0aGhORUJiVDQ2YzBlbUtlelNFWld6WUxONUwzZldJeDFCdFhBMUU4QW1XOTUyYnNjT1Q5ZmlR"; // your api here
  const integrationID = 5432;
  // const integrationID = 2942;

  useEffect(() => {
    handlePaymob();
  }, []);
  const handlePaymob = async () => {
    // e.preventDefault();
    setLoading(true);
    let data = {
      api_key: API,
    };
    try {
      let request = await fetch("https://accept.paymob.com/api/auth/tokens", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });

      let response = await request.json();
      console.log(response);

      let token = response.token;
      secondStep(token);
    } catch (error) {
      console.log(error);
    }
  };

  async function secondStep(token) {
    let data = {
      auth_token: token,
      delivery_needed: "false",
      amount_cents: "100",
      currency: `EGP`,
      items: [
        {
          name: "Points", // SenderName
          amount_cents: dataContent?.data?.paidAmount,
          description: "Smart Watch",
          quantity: dataContent?.data?.points,
        },
      ],
    };

    console.log(data, "data all price");
    let request = await fetch(
      "https://accept.paymob.com/api/ecommerce/orders",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    let response = await request.json();

    let id = response.id;
    console.log(response);

    thirdStep(token, id);
  }

  async function thirdStep(token, id) {
    let data = {
      auth_token: token,
      amount_cents: +dataContent?.data?.paidAmount * 100,
      expiration: 3600,
      order_id: id,
      billing_data: {
        apartment: "803",
        email: user?.userData?.email,
        floor: "42",
        first_name: "kjh",
        street: user?.userData?._id,
        building: "8028",
        phone_number: "+86(8)9135210487",
        shipping_method: "PKG",
        postal_code: "01898",
        city: "Jaskolskiburgh",
        country: dataContent?.data?.currency,
        last_name: "Nicolas",
      },
      currency: "EGP",
      integration_id: integrationID,
    };

    console.log("data", data);

    let request = await fetch(
      "https://accept.paymob.com/api/acceptance/payment_keys",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );

    let response = await request.json();

    console.log(response);
    let TheToken = response.token;
    cardPayment(TheToken);
  }
  const [iframe, setIfreame] = useState("");
  async function cardPayment(token) {
    setLoading(false);
    let iframURL = `https://accept.paymob.com/api/acceptance/iframes/5440?payment_token=${token}`;
    setIfreame(
      `https://accept.paymob.com/api/acceptance/iframes/5440?payment_token=${token}`
    );
    // window.location.href = iframURL;
    // window.open(iframURL, "_blank");
  }

  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <Toast ref={toast} />

      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-auto order-2">
            <section id="snippet-7" className="wrapper py-10">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">
                        {t("Country	")} :
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder ">
                        {dataContent?.data?.nameCountry}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">
                        {t("Paid Amount	")} :
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder ">
                        {dataContent?.data?.paidAmount}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">
                        {t("paymentMethod")} :{" "}
                      </label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder text-green ">
                        {dataContent?.data?.paymentMethod} EGP{" "}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center  mb-4 ">
                    <div className="form-select-wrapper  col-12 col-md-3 d-md-block">
                      <label className="text-orange mb-0">{t("points")}</label>
                    </div>
                    <div className="form-floating  col-md-6 col-12">
                      <span className="fs-20 fw-bolder ">
                        {dataContent?.data?.points}
                      </span>
                    </div>
                  </div>
                  <iframe
                    className="w-100 "
                    style={{ height: "130vh " }}
                    src={iframe}
                  ></iframe>

                  {/* <div className="d-flex align-items-center  mb-4 ">
                    <div className="col-2 d-md-block d-none"></div>
                    <div
                      style={{ border: "1px solid #eee" }}
                      className="p-1  col-md-6 col-12"
                    >
                      <div className="form-check  ">
                        <input
                          className="form-check-input "
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label "
                          htmlFor="flexCheckDefault"
                        >
                          {" "}
                          {t("I Agree Agreement")}
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </section>
          </div>
          {/* <Aside/> */}
        </div>
      </div>
    </div>
  );
}

export default ContentRechargeAdd;
