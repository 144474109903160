import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ButtonCreateNewGroup({ data }) {
  const [t] = useTranslation();

  const navigate = useNavigate();
  const toast = useRef(null);

  return (
    <>
      <div className="d-flex justify-content-start mt-0">
        <Toast ref={toast} />
        <div className="d-flex gap-3  mt-0">
          <button
            className="btn btn-primary rounded-pill mt-0 mb-0 text-nowrap"
            onClick={() => {
              navigate(`/send-message/${data._id}`);
            }}
          >
            {t("Re-send to failed numbers")}
          </button>
        </div>
      </div>
    </>
  );
}

export default ButtonCreateNewGroup;
