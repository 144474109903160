import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Spinner } from "react-bootstrap";

function SendCodeResetPass() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const [count, setCount] = useState(10);
  const token = localStorage.getItem("tokenSms");

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const handleSubmit = async () => {
    console.log(token);

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/confirm-email-verification/` + code,
        config,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) {
        console.log("first");
        //   dispatch(verifyEmail());
        navigate("/reset-password");
        setLoading(false);
      } else {
        console.log(res);
        // setError(true);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
    }
  };

  const resetCode = async () => {
    // setLoading(true);
    setCount(180);

    try {
      const res = await axios.post(`https://togethersms.com/user/resendEmail`);
      if (res.statusCode === 200) {
        console.log("res", res);
        // setLoading(false);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //   setError(true);
    }
  };
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section
        className="wrapper  bg-gradient-primary"
        // style={{ backgroundImage: img }}
      >
        <div className="container  text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Send To Email")} </h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Send To Email")}
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container ">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto">
              <div className="card">
                <div className="card-body p-11 text-center">
                  <h2 className="mb-3 text-start">{t("Send To Email")} </h2>
                  <p className="lead mb-6 text-start">
                    {/* {t("Fill your email and password to sign in.")} */}
                  </p>
                  <form
                    className="text-start mb-3"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        id="loginEmail"
                        onChange={(e) => setCode(e.target.value)}
                      />
                      <label htmlFor="loginEmail">{t("Code")}</label>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {" "}
                          {/* Loading...{" "} */}
                        </Spinner>
                      ) : null}
                      {t("Confirmation")}{" "}
                    </button>
                  </form>
                  {count > 0 && (
                    <p className="mt-2 text-center">
                      {t("Time Remaining")}: {count} {t("seconds")}
                    </p>
                  )}
                  {count === 0 && (
                    <div className="mt-2 text-center">
                      <p className="mb-0">
                        {t("Didn't receive a code ?")}
                        <div
                          onClick={() => resetCode()}
                          // to="/auth-pass-reset-basic"
                          className="fw-semibold text-primary text-decoration-underline cursor-pointer"
                        >
                          {t("Resend")}
                        </div>{" "}
                      </p>
                    </div>
                  )}
                  {/* /form */}

                  {/*/.social */}
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </div>
  );
}

export default SendCodeResetPass;
