import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Toast } from "primereact/toast";

import { useFormik } from "formik";
import * as Yup from "yup";
function Register() {
  const { token } = useSelector((state) => state.auth);
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
  const [countries, setcountries] = useState();
  const toast = useRef();

  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(config);

      const res = await axios.get(
        `https://togethersms.com/country/user-countries`,

        config,
        config
      );
      console.log(res.data);
      if (res) {
        setcountries(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getDataGetway();
  }, []);

  const handleSubmit = async (value) => {
    setLoading(true);

    console.log(value);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/signup`,
        value,
        config
      );
      console.log(res);
      if (res) {
        //  dispatch(adminVerfiy(data));
        localStorage.setItem("tokenSms", res.data.token);

        console.log("res", res);
        navigate("/send-email");
        localStorage.setItem("email", value.email);
        localStorage.setItem("phone", value.phoneNumber);
        // setLoading(false);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      console.log(error);
      setLoading(false);
      //  setError(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
      country: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
      country: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log("Form submitted with values:", values);
      handleSubmit(values);
    },
  });
  return (
    <div className="content-wrapper">
      <Toast ref={toast} />

      {/* <Header /> */}
      <section
        className="wrapper  bg-gradient-primary"
        data-image-src="./assets/img/photos/bg18.png"
      >
        <div className="container  pt-md-5 pb-md-12 text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Sign Up")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Sign Up")}
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}

      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-8 col-xl-8 col-xxl-8 mx-auto mt-n10">
              <div className="card">
                <div className="card-body p-5 text-center">
                  <h2 className="mb-3 text-start"> {t("Sign Up")}</h2>
                  <p className="lead mb-6 text-start">
                    {t("Registration takes less than a minute.")}
                  </p>
                  <div>
                    <h1>Signup</h1>
                  </div>
                  <form
                    className="text-start mb-3 row"
                    onSubmit={formik.handleSubmit}
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   handleSubmit();
                    // }}
                  >
                    <div className="form-floating mb-4 col-12 ">
                      <input
                        style={
                          formik.touched.email && formik.errors.email
                            ? { border: "1px solid red" }
                            : { border: "none" }
                        }
                        // type="email"
                        className="form-control"
                        placeholder="Email"
                        id="loginEmail"
                        // type="email"
                        // id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="loginEmail">{t("Email")}</label>
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-red">{formik.errors.email}</div>
                      ) : null}
                    </div>

                    <div className="form-floating mb-4 col-12 ">
                      <select
                        placeholder="Email"
                        style={
                          formik.touched.country && formik.errors.country
                            ? { border: "1px solid red" }
                            : { border: "none" }
                        }
                        className="form-select"
                        aria-label="Default select example"
                        // onChange={(e) => setCountry(e.target.value)}
                        id="country"
                        name="country"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      >
                        <option selected> {t("Select Country")}</option>
                        {countries?.map((e) => (
                          <option value={e._id}>{e.countryName}</option>
                        ))}
                      </select>
                      <label htmlFor="loginEmail">{t("Select Country")}</label>

                      {formik.touched.country && formik.errors.country ? (
                        <div className="text-red">{formik.errors.country}</div>
                      ) : null}
                    </div>

                    {/* <div className="form-floating mb-4 col-12 ">
                      <PhoneInput
                        country={"eg"} // Set the default country
                        value={""} // Set the initial value
                        onChange={(value, country) => {
                          setPhoneNumber(value);
                          setCountry(country);
                        }}
                      />
                    </div> */}

                    <div className="form-floating password-field mb-4 col-12 ">
                      <input
                        className="form-control"
                        style={
                          formik.touched.password && formik.errors.password
                            ? { border: "1px solid red" }
                            : { border: "none" }
                        }
                        // type="password"
                        id="password"
                        name="password"
                        type={passwordShow ? "text" : "password"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        {passwordShow ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>
                      <label htmlFor="loginPassword">{t("Password")}</label>
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-red">{formik.errors.password}</div>
                      ) : null}
                    </div>
                    {/* <div className="form-floating password-field mb-4 col-12 ">
                      <input
                        className="form-control"
                        id="loginPassword"
                        name="password"
                        //   value={validation.values.password || ""}
                        // type={passwordShow ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() => setPasswordShow(!passwordShow)}
                      >
                        {passwordShow ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>
                      <label htmlFor="loginPassword">{t("Password")}</label>
                    </div> */}
                    <div className="form-floating password-field mb-4 col-12 ">
                      <input
                        className="form-control"
                        style={
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword
                            ? { border: "1px solid red" }
                            : { border: "none" }
                        }
                        // id="loginPassword"
                        // name="password"
                        // //   value={validation.values.password || ""}
                        type={passwordShowConfirm ? "text" : "password"}
                        // placeholder="Enter Confirm Password"
                        // onChange={(e) => setConfirmPassword(e.target.value)}
                        // type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                        type="button"
                        id="password-addon"
                        onClick={() =>
                          setPasswordShowConfirm(!passwordShowConfirm)
                        }
                      >
                        {passwordShowConfirm ? (
                          <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                        ) : (
                          <i className="ri-eye-fill uil uil-eye align-middle"></i>
                        )}
                      </button>
                      <label htmlFor="loginPassword">
                        {t("Confirm Password")}
                      </label>
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div className="text-red">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>

                    <div className="row">
                      <button
                        type="submit"
                        className="btn btn-primary rounded-pill btn-login  mb-2"
                        style={{ width: "fit-content", margin: "auto" }}
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            {/* Loading...{" "} */}
                          </Spinner>
                        ) : null}

                        {t("Sign Up")}
                      </button>
                    </div>
                  </form>
                  {/* /form */}
                  <p className="mb-0">
                    {" "}
                    {t("Already have an account?")}
                    <Link to="/login" className="hover">
                      {t("Login")}
                    </Link>
                  </p>

                  {/*/.social */}
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </div>
  );
}

export default Register;
