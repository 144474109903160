import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

import SelectGroup from "./SelectGroup";
import { GiCancel } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { saveAs } from "file-saver";
import { loginSuccess } from "../../store/slices/authSlice";
export default function SendMessage({ selectedOption, setSelectedOption }) {
  const { user, token } = useSelector((state) => state.auth);
  const [message, setTextInput] = useState("");
  const [charCount, setCharCount] = useState(1120);
  const [arrayToDownload, setarrayToDownload] = useState("");
  const [date, setDate] = useState("");
  const [time, settime] = useState("");

  const [lang, setLang] = useState("ar"); // Set the default language to Arabic
  const [msgsCount, setMsgsCount] = useState(0);
  const [msgsCountDefault, setMsgsCountDefault] = useState(0);
  const [language, setLanguage] = useState("");
  const [maxDigits, setMaxDigits] = useState("");
  const URL = process.env.URL_SERVER;

  function detectLanguage(word) {
    const arabicRegex = /[\u0600-\u06FF]/;
    if (arabicRegex.test(word)) {
      return "ar";
    } else {
      return "en";
    }
  }
  const [numberCount, setNumberCount] = useState(0);

  const calculateMsgsCount = (charCount, lang) => {
    if (charCount >= 70 && lang === "ar") {
      console.log(charCount / 67);
      setMsgsCount(Math.ceil(charCount / 67));
    } else if (charCount > 160 && lang === "en") {
      setMsgsCount(Math.ceil(charCount / 153));
    } else {
      if (lang === "ar") {
        setMsgsCount(Math.ceil(charCount / 70));
      } else {
        setMsgsCount(Math.ceil(charCount / 160));
      }

      if (charCount === 0) {
        setCharCount("0");
        setMsgsCount(1);
      }
    }
  };
  const downloadArrayAsFile = () => {
    const textToSave = arrayToDownload.join("\n"); // Convert array to a string

    const blob = new Blob([textToSave], { type: "text/plain" });
    saveAs(blob, "array.txt");
  };

  const options = Array.from({ length: 60 }, (_, index) => index);
  const currentDate = new Date();
  const [timeSend, setTimeSend] = useState("now");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [t, i18n] = useTranslation();
  const [modalOpened, setModalOpened] = useState(false);

  const deleteGroup = (id) => {
    setSelectedRows(selectedRows.filter((row) => row._id !== id));
    // setSelectGroupContacts(
    //   selectedGroupContacts.filter((row) => console.log(row.groupId, id))
    // );
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [groupSelected, setGroupSelected] = useState("");
  const [allSenderName, setAllSenderName] = useState([]);
  const getSenderName = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      // get - CompanyActivations - ByUser;
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/get-User-CompanyActivated`,

        config
      );
      console.log(res);
      if (res.status === 200) {
        setAllSenderName(res.data.companyActivations);
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getSenderName();
  }, []);
  useEffect(() => {
    console.log("selectedRows", selectedRows);
  }, [selectedRows]);
  const toast = useRef(null);
  const [inputText, setinputText] = useState("");
  const [senderName, setsenderName] = useState("");
  const [confirm, setConfirm] = useState("");
  const [selectGroupId, setSelectGroupId] = useState("");
  const [selectedGroupContacts, setSelectGroupContacts] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [details, setDetails] = useState("");
  const [openVerfiy, setOpenVerfiy] = useState(false);
  const [tokenVerfiy, setTokenVerfiy] = useState("");
  const navigate = useNavigate();
  const sendData = async (confirm) => {
    setCount(180);
    setLoading(true);
    let data = {
      inputText,
      senderName,
      message,

      selectedGroupContacts,
    };
    if (selectGroupId) {
      data.selectedGroups = selectGroupId;
    }
    console.log(time, "time");
    if (timeSend === "later") {
      const combinedDateTime = new Date(`${date}T${time}:00Z`.toLocaleString());
      console.log(combinedDateTime);
      // Adjust for the time zone offset (UTC-2)
      combinedDateTime.setHours(combinedDateTime.getHours());
      console.log(combinedDateTime);

      // Format the resulting datetime string
      const formattedDateTime = combinedDateTime.toISOString();
      data.scheduledAt = formattedDateTime;
    }
    localStorage.setItem("dataSendToSms", JSON.stringify(data));
    console.log(data);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/MessageRecord/create?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      setLoading(false);
      if (res.status === 201) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
      }
      // if (res.status === 201) {
      if (res.data.message == "Please verify your phone number via SMS ") {
        //  dispatch(adminVerfiy(data));
        console.log(res);
        if (timeSend === "now") {
          setTokenVerfiy(res.data.token);
          setOpenVerfiy(res.data.campaignId);
        }
      } else {
        setDetails(res.data);
        if (timeSend === "now") {
          // data.scheduledAt = convertedDateTime;
          if (confirm) setConfirm(true);
        } else {
          navigate("/success");
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const sendDataDeraft = async (confirm) => {
    // const countryCode = phoneNumber.substring(1);
    // console.log(numberCount);
    setLoadingConfirm(true);
    let data = {
      inputText,
      senderName,
      message,

      selectedGroupContacts,
      // contactsGroup,
      // contactName: "",
    };
    if (selectGroupId) {
      data.selectedGroups = selectGroupId;
    }
    if (timeSend === "later") {
      data.scheduledAt = `${date}T${time}:00Z`;
    }
    localStorage.setItem("dataSendToSms", JSON.stringify(data));
    console.log(data);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/MessageRecord/create?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      setLoadingConfirm(false);

      if (res.status === 201) {
        if (res.data.message == "Please verify your phone number via SMS ") {
          //  dispatch(adminVerfiy(data));
          console.log(res);
          if (timeSend === "now") {
            setTokenVerfiy(res.data.token);
            setOpenVerfiy(res.data.campaignId);
          }
        } else {
          navigate("/success");
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: res?.data?.message,
            life: 3000,
          });
        }
      }
      // if (res.status === 201) {
      if (res.data.message == "Please verify your phone number via SMS ") {
        //  dispatch(adminVerfiy(data));
      } else {
      }
    } catch (error) {
      setLoadingConfirm(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  //////////
  const handleSubmit = async () => {
    console.log(code);
    console.log(tokenVerfiy);
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenVerfiy}`,
        },
      };
      const res = await axios.post(
        "https://togethersms.com/MessageRecord/confirm-mobile-sendMessage/" +
          code,
        config,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenVerfiy}`,
          },
        }
      );
      console.log(res);
      if (res.status == 201) {
        if (timeSend === "now") {
          setOpenVerfiy(false);
          setDetails(res.data);
          setConfirm(true);
          setLoading(false);
        } else {
          navigate("/success");
        }
      } else {
        console.log(res);
        // setError(true);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
    }
  };

  //////////
  const sendConfirm = async () => {
    // const countryCode = phoneNumber.substring(1);
    // console.log(numberCount);
    setLoadingConfirm(true);
    let data = {
      inputText,
      senderName,
      message,
      selectedGroups: selectGroupId,
      selectedGroupContacts,
      // contactsGroup,
      // contactName: "",
    };
    console.log(data);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/MessageRecord/${details.campaignId}?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      setModalOpened(false);
      setLoadingConfirm(false);
      if (res) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
        setConfirm(false);
        try {
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${token}`,
            },
          };
          const res = await axios.get(
            `https://togethersms.com/user/${user.userData._id}`,

            config
          );
          //   setLoading(false);
          dispatch(loginSuccess(res.data));
          navigate("/success");

          console.log("user", res);
        } catch (error) {
          console.log(error);
        }
        //  dispatch(adminVerfiy(data));
        //  setConfirm(true);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoadingConfirm(false);
      setModalOpened(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(
          `https://togethersms.com/user/${user.data.userData._id}`,

          config
        );
        //   setLoading(false);
        dispatch(loginSuccess(res));
        console.log("user", res);
      } catch (error) {
        console.log(error);
      }
      //  setError(true);
    }
  };

  ///////

  const isEnglish = () => {
    const valid =
      "\n\r\t\v" +
      "abcdefghijklmnopqrstuvwxyz\\ /* +-1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ.?؛×÷‘ٌ[]ـ؟()&%@!#=_^$~',|\":}{<>;";
    let lang = "en";
    const text = document.querySelector("textarea[name=msg]").value;

    for (let i = 0; i < text.length; i++) {
      const temp = "" + text.substring(i, i + 1);
      if (valid.indexOf(temp) === -1) {
        lang = "ar";
        break;
      }
    }

    if (lang === "en") {
      setLanguage("en");
      setMaxDigits(1120);
    } else {
      setLanguage("ar");
      setMaxDigits(490);
    }

    return lang;
  };

  const handleSmsPress = () => {
    const lang = isEnglish();
    let maxDigits = lang === "ar" ? 490 : 1120;
    setTextInput(document.querySelector("textarea[name=msg]").value);
    console.log(document.querySelector("textarea[name=msg]").value);

    const msgLength =
      document.querySelector("textarea[name=msg]").value.length +
      document.querySelector("textarea[name=msg]").value.split("\n").length -
      1;
    const newline = document
      .querySelector("textarea[name=msg]")
      .value.split("\n").length;
    console.log(
      document.querySelector("textarea[name=msg]").value.split("\n").length
    );
    console.log(maxDigits);
    if (msgLength > maxDigits) {
      console.log("good");
      document.querySelector("textarea[name=msg]").value = document
        .querySelector("textarea[name=msg]")
        .value.substr(0, maxDigits - newline);
      console.log(
        document.querySelector("textarea[name=msg]").value.substr(0, maxDigits)
      );
      maxDigits = msgLength;
    }

    let msgsCount = 1;
    const digitsLimit = maxDigits - msgLength;
    setCharCount(digitsLimit);

    if (msgLength > 70 && lang === "ar") {
      msgsCount = Math.ceil(msgLength / 67);
      setMsgsCount(Math.ceil(msgLength / 67));
    } else if (msgLength > 160 && lang === "en") {
      msgsCount = Math.ceil(msgLength / 153);
      setMsgsCount(Math.ceil(msgLength / 153));
    } else {
      if (lang === "ar") {
        msgsCount = Math.ceil(msgLength / 70);
        setMsgsCount(Math.ceil(msgLength / 70));
      } else {
        msgsCount = Math.ceil(msgLength / 160);
        setMsgsCount(Math.ceil(msgLength / 160));
      }

      if (msgLength === 0) {
        // msgLength = "0";
        msgsCount = 1;
      }
    }

    // document.querySelector("#digits").innerHTML = msgLength;
    // document.querySelector("#digits_limit").innerHTML = digitsLimit;
    // document.querySelector("#msg_num").innerHTML = msgsCount;
  };
  const handleTextareaChange = (e) => {
    const content = e.target.value;
    console.log(e.target.value);
    setMaxDigits(content);
    setinputText(e.target.value);
    // Split the input text by commas and count the numbers
    const numbersArray = content.split(",").map((item) => item.trim());
    const count = numbersArray.filter((item) => !isNaN(item)).length;
    setNumberCount(count);
  };
  const [count, setCount] = useState(180);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const resetCode = async (e) => {
    // setLoading(true);
    setCount(180);
    console.log(token);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/MessageRecord/resend-verify-otp/${openVerfiy}`,
        config,
        config
      );
      if (res.status === 201) {
        console.log("res", res);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
        // setLoading(false);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);

      console.log(error);
      setLoading(false);
      //   setError(true);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="content-wrapper">
        {/* <Header /> */}
        <Toast ref={toast} />
        <section className="wrapper bg-soft-primary">
          <div className="container pt-5 text-center">
            <div className="row">
              <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
                <h1 className="display-1 mb-3">{t("Send SMS")} </h1>
                <p className="lead px-xxl-10"></p>
                <nav className="d-inline-block" aria-label="breadcrumb">
                  <ol className="breadcrumb ">
                    <li className="breadcrumb-item">
                      <Link to="/">{t("Home")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/send-message">{t("Send SMS")}</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto order-2">
              <section id="snippet-2" className="wrapper pt-10">
                <div className="card">
                  <div className="card-body py-1">
                    <h2 className=""> {t("Send To")}</h2>
                    <div className="d-flex align-items-center flex-wrap ">
                      <div className="form-select-wrapper  col-md-2 col-12 d-md-block">
                        <label className="text-orange ">{t("Send to")}</label>
                      </div>
                      <div className="form-floating mb-4 col-md-8  col-12">
                        <div className="form-floating ">
                          <textarea
                            id="textareaExample"
                            className="form-control"
                            placeholder="Textarea"
                            style={{ height: "150px" }}
                            required
                            defaultValue={inputText}
                            onChange={handleTextareaChange}
                          />
                          <label htmlFor="textareaExample">
                            {t("Send to")}
                          </label>
                        </div>{" "}
                        <div className="mt-2 d-flex gap-2 align-items-center">
                          <div
                            className=" bg-red d-flex align-items-center justify-content-center "
                            style={{
                              width: "25px",
                              height: "25px",
                              borderRadius: "50%",
                            }}
                          >
                            <i className="uil-info text-white fs-25"></i>
                          </div>
                          <span>
                            ex : jhasih sahdiuhas asiduhui ishad 0101909116
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="snippet-2" className="wrapper pt-4">
                <div className="card">
                  <div className="card-body py-2">
                    <div className="row ">
                      <SelectGroup
                        setSelectGroupId={setSelectGroupId}
                        setGroupSelected={setGroupSelected}
                        groupSelected={groupSelected}
                        selectGroupId={selectGroupId}
                      />
                    </div>
                  </div>
                </div>
              </section>
              <section id="snippet-2" className="wrapper pt-4">
                <div className="card">
                  <div className="card-body py-2">
                    <div className="row ">
                      <div className="form-select-wrapper  col-12 mb-4 ">
                        <div
                          className="btn btn-yellow rounded-pill"
                          onClick={() => setModalOpened(true)}
                        >
                          {t("Select Contacts")}
                        </div>
                      </div>
                      {selectedRows?.map((e, i) => (
                        <li
                          key={i}
                          className="d-flex col-md-6  gap-5 align-items-center"
                        >
                          {e.phoneNumber} ({e.group[0]?.name}){" "}
                          <GiCancel
                            onClick={() => deleteGroup(e._id)}
                            className="text-primary cursor-pointer"
                          />
                        </li>
                      ))}
                      {selectedRows.length > 0 && (
                        <div className="form-select-wrapper  col-12 mb-4 mt-4">
                          <div
                            className="btn btn-yellow rounded-pill"
                            onClick={() => setModalOpened(true)}
                          >
                            {t("Edit")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </section>

              {/* Send message */}
              <section id="snippet-2" className="wrapper pt-4">
                <div className="card">
                  <div className="card-body py-2">
                    <h2 className="mb-5"> {t("Write Your SMS")}</h2>
                    <div className="d-flex flex-wrap align-items-center mb-4 gap-3">
                      <div className="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">
                          {t("Sender Name")}
                        </label>
                      </div>
                      <div className="form-floating  col-md-4  col-12">
                        <div className="form-select-wrapper ">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setsenderName(e.target.value)}
                            defaultValue={"1"}
                          >
                            <option value={"1"}>{t("Sender Name")}</option>
                            {allSenderName?.map((e, i) => (
                              <option key={i} value={e.senderName}>
                                {e.senderName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-select-wrapper mx-md-7 col-4 ">
                        <Link
                          to={"/add-sender-name"}
                          className="btn btn-yellow rounded-pill"
                        >
                          {t("Request New Sender Name")}
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex align-items-center mb-4 ">
                      <div className="form-select-wrapper  col-2 d-md-block d-none">
                        <label className="text-orange ">{t("Message")}</label>
                      </div>
                      <div className="form-floating col-md-8  col-12">
                        <div className="form-floating ">
                          <textarea
                            dir={language === "ar" ? "rtl" : "ltr"}
                            id="textareaExample"
                            className="form-control "
                            name="msg"
                            placeholder="Textarea"
                            style={{ height: "150px" }}
                            required
                            onChange={handleSmsPress}
                            maxLength={maxDigits}
                            // maxLength={
                            //   (language === "ar" ? 490 : 1120) -
                            //   msgsCountDefault
                            // }
                          />
                          <label htmlFor="textareaExample">
                            {t("Message")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className=" fs-15 mb-4" role="alert">
                      {t("Number of characters")}
                      <span className="text-primary fs-25">
                        {" "}
                        {language === "ar"
                          ? 490 - charCount
                          : 1120 - charCount}{" "}
                      </span>{" "}
                      {/* {t("character")} - {t("rest of") + " "} */}
                      {/* <span className="text-primary">
                        {language === "ar" ? 490 - charCount : 1120 - charCount}{" "}
                      </span> */}
                      {t("character")} - {t("SMS cost")}{" "}
                      <span className="text-primary fs-25">{msgsCount} </span>
                      {t("message")}
                    </div>
                    {/* <div
                      className="alert alert-warning alert-icon fs-13"
                      role="alert"
                      style={
                        language === "en"
                          ? { border: "2px solid #fab758" }
                          : { border: "none" }
                      }
                    >
                      <i className="uil uil-exclamation-triangle"></i>
                      {t(
                        "English : You can send until 1120 character ,will be calculated every 160 character message, and will be received in a single message ."
                      )}
                    </div>
                    <div
                      className="alert alert-warning alert-icon fs-13"
                      role="alert"
                      style={
                        language === "ar"
                          ? { border: "2px solid #fab758" }
                          : { border: "none" }
                      }
                    >
                      <i className="uil uil-exclamation-triangle"></i>
                      {t(
                        "Arabic : You can send until 490 character ,will be calculated every 70 character message, and will be received in a single message."
                      )}
                    </div> */}
                  </div>
                </div>
              </section>
              <section id="snippet-2" className="wrapper pt-4 mb-10">
                <div className="card">
                  <div className="card-body py-2">
                    <h2 className="mb-5"> {t("message cost")}</h2>
                    <div className="d-flex gap-3 mb-3 ">
                      <div className="form-check">
                        <input
                          className="form-check-input col-5"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          defaultChecked
                          onClick={() => setTimeSend("now")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          {" "}
                          {t("Now")}
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                          onClick={() => setTimeSend("later")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          {" "}
                          {t("Scheduling")}
                        </label>
                      </div>
                    </div>
                    {timeSend == "later" && (
                      <div className="row ">
                        <div className="col-md-2 col-2 mb-3">
                          <input
                            id="textInputExample"
                            type="date"
                            className="form-control"
                            // placeholder="Text Input"
                            disabled={timeSend === "now"}
                            onChange={(e) => setDate(e.target.value)}
                            placeholder={t("Date")}
                            // value={t("Date")}
                          />
                        </div>
                        <div className="col-md-2 col-2 mb-3">
                          <input
                            id="textInputExample"
                            type="time"
                            className="form-control"
                            onChange={(e) => settime(e.target.value)}
                            // placeholder="Text Input"
                            disabled={timeSend === "now"}
                            placeholder={t("Date")}
                            // value={t("Date")}
                          />
                        </div>
                        {/* <div className="form-select-wrapper col-md-2 col-12 mb-3">
                        <select
                          className="form-select "
                          aria-label="Default select example"
                          disabled={timeSend === "now"}
                        >
                          <option selected>{t("Hour")}</option>
                          <option value={1}>00</option>
                          <option value={2}>01</option>
                          <option value={3}>03</option>
                          <option value={3}>04</option>
                          <option value={3}>05</option>
                          <option value={3}>06</option>
                          <option value={3}>07</option>
                          <option value={3}>08</option>
                          <option value={3}>09</option>
                          <option value={3}>10</option>
                          <option value={3}>11</option>
                          <option value={3}>12</option>
                          <option value={3}>13</option>
                          <option value={3}>14</option>
                          <option value={3}>15</option>
                          <option value={3}>16</option>
                          <option value={3}>17</option>
                          <option value={3}>18</option>
                          <option value={3}>19</option>
                          <option value={3}>20</option>
                          <option value={3}>21</option>
                          <option value={3}>22</option>
                          <option value={3}>23</option>
                        </select>
                      </div>
                      <div className="form-select-wrapper col-md-3 col-12 mb-3">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          disabled={timeSend === "now"}
                        >
                          <option selected> {t("minute")}</option>
                          {options.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div> */}
                      </div>
                    )}
                    {timeSend == "later" && (
                      <p className="mt-3">{currentDate.toLocaleString()}</p>
                    )}
                    <div className="d-flex gap-3">
                      <button
                        // onClick={() => setConfirm(true)}
                        onClick={() => sendData(true)}
                        //  href="#"
                        disabled={loading ? true : false}
                        className="btn btn-outline-primary rounded-pill"
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) : null}
                        {t("Send")}
                      </button>

                      <button
                        // href="#"
                        onClick={() => sendDataDeraft(true)}
                        disabled={loadingConfirm ? true : false}
                        className="btn btn-outline-gradient gradient-1 rounded-pill me-1 mb-2 mb-md-0"
                      >
                        {loadingConfirm ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) : null}
                        <span>{t("Save as Draft")}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* <Aside /> */}
          </div>
        </div>
      </div>
      <Modal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        setSelectedRows={setSelectedRows}
        setSelectGroupContacts={setSelectGroupContacts}
        selectedRows={selectedRows}
        deleteGroup={deleteGroup}
        selectedGroupContacts={selectedGroupContacts}
      />
      <Dialog
        visible={confirm}
        style={{ width: "42rem", height: "100%" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={
          <div className="form-select-wrapper  col-12  ">
            <div className=" rounded-pill">{t("Confirm Send")}</div>
          </div>
        }
        modal
        className="p-fluid"
        // footer={productDialogFooter}
        onHide={() => setConfirm(false)}
      >
        <div className="col-xl-12 m-auto order-2">
          <section id="snippet-7" className="wrapper py-10">
            <div className="card">
              <div className="card-body py-2">
                <div className="card shadow-lg card-border-top border-primary">
                  <div className="card-body py-2">
                    <div className="text-center ">
                      <label className="text-orange ">{t("Send to :")}</label>
                      <span className="mx-1">
                        {details?.totalValidPhoneNumbers} Individual
                      </span>
                    </div>
                    <div className="text-center ">
                      <label className="text-orange ">
                        {t("Message Cost :")}
                      </label>
                      <span className="mx-1">{details?.totalPoints}</span>
                    </div>
                    <div className="text-center mb-3">
                      At a rate of messages
                      <label className="text-orange mx-1">
                        {" "}
                        {details?.messageCount}{" "}
                      </label>
                      {/* <span>122</span> */}
                      per phone number
                    </div>
                    <div className="text-center mb-3">
                      <label className="text-orange ">
                        {t("Message will be sent after confirm")}
                      </label>
                    </div>
                    <div className="text-center">
                      <button
                        // onClick={() => setConfirm(true)}
                        onClick={() => sendConfirm()}
                        //  href="#"
                        disabled={loadingConfirm ? true : false}
                        className="btn btn-outline-primary rounded-pill"
                      >
                        {loadingConfirm ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) : null}
                        <span>{t("Confirm")}</span>
                      </button>
                      {/* <button
                        onClick={() => sendConfirm()}
                        className="btn btn-expand btn-primary rounded-pill my-4"
                      >
                        <i className="uil uil-arrow-right"></i>
                        <span>{t("Confirm")}</span>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Dialog>

      <Dialog
        visible={openVerfiy}
        style={{ width: "42rem", height: "100%" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={
          <div className="form-select-wrapper  col-12  ">
            <div className=" rounded-pill">{t("Verify your campaigns")}</div>
          </div>
        }
        modal
        className="p-fluid"
        // footer={productDialogFooter}
        onHide={() => setOpenVerfiy(false)}
      >
        <section className="wrapper bg-light">
          <div className="container ">
            <div className="row">
              <div className="  mx-auto ">
                <div className="card">
                  <div className="card-body p-11 text-center">
                    <h2 className="mb-3 fs-20 text-start">
                      {t(
                        "Please enter the confirmation code to send this campaign. "
                      )}
                    </h2>

                    <p className="lead mb-6 text-start">
                      {/* {email} */}
                      {/* {t("Fill your email and password to sign in.")} */}
                    </p>
                    <form
                      className="text-start mb-3"
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="form-floating mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          id="loginEmail"
                          onChange={(e) => setCode(e.target.value)}
                        />
                        <label htmlFor="loginEmail">{t("Code")}</label>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2">
                            {" "}
                            {/* Loading...{" "} */}
                          </Spinner>
                        ) : null}
                        {t("Confirmation")}
                      </button>
                    </form>
                    {count > 0 && (
                      <p className="mt-2 fs-15 text-center">
                        If you didn't receive your confirmation code, you can
                        request another after{" "}
                        <span className="text-primary">{count}</span>
                         (Send again)
                      </p>
                    )}
                    {count === 0 && (
                      <div className="mt-2 text-center">
                        <p className="mb-0">
                          Didn't receive a code ?{" "}
                          <div
                            onClick={() => resetCode()}
                            // to="/auth-pass-reset-basic"
                            className="fw-semibold text-primary text-decoration-underline cursor-pointer"
                          >
                            {t("Resend")}
                          </div>{" "}
                        </p>
                      </div>
                    )}
                    {/* {count === 0 && (
                      <div className="mt-2 text-center">
                        <p className="mb-0">
                          Didn't receive a code ?{" "}
                          <div
                            onClick={() => resetCode()}
                            // to="/auth-pass-reset-basic"
                            className="fw-semibold text-primary text-decoration-underline cursor-pointer"
                          >
                            {t("Resend")}
                          </div>{" "}
                        </p>
                      </div>
                    )} */}
                    {/* /form */}

                    {/*/.social */}
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.card */}
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </section>
      </Dialog>
    </>
  );
}
