import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { IoMdClose } from "react-icons/io";
import { useFormik } from "formik";
import * as Yup from "yup";
import ModelSenderRecharge from "./ModelSenderRecharge";

function AddSenderName() {
  const { token } = useSelector((state) => state.auth);
  const [t, i18n] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dataContent = JSON.parse(
    sessionStorage.getItem("contentAddSenderName")
  );
  const [loading, setLoading] = useState(false);
  const [countryName, setCountryName] = useState(false);
  const [nameCountry, setNameCountry] = useState("");
  const [senderName, setSenderName] = useState(dataContent?.data?.senderName);
  const [activity, setActivity] = useState(dataContent?.data?.activity);
  const [facebookLink, setFacebookLink] = useState(
    dataContent?.data?.facebookLink
  );
  const [websiteLink, setWebsiteLink] = useState(
    dataContent?.data?.websiteLink
  );
  const [commercialRegister, setcommercialRegister] = useState("");
  const [taxId, settaxId] = useState("");
  const [delegation, setdelegation] = useState("");
  const navigate = useNavigate("");
  const [typePayment, setTpePayment] = useState("");
  const [check, setCheck] = useState(true);
  const [contentSenderName, setcontentSenderName] = useState(false);
  const [typePaymentOffline, setTpePaymentOffline] = useState("vodafoneCash");
  const toast = useRef();
  const { user } = useSelector((state) => state.auth);
  const URL = process.env.URL_SERVER;

  const handlePhotoUpload = (e) => {
    const newPhotos = [...commercialRegister, ...e.target.files];
    setcommercialRegister(newPhotos);
  };
  const handlecommercialRegisterDelete = (index) => {
    const newPhotos = [...commercialRegister];
    newPhotos.splice(index, 1);
    setcommercialRegister(newPhotos);
  };
  const handlePhotoUpload2 = (e) => {
    const newPhotos = [...taxId, ...e.target.files];
    settaxId(newPhotos);
  };
  const handleTaId = (index) => {
    const newPhotos = [...taxId];
    newPhotos.splice(index, 1);
    settaxId(newPhotos);
  };
  const handlePhotoUpload3 = (e) => {
    const newPhotos = [...delegation, ...e.target.files];
    setdelegation(newPhotos);
  };
  const handledelegation = (index) => {
    const newPhotos = [...delegation];
    newPhotos.splice(index, 1);
    setdelegation(newPhotos);
  };
  const [countries, setcountries] = useState();

  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/SenderNameConfig/get-user-senderName`,

        config,
        config
      );
      console.log(res, "get-user-senderName");
      if (res) {
        setcountries(res.data.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });

      //  setError(true);
    }
  };
  useEffect(() => {
    getDataGetway();
  }, []);
  const sendData = async () => {
    // const countryCode = phoneNumber.substring(1);
    setLoading(true);
    // console.log(typePayment);
    // console.log(typePaymentOffline);

    const formData = new FormData();
    if (typePayment == "online") {
      formData.append("paymentMethod", "online");
    } else {
      formData.append("paymentMethod", typePaymentOffline);
    }

    formData.append("websiteLink", websiteLink);
    formData.append("facebookLink", facebookLink);
    formData.append("activity", activity);
    formData.append("senderName", senderName);
    formData.append("country", countryName);
    // formData.append("commercialRegister", commercialRegister);
    // formData.append("taxId", taxId);
    // formData.append("delegation", delegation);
    formData.append("paidAmountCents", +priceSenderName);
    if (commercialRegister) {
      commercialRegister?.forEach((file, index) => {
        formData.append("commercialRegister", file);
      });
    }
    if (taxId) {
      taxId?.forEach((file, index) => {
        formData.append("taxId", file);
      });
    }
    if (delegation) {
      delegation?.forEach((file, index) => {
        formData.append("delegation", file);
      });
    }
    let data = {
      websiteLink,
      facebookLink,
      activity,
      senderName,
      countryName,

      taxId,
      delegation,
      nameCountry,
      paidAmountCents: +priceSenderName,
    };

    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/CompanyActivation/uploadFields?lang=${i18n.language}`,
        formData,
        config
      );
      console.log(res);
      if (res) {
        //  dispatch(adminVerfiy(data));

        console.log("res", res);
        // navigate("/add-sender-name-files");

        // setLoading(false);
        setLoading(false);
        console.log(typePayment);
        if (typePayment == "online") {
          sessionStorage.setItem(
            "contentAddSenderName",
            JSON.stringify({
              data,
            })
          );
          setcontentSenderName(true);
          // navigate("/content-add-sender-name");
        } else {
          navigate("/sender-names-list");
        }
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const handleSend = async (e) => {
    setLoading(true);

    const formData = new FormData();
    if (typePayment == "online") {
      formData.append("paymentMethod", "online");
    } else {
      formData.append("paymentMethod", typePayment);
    }

    formData.append("websiteLink", websiteLink);
    formData.append("facebookLink", facebookLink);
    formData.append("activity", activity);
    formData.append("senderName", senderName);
    formData.append("country", countryName);
    formData.append("commercialRegister", commercialRegister);
    if (commercialRegister) {
      for (let i = 0; i <= commercialRegister.length; i++) {
        formData.append("commercialRegister", commercialRegister[i]);
      }
    }
    if (taxId) {
      for (let i = 0; i <= taxId.length; i++) {
        formData.append("taxId", taxId[i]);
      }
    }
    if (delegation) {
      for (let i = 0; i <= delegation.length; i++) {
        formData.append("delegation", delegation[i]);
      }
    }
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/CompanyActivation/uploadFields`,
        formData,
        config
      );
      console.log(res);
      if (res) {
        //  dispatch(adminVerfiy(data));

        console.log("res", res);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });

        let data = {
          paymentMethod:
            typePayment == "ofilne" ? typePaymentOffline : typePayment,
          paidAmount: +priceSenderName,
          senderName: senderName,
          country: countryName,
        };
        console.log(data);
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${token}`,
            },
          };
          const res = await axios.post(
            `https://togethersms.com/payment/create`,
            data,
            config
          );
          console.log(res, "1");
          setLoading(false);
          if (res.status === 201) {
            setTimeout(() => {
              if (typePayment == "online") {
                let data = {
                  paymentMethod: typePayment,
                  priceSenderName: priceSenderName,
                  senderName: senderName,
                  activity: activity,
                  countryName: nameCountry,
                  countryId: countryName,
                };
                sessionStorage.setItem(
                  "contentAddSenderName",
                  JSON.stringify({
                    data,
                  })
                );
                setcontentSenderName(true);
                // navigate("/content-add-sender-name");
              } else {
                navigate("/sender-names-list");
              }
            }, 1000);
          } else {
            console.log(res);
            setLoading(false);
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: res.data.message,
              life: 3000,
            });
          }
        } catch (error) {
          // setLoading(false);
          console.log(error);
          setLoading(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            // detail: error.response.data.message,
            life: 3000,
          });
          //  setError(true);
        }

        // navigate("/add-sender-name-files");

        // setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      // setLoading(false);
      console.log(error);
      setLoading(false);
      //  setError(true);
    }
  };

  const [priceSenderName, setPriceSenderName] = useState(0);
  const formik = useFormik({
    initialValues: {
      senderName: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      // Handle form submission here
      console.log("Form submitted with values:", values);
      sendData(values);
    },
  });
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // يتم التحقق من عدم وجود حروف عربية والرموز المحظورة (باستثناء المسافات)
    if (
      !/[^\u0000-\u007F]/.test(inputValue) &&
      !/[@.#\\\/$%^&*()_+|~=`{}\[\]:";'<>?,!]/.test(inputValue) &&
      inputValue.length <= 11
    ) {
      console.log(inputValue);
      setSenderName(inputValue);
    }
  };

  return (
    <div className="content-wrapper">
      <Toast ref={toast} />

      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-8 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display31 mb-3">{t("Add New Sender Name")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/sender-names-list">
                      {t("Sender Name Activation")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/add-sender-name"}>
                      {" "}
                      {t("Add New Sender Name")}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-7" className="wrapper py-10">
              <div className="card">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (typePayment == "offline") {
                      handleSend();
                    } else {
                      handleSend();
                    }
                  }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-4 row">
                      <div className="form-select-wrapper  col-2 d-md-block ">
                        <label className="text-orange ">
                          {t("Select Country")}
                        </label>
                      </div>
                      <div className="form-floating  col-md-6  col-12">
                        <div className="form-floating ">
                          <div className="form-select-wrapper">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) => {
                                const [alphanumericCode, numericValue, name] =
                                  e.target.value.split(",");

                                console.log(alphanumericCode);
                                setPriceSenderName(numericValue);
                                setCountryName(alphanumericCode);
                                setNameCountry(name);
                              }}
                              defaultValue={"1"}
                            >
                              <option key={"1"}> {t("Select Country")}</option>
                              {countries?.map((e, i) => (
                                <option
                                  key={i}
                                  onClick={() => {
                                    console.log(e.countryPrice);
                                    setPriceSenderName(e.countryPrice);
                                  }}
                                  value={[
                                    e?.country?._id,
                                    e.countryPrice,
                                    e?.country?.countryName,
                                  ]}
                                >
                                  {e.country.countryName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 row">
                      <div className="form-select-wrapper  col-2 d-md-block ">
                        <label className="text-orange ">{t("Price")}</label>
                      </div>
                      <div className="form-floating  col-md-6  col-12 fs-25 fw-bolder text-green">
                        {+priceSenderName?.toLocaleString("en")} EGP
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 row">
                      <div className="form-select-wrapper  col-2 d-md-block ">
                        <label className="text-orange ">
                          {t("Sender Name")}
                        </label>
                      </div>
                      <div className="form-floating  col-md-6  col-12">
                        <div className="form-floating ">
                          <input
                            id="textInputExample"
                            type="text"
                            className="form-control"
                            placeholder="Text Input"
                            value={senderName}
                            onChange={(e) => handleInputChange(e)}
                            required
                          />
                          <label htmlFor="textInputExample">
                            {t("Sender Name")}
                          </label>
                        </div>
                      </div>
                      {senderName?.length || 0} / 11
                    </div>
                    <div className="d-flex align-items-center mb-4 row">
                      <div className="form-select-wrapper  col-2 d-md-block ">
                        <label className="text-orange "> {t("About")}</label>
                      </div>
                      <div className="form-floating  col-md-6  col-12">
                        <div className="form-floating ">
                          <textarea
                            id="textareaExample"
                            className="form-control"
                            placeholder="Textarea"
                            style={{ height: "150px" }}
                            required
                            value={activity}
                            onChange={(e) => setActivity(e.target.value)}
                          />
                          <label htmlFor="textareaExample">
                            {t("Type Of Activity")}
                          </label>
                          {/* <input
                            value={activity}
                            onChange={(e) => setActivity(e.target.value)}
                            required
                            id="textInputExample"
                            type="text"
                            className="form-control"
                            placeholder="Text Input"
                          />
                          <label htmlFor="textInputExample">
                            {t("Type Of Activity")}
                          </label> */}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 row">
                      <div className="form-select-wrapper  col-2 d-md-block ">
                        <label className="text-orange ">
                          {t("Facebook Link")}
                        </label>
                      </div>
                      <div className="form-floating  col-md-6  col-12">
                        <div className="form-floating ">
                          <input
                            value={facebookLink}
                            onChange={(e) => setFacebookLink(e.target.value)}
                            id="textInputExample"
                            type="url"
                            className="form-control"
                            placeholder="Text Input"
                          />
                          <label htmlFor="textInputExample">
                            {t("Facebook Link")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center mb-4 row">
                      <div className="form-select-wrapper  col-2 d-md-block ">
                        <label className="text-orange ">
                          {" "}
                          {t("Website Link")}
                        </label>
                      </div>
                      <div className="form-floating  col-md-6  col-12">
                        <div className="form-floating ">
                          <input
                            value={websiteLink}
                            onChange={(e) => setWebsiteLink(e.target.value)}
                            id="textInputExample"
                            type="url"
                            className="form-control"
                            placeholder="Text Input"
                          />
                          <label htmlFor="textInputExample">
                            {t("Website Link")}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="d-flex align-items-center mb-4 row">
                        <div className="form-select-wrapper  col-4 d-md-block ">
                          <label className="text-orange ">
                            {t("Commercial register")}
                          </label>
                        </div>
                        <div className=" col-md-4  col-12 d-flex  justify-content-end">
                          <a
                            label="Upload Excel"
                            icon="pi pi-upload"
                            className="btn btn-primary rounded-xl  fs-14"
                            onClick={() =>
                              document.getElementById("register").click()
                            }
                          >
                            {t("Upload")}
                            <i className="uil uil-export  mx-2"></i>
                          </a>
                        </div>
                        <input
                          onChange={handlePhotoUpload}
                          id="register"
                          type="file"
                          accept=".pdf ,.png,.jpg, .xls"
                          style={{ display: "none" }}
                          multiple
                          //   onChange={handleUploadExcel}
                        />
                      </div>
                      {commercialRegister.length > 0 &&
                        commercialRegister.map((e, index) => (
                          <div
                            className="d-flex gap-2 align-items-center justify-content-between col-md-6 col-12 mb-2"
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div className="">{e.name}</div>
                            <IoMdClose
                              className="cursor-pointer"
                              style={{
                                background: "#eee",
                                borderRadius: "50%",
                                width: "30px",
                                minWidth: "30px",
                                height: "30px",
                                padding: "3px",
                                color: "red",
                              }}
                              onClick={() =>
                                handlecommercialRegisterDelete(index)
                              }
                            />
                          </div>
                        ))}
                      <div className="d-flex align-items-center mb-4 row">
                        <div className="form-select-wrapper mb-4 col-4  d-md-block ">
                          <label className="text-orange ">{t("Tax id")}</label>
                        </div>
                        <div className="mb-4 col-md-4  col-12 d-flex  justify-content-end">
                          <a
                            label="Upload Excel"
                            icon="pi pi-upload"
                            className="btn btn-primary rounded-xl  fs-14"
                            onClick={() =>
                              document.getElementById("tax").click()
                            }
                          >
                            {t("Upload")}
                            <i className="uil uil-export  mx-2"></i>
                          </a>
                        </div>
                        <input
                          onChange={handlePhotoUpload2}
                          id="tax"
                          type="file"
                          accept=".pdf ,.png,.jpg, .xls"
                          multiple
                          style={{ display: "none" }}
                          //   onChange={handleUploadExcel}
                        />
                      </div>
                      {taxId.length > 0 &&
                        taxId.map((e, index) => (
                          <div
                            className="d-flex gap-2 align-items-center justify-content-between col-md-6 col-12 mb-2"
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div className="">{e.name}</div>
                            <IoMdClose
                              className="cursor-pointer"
                              style={{
                                background: "#eee",
                                borderRadius: "50%",
                                width: "30px",
                                minWidth: "30px",
                                height: "30px",
                                padding: "3px",
                                color: "red",
                              }}
                              onClick={() => handleTaId(index)}
                            />
                          </div>
                        ))}
                      <div className="d-flex align-items-center mb-4 row mb-4">
                        <div className="form-select-wrapper mb-4 col-4 d-md-block ">
                          <label className="text-orange ">
                            {t("Delegation (Download Sample File)")}
                          </label>
                        </div>
                        <div className="mb-4 col-md-4  col-12 d-flex  justify-content-end">
                          <a
                            icon="pi pi-upload"
                            className="btn btn-primary rounded-xl  fs-14"
                            onClick={() =>
                              document.getElementById("Delegation").click()
                            }
                          >
                            {t("Upload")}
                            <i className="uil uil-export  mx-2"></i>
                          </a>
                        </div>
                        <input
                          onChange={handlePhotoUpload3}
                          id="Delegation"
                          multiple
                          type="file"
                          accept=".pdf ,.png,.jpg, .xls"
                          style={{ display: "none" }}
                        />
                      </div>
                      {delegation.length > 0 &&
                        delegation.map((e, index) => (
                          <div
                            className="d-flex gap-2 align-items-center justify-content-between col-md-6 col-12 mb-2"
                            style={{
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                            }}
                          >
                            <div className="">{e.name}</div>
                            <IoMdClose
                              className="cursor-pointer"
                              style={{
                                background: "#eee",
                                borderRadius: "50%",
                                width: "30px",
                                minWidth: "30px",
                                height: "30px",
                                padding: "3px",
                                color: "red",
                              }}
                              onClick={() => handledelegation(index)}
                            />
                          </div>
                        ))}
                      <ul
                        className="icon-list bullet-bg bullet-soft-primary mb-4 p-3"
                        style={{ border: "1px solid #eee" }}
                      >
                        <h4 className="text-primary">
                          {t("Sender Name Request policy")}
                        </h4>
                        <li>
                          <i className="uil uil-check"></i>
                          {t(
                            "Sender name activation should be approved for business or corporates only not to personal."
                          )}
                        </li>
                        <li>
                          <i className="uil uil-check"></i>
                          {t(
                            "The sender should be written in the Commercial Register or Tax ID "
                          )}
                        </li>
                        <li>
                          <i className="uil uil-check"></i>
                          {t(
                            "The sender should use in commercial or business use not to Contempt of religion or against Egyptian policy."
                          )}
                        </li>
                      </ul>

                      <div className="form-floating d-flex flex-wrap gap-4 mb-4">
                        <h4 className="text-primary">
                          {t("Payment method")} :
                        </h4>
                        <div
                          className="form-check"
                          onClick={() => setTpePayment("online")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            defaultChecked={typePayment === "online"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            {" "}
                            {t("Online Payment")}
                          </label>
                        </div>
                        <div
                          className="form-check mb-4"
                          onClick={() => setTpePayment("offline")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked={typePayment === "offline"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            {" "}
                            {t("Offline Payment")}
                          </label>
                        </div>
                      </div>
                      {typePayment === "offline" && (
                        <div className="">
                          <div
                            className="form-check mb-2"
                            onClick={() => setTpePaymentOffline("vodafoneCash")}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typePyment"
                              id="vodafoneCash"
                              defaultChecked={
                                typePaymentOffline === "vodafoneCash"
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Vodafone"
                            >
                              {" "}
                              {t("Vodafone Cash: 01066661015")}
                            </label>
                          </div>
                          <div
                            className="form-check  mb-2"
                            onClick={() => setTpePaymentOffline("bank")}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typePyment"
                              id="CIB"
                              defaultChecked={typePaymentOffline === "bank"}
                            />
                            <label className="form-check-label" htmlFor="CIB">
                              {" "}
                              {t(
                                "CIB Bank:  100034877275  - Account Name Together - Abbas El akkad Branch."
                              )}
                            </label>
                          </div>{" "}
                          <div className="col-12 h-100"></div>
                          <div
                            className="form-check mb-4"
                            onClick={() => setTpePaymentOffline("office")}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="typePyment"
                              id="Office"
                              defaultChecked={typePaymentOffline === "office"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="Office"
                            >
                              {" "}
                              {t(
                                "Request a collection from your Office :" + " "
                              )}
                              <span className="text-primary">
                                {t("50 EGP will add to the invoice.")}
                              </span>
                            </label>
                          </div>
                          {typePayment === "offline" && (
                            <button
                              type="submit"
                              className="btn btn-primary rounded-pill btn-login  mb-2"
                              disabled={loading ? true : false}
                            >
                              {loading ? (
                                <Spinner size="sm" className="me-2">
                                  {" "}
                                  {/* Loading...{" "} */}
                                </Spinner>
                              ) : null}
                              {t("Send")}{" "}
                            </button>
                          )}
                        </div>
                      )}
                      {typePayment === "online" && (
                        <>
                          {/* <iframe
                            className="w-100 "
                            style={{ height: "100vh " }}
                            src={iframe}
                          ></iframe> */}
                          <button
                            type="submit"
                            className="btn btn-primary rounded-pill btn-login  mb-2"
                            disabled={loading ? true : false}
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                {/* Loading...{" "} */}
                              </Spinner>
                            ) : null}
                            {t("Send")}{" "}
                          </button>
                        </>
                      )}
                      {/* <button
                        type="submit"
                        className="btn btn-primary rounded-pill btn-login  mb-2"
                        disabled={loading ? true : false}
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2">
                          </Spinner>
                        ) : null}
                        {t("Pay")}
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
          {/* <Aside/> */}
        </div>
      </div>
      <ModelSenderRecharge
        contentSenderName={contentSenderName}
        setcontentSenderName={setcontentSenderName}
      />
    </div>
  );
}

export default AddSenderName;
