import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Toast } from "primereact/toast";

function EnterEmail() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const toast = useRef();

  const [count, setCount] = useState(10);
  const token = localStorage.getItem("tokenSms");

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const handleSubmit = async () => {
    console.log(token);

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `https://togethersms.com/user/forget-password/` + email,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        //  dispatch(adminVerfiy(data));
        localStorage.setItem("tokenSms", res.data.token);
        //   dispatch(verifyEmail());
        navigate("/send-code-rest-pass");
        setLoading(false);
      } else {
        console.log(res);
        // setError(true);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error)
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "This email or phone number is not registered in our database.",
        life: 3000,
      });
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div className="content-wrapper">
      {/* <Header /> */} <Toast ref={toast} />
      <section
        className="wrapper   bg-gradient-primary"
        // style={{ backgroundImage: img }}
      >
        <div className="container   text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Enter Email")} </h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Enter Email")}
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container ">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto ">
              <div className="card">
                <div className="card-body p-11 text-center">
                  <h2 className="mb-3 text-start">{t("Enter Email")} </h2>
                  <p className="lead mb-6 text-start">
                    {/* {t("Fill your email and password to sign in.")} */}
                  </p>
                  <form
                    className="text-start mb-3"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    <div className="form-floating mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        id="loginEmail"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="loginEmail">{t("Enter Email")}</label>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {" "}
                          {/* Loading...{" "} */}
                        </Spinner>
                      ) : null}
                      {t("Send")}
                    </button>
                  </form>

                  {/* /form */}

                  {/*/.social */}
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </div>
  );
}

export default EnterEmail;
