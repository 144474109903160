import axios from "axios";
import { t } from "i18next";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

function AddContact({
  productDialog,
  setProductDialog,
  setName,
  setphoneNumber,
  phoneNumber,
  name,
  //   setLoading,
  toast,
  setchange,
  change,
  id,
}) {
  const hideDialog = () => {
    // setSubmitted(false);
    setProductDialog(false);
  };
  const [t, i18n] = useTranslation();
  const { user, token } = useSelector((state) => state.auth);
  const parms = useParams().id;
  const sendData = async () => {
    console.log(productDialog);
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    // setLoading(true);
    let data = {
      name,
      phoneNumber,
      group: id || productDialog._id,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/contact/create-contact?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        // getDataSearch(currentPage, globalFilter);
        // setLoading(false);
        setchange(!change);
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: t(res.data.message),
          life: 3000,
        });
        // setLoading(false);
      } else {
        console.log(res);
        // setLoading(false);
      }
    } catch (error) {
      //   setLoading(false);
      console.log(error);
      //   setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };
  return (
    <Dialog
      visible={productDialog}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={t("Add")}
      modal
      className="p-fluid"
      footer={
        <React.Fragment>
          <Button
            label="Cancels"
            icon="pi pi-times"
            outlined
            onClick={hideDialog}
          />
          <Button label="Save" icon="pi pi-check" onClick={() => sendData()} />
        </React.Fragment>
      }
      onHide={hideDialog}
    >
      <div className="field">
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Name")}
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              class="form-control"
              placeholder="Text Input"
              onChange={(e) => setName(e.target.value)}
              required
              autoFocus
            />

            <label for="textInputExample">{t("Name")} </label>
          </div>
        </div>
      </div>
      <div className="field">
        <label htmlFor="description" className="font-bold">
          {t("Phone")}
        </label>
        <div class="form-floating mb-4">
          <input
            id="textInputExample"
            type="text"
            class="form-control"
            placeholder="Text Input"
            onChange={(e) => setphoneNumber(e.target.value)}
            required
            //   autoFocus
            autoFocus
          />

          <label for="textInputExample">{t("Phone")}</label>
        </div>
      </div>
    </Dialog>
  );
}

export default AddContact;
