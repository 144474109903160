import React from "react";
import { useTranslation } from "react-i18next";
import img from "../assets/illustrations/i2.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
function HomeInformation() {
  const [t] = useTranslation();
  const { user } = useSelector((state) => state.auth);
  return (
    <section className="wrapper bg-gradient-primary">
      <div className="container pt-10 pt-md-14 pb-8 text-center">
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
          <div className="col-lg-7">
            <figure>
              <img className="w-auto" src={img} alt="" />
            </figure>
          </div>
          <div className="col-md-10 offset-md-1 offset-lg-0 col-lg-5 text-center text-lg-start">
            <h1 className="display-3 mb-5 mx-md-n5 mx-lg-0 fs-23 text-primary">
              {t(
                "Drive Sales with our High-Performance Marketing Bulk SMS System."
              )}
            </h1>
            <p className="lead fs-16 mb-7">
              {t(
                "Boost Your Business with Our Easy and Effective Bulk SMS Marketing Service - The Perfect Solution for Promoting Your Products and Services Directly."
              )}
            </p>
            {!user && (
              <span>
                <Link
                  className="btn btn-primary rounded-pill me-2"
                  to={"/login"}
                >
                  {t("Start Now")}
                </Link>
              </span>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeInformation;
