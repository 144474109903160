import React, { useEffect } from "react";
import HomeInformation from "../components/HomeInformation";
import Pricing from "../components/Pricing";
import { useTranslation } from "react-i18next";
import img from "../assets/icons/lineal/search-2.svg";
import img2 from "../assets/icons/lineal/browser.svg";
import img3 from "../assets/icons/lineal/chat-2.svg";
import img4 from "../assets/icons/lineal/megaphone.svg";
import img5 from "../assets/illustrations/i3.png";

function Home() {
  const [t] = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="content-wrapper">
        {/* <Header /> */}
        {/* /header */}
        <HomeInformation />
        {/* /section */}
        <section
          className="wrapper bg-light"
          data-image-src=".assets/img/photos/bg18.png"
        >
          <div className="container pt-14 pt-md-16">
            <div className="row text-center">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <h2 className="fs-16 text-uppercase text-muted mb-3">
                  {t("SMS Services!")}
                </h2>
                <h3 className="display-4 mb-10 text-primary fs-18  px-xl-10">
                  {t(
                    "All-In-One advertising messaging services that suits your business needs."
                  )}
                </h3>
              </div>
              {/* /column */}
            </div>
            {/* /.row */}
            <div className="position-relative">
              <div
                className="shape rounded-circle bg-soft-blue rellax w-16 h-16"
                data-rellax-speed={1}
                style={{ bottom: "-0.5rem", right: "-2.2rem", zIndex: 0 }}
              />
              <div
                className="shape bg-dot primary rellax w-16 h-17"
                data-rellax-speed={1}
                style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
              />
              <div className="row gx-md-5 gy-5 text-center">
                <div className="col-md-12 col-xl-6">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src={img}
                        className="svg-inject icon-svg icon-svg-md text-yellow mb-3"
                        alt=""
                      />
                      <h4 className="text-primary"> {t("Bulk SMS")}</h4>
                      <p className="mb-2 fs-14">
                        {t(
                          "You can send your message to your existing clients sheet that you already have in excel or txt file. Just upload your database file and write your message then send it."
                        )}
                      </p>
                      <a href="/" className="more hover link-yellow">
                        {t("Learn More")}
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
                <div className="col-md-12 col-xl-6">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src={img2}
                        className="svg-inject icon-svg icon-svg-md text-red mb-3"
                        alt=""
                      />
                      <h4 className="text-primary">{t("Mobile Area Ads")}</h4>
                      <p className="mb-2 fs-14">
                        {t(
                          "Mobile Ads allows you to target new clients in specific city or zone and send SMS to people who spend specific monthly mobile invoice amount or android or iphone mobile users."
                        )}
                      </p>
                      <a href="/" className="more hover link-red">
                        {t("Learn More")}
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
                <div className="col-md-12 col-xl-6">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src={img3}
                        className="svg-inject icon-svg icon-svg-md text-green mb-3"
                        alt=""
                      />
                      <h4 className="text-primary">
                        {t("Location Based Advertising")}
                        {t("")}
                      </h4>
                      <p className="mb-2 fs-14">
                        {t(
                          "Location based advertising or LBA service is a smart tool to target the client who visit famous malls or cairo air port or famous hotels, travel gates and popular resorts. and the SMS will reach to them once they arrives to the targeted places."
                        )}
                      </p>
                      <a href="/" className="more hover link-green">
                        {t("Learn More")}
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
                <div className="col-md-12 col-xl-6">
                  <div className="card shadow-lg">
                    <div className="card-body">
                      <img
                        src={img4}
                        className="svg-inject icon-svg icon-svg-md text-blue mb-3"
                        alt=""
                      />
                      <h4 className="text-primary">
                        SMS API integration
                        {t("")}
                      </h4>
                      <p className="mb-2 fs-14">
                        {t(
                          "SMS API Integration is a development tool to enhance your website by SMS service to verify your clients' orders or confirm orders or used to reset the password to your clients."
                        )}
                      </p>
                      <a href="/" className="more hover link-blue">
                        {t("Learn More")}
                      </a>
                    </div>
                    {/*/.card-body */}
                  </div>
                  {/*/.card */}
                </div>
                {/*/column */}
              </div>
              {/*/.row */}
            </div>
            {/* /.position-relative */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
        <section className="wrapper bg-gradient-reverse-primary">
          <div className="container py-12 py-md-12">
            <div className="row gx-lg-8 gx-xl-12 gy-10 mb-8 align-items-center">
              <div className="col-lg-7 order-lg-2">
                <figure>
                  <img className="w-auto" src={img5} alt="" />
                </figure>
              </div>
              {/*/column */}
              <div className="col-lg-5">
                <h2 className="fs-16 text-uppercase text-muted mb-3">
                  {t("Easy & Simple")}
                </h2>
                <h3 className="display-4 mb-5 fs-20">
                  {t(
                    "Don't worry about how to use the system, because we designed it to be user friendly and effective "
                  )}
                </h3>
                <p className="mb-7 fs-15">
                  {t(
                    "Everything you need to do is very simple, you can easily charge your account, upload your excel data, send your message immediately or schedule it to be sent in specific time, track your campaigns, download detailed reports and more..."
                  )}
                </p>

                {/* /.row */}
              </div>
              {/*/column */}
            </div>
            {/*/.row */}
          </div>
          {/* /.container */}
        </section>
        {/* /section */}
        {/* /section */}
        {/* /section */}
        {/* /section */}
        <Pricing />
        {/* /section */}
      </div>
      {/* /.content-wrapper */}

      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
    </div>
  );
}

export default Home;
