import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/shot-bulksms-egypt-logo.png";
import { IoMdNotifications } from "react-icons/io";
import { loginSuccess, logout } from "../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { CgMenuGridR } from "react-icons/cg";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { IoIosSend } from "react-icons/io";
import { FaUsers } from "react-icons/fa6";
import { ImBlocked } from "react-icons/im";
import { BiArchiveIn } from "react-icons/bi";
import { BiSolidReport } from "react-icons/bi";
import { MdAccessTimeFilled, MdOutlineDashboard } from "react-icons/md";
import { IoDocumentTextSharp } from "react-icons/io5";
import { CiCircleList } from "react-icons/ci";

import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
function HeaderLogin() {
  const { user, token } = useSelector((state) => state.auth);
  const URL = process.env.URL_SERVER;
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openMoney, setOpenMoney] = useState("");
  const getUser = async (e) => {
    console.log(
      `https://togethersms.com/user/${user.data.userData._id}?searchField=${e}`
    );
    if (!countryType) {
      countryType = "";
    }
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/user/${user.data._id}?searchField=${e}`,

        config
      );
      //   setLoading(false);
      dispatch(loginSuccess(res));
      console.log("user", res);
    } catch (error) {
      console.log(error);

      // return custom error message from backend if present
      //   setLoading(false);
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        document
          .querySelector(".navbar")
          .classList.add("navbar-clone", "navbar-stick");
      } else {
        document
          .querySelector(".navbar")
          .classList.remove("navbar-clone", "navbar-stick");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [countries, setcountries] = useState([]);

  const getDataGetway = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/country/user-activated-countries`,

        config,
        config
      );
      console.log(res.data, "sadashdkj");
      if (res) {
        setcountries(res.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  const [openLeft, setOpenLeft] = useState(false);
  const [t, i18n] = useTranslation();
  useEffect(() => {
    if (sessionStorage.getItem("i18nextLng") === "ar") {
      document.body.style.direction = "rtl";
      document.body.classList.add("ar");
    }
    getDataGetway();
  }, []);
  const [showServices, setShowServices] = useState(false);
  const [countryType, setCountryType] = useState("Egypt");
  return (
    <header className="wrapper bg-white">
      <nav className="navbar d-flex flex-column navbar-expand-lg center-nav transparent navbar-light ">
        <div className="container flex-lg-row flex-nowrap align-items-center p-xl-0 gap-4">
          <div className="navbar-brand ">
            <Link to="/">
              <img src={logo} alt="" style={{ width: "140px" }} />
            </Link>
          </div>
          <div
            className={
              openLeft
                ? `navbar-collapse offcanvas offcanvas-nav offcanvas-start show`
                : "navbar-collapse offcanvas offcanvas-nav offcanvas-start"
            }
          >
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">SMS</h3>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => setOpenLeft(false)}
              />
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                <li className="nav-item  ">
                  <NavLink
                    onClick={() => setOpenLeft("")}
                    className="nav-link px-3"
                    to="/"
                    data-bs-toggle="dropdown"
                  >
                    {t("Home")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                {user && (
                  <li className="nav-item dropdown p d-lg-none ">
                    <div
                      className="nav-link dropdown-toggle cursor-pointer px-3"
                      data-bs-toggle="dropdown"
                      onClick={() => {
                        setShowServices(!showServices);
                      }}
                    >
                      {t("Services")}
                    </div>
                    <div
                      className={
                        showServices
                          ? "dropdown-menu dropdown-lg show"
                          : "dropdown-menu dropdown-lg"
                      }
                    >
                      <div className="dropdown-lg-content">
                        <div>
                          <ul className="list-unstyled">
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/send-message"
                              >
                                <IoIosSend className="fs-20" />

                                {t("Send SMS")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/sender-names-list"
                              >
                                <CiCircleList className="fs-20" />
                                {t("Sender Name Activation")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/recharge-archieve"
                              >
                                <RiMoneyDollarCircleLine className="fs-20" />
                                {t("Recharge Your Balance")}
                              </Link>
                            </li>
                            {/* <li onClick={() => setOpenLeft("")}>
                              <Link className="dropdown-item d-flex align-items-center gap-1" to="/user-info">
                                {t("User Information")}
                              </Link>
                            </li> */}

                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/contacts"
                              >
                                <FaUsers className="fs-20" />

                                {t("Groups & Contacts")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/black-list-contact"
                              >
                                <ImBlocked className="fs-20" />
                                {t("Blocked Contacts")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div>
                          {/* <h6 className="dropdown-header">Single Projects</h6> */}
                          <ul className="list-unstyled">
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/message-archive"
                              >
                                <BiArchiveIn className="fs-20" />

                                {t("SMS Draft")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/message-finished"
                              >
                                <BiSolidReport className="fs-20" />

                                {t("SMS Reports")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/pending-sms"
                              >
                                <MdAccessTimeFilled className="fs-20" />
                                {t("Pending Tasks")}
                              </Link>
                            </li>
                            {/* <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/recharge-archieve"
                              >
                                {t("Recharge archieve")}
                              </Link>
                            </li> */}
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/api-doc"
                              >
                                <IoDocumentTextSharp className="fs-20" />

                                {t("API Documentation")}
                              </Link>
                            </li>{" "}
                            {/* <li onClick={()=>setOpenLeft("")}>
                            <Link className="dropdown-item d-flex align-items-center gap-1" to="">
                              {t("")}
                            </Link>
                          </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                )}

                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/blog"
                    data-bs-toggle="dropdown"
                  >
                    {t("Features")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/pricing"
                    data-bs-toggle="dropdown"
                  >
                    {t("Pricing")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/blog"
                    data-bs-toggle="dropdown"
                  >
                    {t("Support")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/contact"
                    data-bs-toggle="dropdown"
                  >
                    {t("Contact us")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
              </ul>
              <div className="offcanvas-footer d-lg-none">
                <div>
                  <a
                    href="mailto:first.last@email.com"
                    className="link-inverse"
                  >
                    info@email.com
                  </a>
                  <br /> 00 (123) 456 78 90 <br />
                  <nav className="nav social social-white mt-4">
                    <a href="/">
                      <i className="uil uil-twitter" />
                    </a>
                    <a href="/">
                      <i className="uil uil-facebook-f" />
                    </a>
                    <a href="/">
                      <i className="uil uil-dribbble" />
                    </a>
                    <a href="/">
                      <i className="uil uil-instagram" />
                    </a>
                    <a href="/">
                      <i className="uil uil-youtube" />
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-other w-100 d-flex  justify-content-end">
            <ul className="navbar-nav flex-row align-items-center  ">
              <li className="px-1 nav-link">
                <IoMdNotifications className="fs-22 fw-bold" />
              </li>
              <li className="nav-item dropdown p language-select px-md-1 text-uppercase d-lg-block ">
                <div
                  className="nav-link dropdown-item dropdown-toggle fs-14"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {i18n.language === "ar" ? "Ar" : "En"}
                </div>
                <ul className="dropdown-menu mobile">
                  <li className="nav-item">
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("en");
                        document.body.style.direction = "ltr";

                        sessionStorage.setItem("i18nextLng", "en");
                        document.body.classList.remove("ar");
                      }}
                    >
                      En
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("ar");
                        document.body.style.direction = "rtl";
                        document.body.classList.add("ar");

                        sessionStorage.setItem("i18nextLng", "ar");
                      }}
                    >
                      Ar
                    </div>
                  </li>
                </ul>
              </li>
              {/* {user && (
                <li
                  className={`${
                    countries?.length > 1 && "dropdown"
                  }  p language-select px-2 d-lg-block d-none`}
                >
                  <div
                    className={`${
                      countries?.length > 1 && "dropdown-item dropdown-toggle"
                    } nav-link  fs-14 px-0 py-0`}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user?.selected && user?.selected.countryName}{" "}
                  </div>
                  <ul className="dropdown-menu">
                    {countries?.length > 1 &&
                      countries.map((e) => (
                        <div className="dropdown-item cursor-pointer">
                          <li
                            onClick={() => {
                              getUser(e.countryName);
                              setCountryType(e.countryName);
                            }}
                            className="nav-item"
                          >
                            {e.countryName}
                          </li>
                        </div>
                      ))}
                  </ul>
                </li>
              )} */}

              {user ? (
                <>
                  {" "}
                  <li
                    className=" d-flex   fs-30 flex-column nav-link h-none  justify-content-center align-items-center p-1 "
                    onClick={() => setOpenMoney(true)}
                  >
                    <RiMoneyDollarCircleLine />
                  </li>
                  <li className="nav-item dropdown p  d-lg-flex d-none  ">
                    <div
                      className="nav-link fs-30 d-flex  justify-content-center align-items-center  cursor-pointer px-3"
                      data-bs-toggle="dropdown"
                      onClick={() => {
                        setShowServices(!showServices);
                      }}
                    >
                      <CgMenuGridR />
                    </div>
                    <div
                      className={
                        showServices
                          ? "dropdown-menu dropdown-lg show"
                          : "dropdown-menu dropdown-lg"
                      }
                    >
                      <div className="dropdown-lg-content">
                        <div>
                          <ul className="list-unstyled">
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/"
                              >

                                <MdOutlineDashboard className="fs-20" />

                                {t("Dashboard")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/send-message"
                              >
                                <IoIosSend className="fs-20" />

                                {t("Send SMS")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/sender-names-list"
                              >
                                <CiCircleList className="fs-20" />
                                {t("Sender Name Activation")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/recharge-archieve"
                              >
                                <RiMoneyDollarCircleLine className="fs-20" />
                                {t("Recharge Your Balance")}
                              </Link>
                            </li>
                            {/* <li onClick={() => setOpenLeft("")}>
                              <Link className="dropdown-item d-flex align-items-center gap-1" to="/user-info">
                                {t("User Information")}
                              </Link>
                            </li> */}

                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/contacts"
                              >
                                <FaUsers className="fs-20" />

                                {t("Groups & Contacts")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div>
                          {/* <h6 className="dropdown-header">Single Projects</h6> */}
                          <ul className="list-unstyled">
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/message-archive"
                              >
                                <BiArchiveIn className="fs-20" />

                                {t("SMS Draft")}
                              </Link>
                            </li>

                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/message-finished"
                              >
                                <BiSolidReport className="fs-20" />

                                {t("SMS Reports")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/pending-sms"
                              >
                                <MdAccessTimeFilled className="fs-20" />
                                {t("Pending Tasks")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/black-list-contact"
                              >
                                <ImBlocked className="fs-20" />
                                {t("Blocked Contacts")}
                              </Link>
                            </li>
                            <li onClick={() => setOpenLeft("")}>
                              <Link
                                className="dropdown-item d-flex align-items-center gap-1"
                                to="/api-doc"
                              >
                                <IoDocumentTextSharp className="fs-20" />

                                {t("API Documentation")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown p language-select  mx-lg-2 m-0 d-lg-block ">
                    <div
                      className="nav-link dropdown-item "
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <div>
                        {user?.userData?.image ? (
                          <img
                            className="rounded-circle me-lg-2 m-auto "
                            alt=""
                            style={{ width: "50px", height: "50px" }}
                            src={user?.userData?.image}
                          />
                        ) : (
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              background: "#00ce8f",
                            }}
                          >
                            <Avatar
                              className="avatar w-10"
                              unstyled={true}
                              name={
                                user?.userData?.firstName +
                                " " +
                                user?.userData?.lastName
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <ul className="dropdown-menu mobile">
                      <li className="nav-item">
                        <Link
                          to="/user-info"
                          className="dropdown-item cursor-pointer"
                        >
                          {t("profile")}
                        </Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          sessionStorage.removeItem("userSms");
                          dispatch(logout());
                          navigate("/login");
                        }}
                      >
                        <div className="dropdown-item cursor-pointer">
                          {t("Logout")}
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item d-lg-none p-0 mx-2">
                    <button
                      className="hamburger offcanvas-nav-btn"
                      onClick={() => setOpenLeft(true)}
                    >
                      <span />
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <span>
                    <Link
                      to={"/login"}
                      className="btn btn-primary rounded-pill me-2"
                    >
                      {t("Login")}
                    </Link>
                  </span>
                  <span style={{ textDecoration: "underline" }}>
                    <Link to={"/register"} className="  me-2">
                      {t("Sign Up")}
                    </Link>
                  </span>
                  <li className="nav-item d-lg-none p-0 mx-2">
                    <button
                      className="hamburger offcanvas-nav-btn"
                      onClick={() => setOpenLeft(true)}
                    >
                      <span />
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div
        className="offcanvas offcanvas-end text-inverse"
        id="offcanvas-info"
        data-bs-scroll="true"
      >
        <div className="offcanvas-header">
          <h3 className="text-white fs-30 mb-0">Sandbox</h3>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body pb-6">
          <div className="widget mb-8">
            <p>
              Sandbox is a multipurpose HTML5 template with various layouts
              which will be a great solution for your business.
            </p>
          </div>

          <div className="widget mb-8">
            <h4 className="widget-title text-white mb-3">
              {t("Contact Info")}
            </h4>
            <address>
              {" "}
              Moonshine St. 14/05 <br /> Light City, London{" "}
            </address>
            <div
              className="d-flex flex-column justify-content-center align-items-center p-1 "
              style={{
                borderLeft: "1px solid #eee",
                borderRight: "1px solid #eee",
              }}
            >
              Egypt {t("Blance")}{" "}
              <span className="text-primary">1000000 $</span>
            </div>
          </div>

          <div className="widget mb-8">
            <h4 className="widget-title text-white mb-3">{t("Learn More")}</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/">{t("Our Story")}</a>
              </li>
              <li>
                <a href="/">{t("Terms of Use")}</a>
              </li>
              <li>
                <a href="/">{t("Privacy Policy")}</a>
              </li>
              <li>
                <a href="/">{t("Contact Us")}</a>
              </li>
            </ul>
          </div>

          <div className="widget">
            <h4 className="widget-title text-white mb-3">{t("Follow Us")}</h4>
            <nav className="nav social social-white">
              <a href="/">
                <i className="uil uil-twitter" />
              </a>
              <a href="/">
                <i className="uil uil-facebook-f" />
              </a>
              <a href="/">
                <i className="uil uil-dribbble" />
              </a>
              <a href="/">
                <i className="uil uil-instagram" />
              </a>
              <a href="/">
                <i className="uil uil-youtube" />
              </a>
            </nav>
          </div>
        </div>
      </div>
      <Dialog
        visible={openMoney}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={() => setOpenMoney(false)}
            />
            {/* <Button
              label="Save"
              icon="pi pi-check"
              type="submit"
              onClick={(e) =>
                editRow?.group?.name ? handleEdit() : sendData(e)
              }
            /> */}
          </React.Fragment>
        }
        onHide={() => setOpenMoney(false)}
      >
        <div className="d-flex flex-column gap-4">
          {user && (
            <>
              <div className="d-flex align-items-center  ">
                <div className="form-select-wrapper  col-2 d-md-block d-none">
                  <label className="d-flex fs-20 fw-bold">{t("Country")}</label>
                </div>
                <div className="form-floating  col-md-6 col-12">
                  <div className="form-select-wrapper ">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      defaultValue={"1"}
                      //   onChange={(e) => setCountry(e.target.value)}
                    >
                      {countries.length > 1 ? (
                        <>
                          <option value={"1"}>{t("Select Country")}</option>
                          {countries?.length > 0 &&
                            countries?.map((e, index) => (
                              <option
                                key={index}
                                selected={
                                  user?.selected?.countryName === e.countryName
                                }
                                value={e._id}
                              >
                                {e.countryName}
                              </option>
                            ))}
                        </>
                      ) : (
                        <>
                          {" "}
                          {/* <option selected> {t("Select Country")}</option> */}
                          <option value={"1"} selected>
                            {" "}
                            {countries[0]?.countryName}
                          </option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="d-flex fs-20 fw-bold">
            {" "}
            {t("Available")} :
            <span className="text-primary fs-20 mx-1">
              {" "}
              {user?.selected && user?.selected.points} points
            </span>
          </div>
          {/* {user?.selected.sentPoints > 0 && ( */}
          <div className="d-flex fs-20 fw-bold">
            {" "}
            {t("Sent")} :
            <span className="text-primary fs-20 mx-1">
              {" "}
              {user?.selected?.sentPoints} points
            </span>
          </div>
          {/* )} */}
        </div>
      </Dialog>
    </header>
  );
}

export default HeaderLogin;
