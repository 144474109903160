import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Header from "../../components/Header";

export default function ApiDoc({}) {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display-1 mb-3">خدمه ال {t("Api")} </h1>
              <p className="lead px-xxl-10"></p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-2" className="wrapper pt-10 ">
              <h2 className="mb-5 text-primary"> خدمه ال {t("Api")} </h2>
              <p className="fs-20">
                هي ملف برمجة يمكنك من استخدام حسابك لديك في الارسال عن طريقها من
                موقعك مثلا : موقعك موقع تسوق ترعب في ان تصل لاعضاؤك رسالة قصيرة
                بعد عملية الدفع ، فيمكنك عن طريق الملف البرمجي دمجه مع موقعك
                بحيث يمكنك استخدام رصيدك لارسال تلك الرسائل .
              </p>
              <hr className="m-3" />
              <h2 className="mb-5 text-primary">
                {" "}
                {t("توضیحات هامة بشأن استخدام الـ API")}
              </h2>
              <p className="fs-20">
                <span className="d-block my-2">
                  {" "}
                  - لكى تستطيع استخدام الخدمة يتوجب عليك التسجيل معنا ويمكنك
                  التسجيل مجانا من هنا.
                </span>
                <span className="d-block my-2">
                  - بعد الضغط على الرابط السابق سوف تظهر لك صفحة التسجيل يرجى
                  ادخال البيانات كاملة وتفعيل العضوية.
                </span>
                <span className="d-block my-2">
                  - ليس هناك أي تكلفة إضافية لاستخدام الخدمة, كل ما عليك هو شحن
                  رصيدك والتمتع باستخدامها - بعد اتمام التسجيل سوف تقوم بعد ذلك
                  يشحن الرصيد لكم وبامكانكم استخدام اسم المستخدم وكلمة المرور
                  للارسال من خلال بوابة موقعك
                </span>
              </p>
              <h2 className="mb-5 text-primary">
                {" "}
                {t("كيفية استخدام ال API")}
              </h2>
              <h2 className="mb-5 text-primary"> {t("رابط الارسال")}</h2>
              <p>
                http://sms.masrbokra.com/sendsms.php?
                user=demo&password=demo&numbers=966540000000&sender=togetherpro.com&message=test&lang=en
              </p>
              <h2 className="mb-5 text-primary">
                {" "}
                {t("قائمة المتغيرات المستخدمة :-")}
              </h2>
              <div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-25">
                    اسم المتغير
                  </div>
                  <div className="col-8 fs-25">قيمه المتغير</div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-20">user</div>
                  <div className="col-8 fs-20">
                    قوم بالتعويض عنها باسم المستخدم
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-20">
                    password
                  </div>
                  <div className="col-8 fs-20">
                    تقوم بالتعويض عنها بكلمة المرور
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-20">
                    numbers
                  </div>
                  <div className="col-8 fs-20">
                    {" "}
                    تقوم بالتعويض عنها برقم جوال المرسل اليه, يمكنك الارسال الى
                    أكثر من رقم وذلك بوضع فاصلة (,) بين الأرقام
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-20">
                    sender
                  </div>
                  <div className="col-8 fs-20">
                    قوم بالتعويض عنها باسم المرسل
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-20">
                    message
                  </div>
                  <div className="col-8 fs-20">
                    نقوم بالتعويض عنها بالرسالة المراد إرسالها
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-4 col-md-2 fs-20">lang</div>
                  <div className="col-8 fs-20">
                    تقوم بالتعويض عنها بلغة الرسالة, القيم المتاحة : aren
                  </div>
                </div>
              </div>
              <hr className="m-3" />
              <h2 className="mb-5 text-primary">
                {" "}
                {t(
                  "مجموعة القيم الناتجة من تنقيد رابط الإرسال ومعنى كل منها :"
                )}
              </h2>
              <div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-25">
                    اسم المتغير
                  </div>
                  <div className="col-8 fs-25">قيمه المتغير</div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">1</div>
                  <div className="col-8 fs-20">تم الإرسال بنجاح</div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">0</div>
                  <div className="col-8 fs-20">خطأ في الإرسال</div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">
                    Invalid login
                  </div>
                  <div className="col-8 fs-20"> خطأ في بيانات الدخول</div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">
                    Missing or Empty Field
                  </div>
                  <div className="col-8 fs-20">
                    توجد قيمة خاطئة في إحدى الحقول, أو هناك حقل فارغ
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">
                    Insufficent Balance
                  </div>
                  <div className="col-8 fs-20">
                    رصيدك لايكفي لإتمام عملية الإرسال{" "}
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">
                    Invalid Sender
                  </div>
                  <div className="col-8 fs-20">
                    خطأ في اسم المرسل ، اسم المرسل لابد أن يتكون من حروف
                    انجليزية وأرقام
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">
                    Sender name is not activated{" "}
                  </div>
                  <div className="col-8 fs-20">اسم المرسل غير مفعل</div>
                </div>
                <div className="row mb-1">
                  <div className="text-primary col-6 col-md-4 fs-20">
                    Invalid Message Language
                  </div>
                  <div className="col-8 fs-20">
                    لغة الرسالة مخالفة عن القيمة المذكورة في متغير Lang
                  </div>
                </div>
              </div>
              <h2 className="mb-5 text-primary"> {t("رابط حلب الرصيد : ")}</h2>
              <p>
                رابط جلب الرصيد : يمكنك عن طريق الرابط التالي معرفة رصيدك دون
                الحاجة إلى الدخول إلى الموقع
              </p>
              <p>
                https://sms.masrbokra.com/sendsms.php?user=demo&password=demo&action=get
              </p>
              <h2 className="mb-5 text-primary"> {t(" ملاحظه : ")}</h2>
              <p>
                تخصم النقاط بناء على طول الرسالة المرسلة وفيما يلي بيان لكيفية
                حساب تكلفة الرسالة :-
              </p>
              <p>
                - الرسالة إنجليزية : اذا كان طول الرسالة 160 حرف أو أقل يتم
                احتسابها بنقطة واحدة, أما إذا كان طول الرسالة أكثر من ذلك يتم
                احتساب كل 153 حرف بنقطة.
              </p>
              <p>
                - الرسالة عربية : اذا كان طول الرسالة 70 حرف أو أقل يتم احتسابها
                بنقطة واحدة, أما إذا كان طول الرسالة أكثر من ذلك يتم احتساب
                كل 67 حرف بنقطة
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
