import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "reactstrap";

const CircleChart = () => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState(0);
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/get-marketing-quality`,

        config
      );
      console.log(res);
      setChartData((prevState) => ({
        ...prevState,
        series: [res?.data],
      }));
    } catch (error) {
      console.log(error);
      // if (
      //   error?.response?.data?.message ==
      //     "Token is invalid. Please log in again." ||
      //   "Unauthorized"
      // ) {
      //   console.log("done");
      //   dispatch(logout());
      // }
      //  setError(true);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const [chartData, setChartData] = useState({
    series: [0],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    },
  });

  return (
    <Card className="p-2">
      <h2>{t("Marketing Quality")} <span className="text-blue">?</span></h2>
      <div className="w-100">
        <div id="card">
          <div id="chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.series}
              type="radialBar"
              height={350}
            />
          </div>
        </div>
        <div id="html-dist"></div>
      </div>
    </Card>
  );
};

export default CircleChart;
