import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Toast } from "primereact/toast";
function EnterPhone() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [count, setCount] = useState(10);
  //   const dispatch = useDispatch();
  const token = localStorage.getItem("tokenSms");
  console.log(token);
  const toast = useRef();

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [count]);

  const handleSubmit = async (values) => {
    console.log(token);
    console.log(values);

    setLoading(true);
    try {
      const res = await axios.post(
        `https://togethersms.com/user/signup/smsSignup`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        localStorage.setItem("phone", values.phoneNumber);
        navigate("/send-mobile");
        setLoading(false);
      } else {
        console.log(res);
        // setError(true);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: "",
      confirmPhoneNumber: "",
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(/^\d{11}$/, "Phone number must be exactly 11 digits")
        .required("Required"),
      confirmPhoneNumber: Yup.string()
        .oneOf([Yup.ref("phoneNumber"), null], "Phone numbers must match")
        .required("Required"),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      handleSubmit(values);
      console.log("Form submitted with values:", values);
    },
  });

  return (
    <div className="content-wrapper">
      <Toast ref={toast} />

      {/* <Header /> */}
      <section
        className="wrapper   bg-gradient-primary"
        // style={{ backgroundImage: img }}
      >
        <div className="container   text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Enter Phone")} </h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Enter Phone")}
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <section className="wrapper bg-light">
        <div className="container ">
          <div className="row">
            <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto ">
              <div className="card">
                <div className="card-body p-11 text-center">
                  <h2 className="mb-3 text-start">{t("Enter Phone")} </h2>
                  <p className="lead mb-6 text-start">
                    {/* {t("Fill your email and password to sign in.")} */}
                  </p>
                  <form
                    className="text-start mb-3"
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   handleSubmit();
                    // }}
                    onSubmit={formik.handleSubmit}
                  >
                    <div>{t("Enter your phone number")}</div>
                    <div className="form-floating mb-4">
                      <input
                        // type="text"
                        className="form-control"
                        style={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                            ? { border: "1px solid red" }
                            : { border: "none" }
                        }
                        type="tel"
                        id="phoneNumber"
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <label htmlFor="loginPhone">{t("Enter Phone")}</label>
                      {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                        <div className="text-danger">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                    <div>{t("Confirm your phone")}</div>

                    <div className="form-floating mb-4">
                      <input
                        // type="text"
                        className="form-control"
                        style={
                          formik.touched.confirmPhoneNumber &&
                          formik.errors.confirmPhoneNumber
                            ? { border: "1px solid red" }
                            : { border: "none" }
                        }
                        type="tel"
                        id="confirmPhoneNumber"
                        name="confirmPhoneNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPhoneNumber}
                      />
                      <label htmlFor="loginPhone">
                        {t("Enter Confirm Phone")}
                      </label>
                      {formik.touched.confirmPhoneNumber &&
                      formik.errors.confirmPhoneNumber ? (
                        <div className="text-danger">
                          {formik.errors.confirmPhoneNumber}
                        </div>
                      ) : null}
                    </div>

                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      disabled={loading ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {" "}
                          {/* Loading...{" "} */}
                        </Spinner>
                      ) : null}
                      {t("Send")}
                    </button>
                  </form>

                  {/* /form */}

                  {/*/.social */}
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
    </div>
  );
}

export default EnterPhone;
