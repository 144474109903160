import React, { useEffect } from "react";
import Widgets from "../../components/Cards/Cards";
import { CardBody, Col, Container, Row } from "reactstrap";
import ApexChart, {
  RevenueCharts,
} from "../../components/Cards/DashboardEcommerceCharts";
import CartsPage from "../../components/Cards/CartsPage";
import CircleChart from "../../components/Cards/CircleChart";

function HomeUser() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container className="my-10">
      <Widgets />
      <CardBody className="p-0 pb-2">
        <div className="w-100">
          <div dir="ltr">
            <Row>
              <Col xxl={9} xl={8}>
                <ApexChart />
              </Col>
              <Col xxl={3} xl={4}>
                <CircleChart />
              </Col>
            </Row>
            {/* <RevenueCharts
            //   series={chartData}
              dataColors='["--vz-light", "--vz-primary", "--vz-secondary"]'
            /> */}
          </div>
        </div>
      </CardBody>
      <CartsPage />
    </Container>
  );
}

export default HomeUser;
