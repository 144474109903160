import React from "react";
import { Dialog } from "primereact/dialog";
import { t } from "i18next";
import ContentRechargeAdd from "./ContentRecahrgeAdd";

function ModelRechargeAdd({ contentSenderName, setcontentSenderName }) {
  return (
    <Dialog
      visible={contentSenderName}
      style={{ width: "60rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={t("Add")}
      modal
      className="p-fluid"
      //   footer={
      //     <React.Fragment>
      //       <Button
      //         label="Cancels"
      //         icon="pi pi-times"
      //         outlined
      //         onClick={hideDialog}
      //       />
      //       <Button label="Save" icon="pi pi-check" onClick={() => sendData()} />
      //     </React.Fragment>
      //   }
      onHide={() => setcontentSenderName(false)}
    >
      <ContentRechargeAdd />
    </Dialog>
  );
}

export default ModelRechargeAdd;
