import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { ProductService } from "./ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Toolbar } from "primereact/toolbar";
import { saveAs } from "file-saver";

import { Dialog } from "primereact/dialog";

import * as XLSX from "xlsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";
import { logout } from "../../store/slices/authSlice";
import AddContact from "./AddContact";
import AddFileContact from "./AddFileContact";

export default function OneGroup({ setNmaeGroup }) {
  let emptyProduct = {
    id: null,
    name: "",
    image: null,
    description: "",
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: "INSTOCK",
  };
  const dispatch = useDispatch();
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const toast = useRef(null);
  const dt = useRef(null);

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const editProduct = (product) => {
    setProductDialog(true);
  };

  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const parms = useParams().id;

  const leftToolbarTemplate = () => {
    return (
      <div>
        <div className="flex  flex-wrap fs-5 gap-3">
          <Dropdown style={{ display: "contents" }} className="mb-2">
            <Dropdown.Toggle
              variant="success"
              className="btn btn-green rounded-xl mx-1 fs-14 text-white mb-2"
              id="dropdown-basic"
            >
              {t("Actions")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className="w-100">
                <button
                  label="Delete"
                  icon="pi pi-trash"
                  severity="danger"
                  onClick={confirmDeleteSelected}
                  disabled={!selectedProducts || !selectedProducts.length}
                  className="btn btn-danger rounded-xl  fs-14  w-100 mb-2"
                >
                  {t("Delete")}
                  <i class="uil uil-shopping-basket mx-2"></i>
                </button>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={openNew}
            className="btn btn-primary rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Add Contact")}
            <i class="uil uil-plus mx-2"></i>
          </button>{" "}
          <button
            label="New"
            icon="pi pi-plus"
            severity="success"
            onClick={() => setImportExcel(true)}
            className="btn btn-secondary text-white rounded-xl mx-1 fs-14 mb-2"
          >
            {t("Upload")}
            <i class="uil uil-export  mx-2"></i>
          </button>{" "}
          <button
            label="Export Selected to Excel"
            icon="pi pi-file-excel"
            onClick={exportAllToExcel}
            className="btn btn-info text-white  rounded-xl mx-1 mb-2 fs-14"
            // disabled={!selectedProducts || !selectedProducts.length}
          >
            {t("Export")}
            <i class="uil uil-import mx-2"></i>
          </button>{" "}
          {err && (
            <button
              label="Export Selected to Excel"
              icon="pi pi-file-excel"
              onClick={exportToExcel}
              className="btn btn-info text-white  rounded-xl mx-1 mb-2 fs-14"
            >
              {t("Export Error Numbers")}
              <i class="uil uil-import mx-2"></i>
            </button>
          )}
        </div>
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
          <span className="p-input-icon-left col-12 col-md-12">
            <i className="pi pi-search" />
            <div class="form-floating mt-4">
              <input
                id="textInputExample"
                type="search"
                onInput={(e) => {
                  setGlobalFilter(e.target.value);
                  setCurrentPage(1);

                  getDataSearch(1, e.target.value);
                }}
                placeholder="Search..."
                class="form-control"
              />
              <label for="textInputExample">{t("Search...")}</label>
            </div>
          </span>
        </div>
      </div>
    );
  };

  // const rightToolbarTemplate = () => {
  //   return (
  //     <Button
  //       label="Export"
  //       icon="pi pi-upload"
  //       className="p-button-help"
  //       onClick={exportCSV}
  //     />
  //   );
  // };

  // const imageBodyTemplate = (rowData) => {
  //   return (
  //     <img
  //       src={`https://primefaces.org/cdn/primereact/images/product/${rowData.image}`}
  //       alt={rowData.image}
  //       className="shadow-2 border-round"
  //       style={{ width: "64px" }}
  //     />
  //   );
  // };

  // const priceBodyTemplate = (rowData) => {
  //   return formatCurrency(rowData.price);
  // };

  // const ratingBodyTemplate = (rowData) => {
  //   return <Rating value={rowData.rating} readOnly cancel={false} />;
  // };

  // const statusBodyTemplate = (rowData) => {
  //   return (
  //     <Tag
  //       value={rowData.inventoryStatus}
  //       severity={getSeverity(rowData)}
  //     ></Tag>
  //   );
  // };

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case "INSTOCK":
        return "success";

      case "LOWSTOCK":
        return "warning";

      case "OUTOFSTOCK":
        return "danger";

      default:
        return null;
    }
  };

  // const header = (

  // );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => deleteMultipleHandle()}
      />
    </React.Fragment>
  );
  const exportSelectedToExcel = () => {
    if (selectedProducts && selectedProducts.length > 0) {
      const selectedRows = products.filter((product) =>
        selectedProducts.includes(product.id)
      );

      const exportData = selectedRows.map((product) => ({
        Name: product.name,
        Description: product.description,
        Price: product.price,
        Category: product.category,
        Rating: product.rating,
        InventoryStatus: product.inventoryStatus,
      }));

      const ws = XLSX.utils.json_to_sheet(exportData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SelectedProducts");

      XLSX.writeFile(wb, "selected_products.xlsx");
    }
  };
  const nameBodyTemplate = (rowData) => {
    return <Link to={`/smspro-groups_grid/${rowData.id}`}>{rowData.name}</Link>;
  };
  const [checked, setChecked] = useState(false);

  const handleToggleChange = (rowData, value) => {
    updateStatus(rowData);
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData._id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <InputSwitch
        checked={!rowData.blackList || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const [editRow, setEditRow] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [change, setchange] = useState(false);
  const [importExcel, setImportExcel] = useState(false);
  const [t, i18n] = useTranslation();
  const { user, token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [phoneNumber2, setphoneNumber2] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const getDataSearch = async (page, value) => {
    if (!value) {
      value = "";
    }
    console.log(value);
    setLoading(true);
    console.log(
      `https://togethersms.com/contact/get-group-contacts/` +
        parms +
        "?page=" +
        page +
        "&searchField=" +
        value
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/contact/get-group-contacts/` +
          parms +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      if (res.status === 200) {
        setData(res.data.group[0].contact);
        setTotalPages(res.data.totalContacts / 10);
        setNmaeGroup(res?.data?.group?.name);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (
        error?.response?.data?.message ==
          "Token is invalid. Please log in again." ||
        "Unauthorized"
      ) {
        console.log("done");
        dispatch(logout());
      }
      //  setError(true);
    }
  };

  ///sendData
  const sendData = async () => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    setLoading(true);
    let data = {
      name,
      phoneNumber,
      group: parms,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/contact/create-contact?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        getDataSearch(currentPage, globalFilter);
        // setLoading(false);
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: t(res.data.message),
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const updateStatus = async (e) => {
    hideDialog();
    setLoading(true);
    let data = {
      phoneNumber: e.phoneNumber,
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const status = await axios.patch(
        `https://togethersms.com/contact/update-contact-blacklist?lang=${i18n.language}`,
        data,
        config
      );
      if (status.status === 200) {
        console.log(
          `https://togethersms.com/contact/get-group-contacts/` +
            parms +
            "?page=" +
            currentPage +
            "&searchField=" +
            globalFilter
        );
        try {
          const config = {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              withCredentials: false,
              Authorization: `Bearer ${token}`,
            },
          };
          const res = await axios.get(
            `https://togethersms.com/contact/get-group-contacts/` +
              parms +
              "?page=" +
              currentPage +
              "&searchField=" +
              globalFilter,

            config
          );
          console.log(res);
          setLoading(false);
          if (res.status === 200) {
            toast?.current?.show({
              severity: "success",
              summary: "Successful",
              detail: t(status?.data?.message),
              life: 3000,
            });
            setLoading(false);
            setData(res.data.group.contact);
            setTotalPages(res.data.totalContacts / 10);
          } else {
            console.log(res);
          }
        } catch (error) {
          setLoading(false);
          console.log(error);

          //  setError(true);
        }
      } else {
        console.log(status);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage, change]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const handleEdit = async () => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    console.log(editRow);
    setLoading(true);
    let data = {
      name,
    };

    if (phoneNumber2) {
      data.phoneNumber = phoneNumber2.slice(1);
    }

    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/contact/update-contact/${editRow._id}?lang=${i18n.language}`,
        data,
        config
      );
      console.log(res);
      if (res.status === 200) {
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
        getDataSearch(currentPage, globalFilter);
        setEditRow("");
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
        setEditRow("");
      }
    } catch (error) {
      // setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      console.log(error);
      setEditRow("");
      setLoading(false);
      //  setError(true);
    }
  };
  const [uploadExcel, setUploadExcel] = useState("");
  const [exp, setExport] = useState("");
  const [err, setError] = useState("");
  const exportToExcel = () => {
    if (exp) {
      console.log(exp, "exp");

      const blob = new Blob([exp], {
        type: exp.type,
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;

      a.download = "exported_file";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setError(false);
    }
  };
  // const exportToExcel = () => {
  //   // هنا يتم إنشاء Blob
  //   const blob = new Blob([exp], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   });

  //   // يتم تحديد اسم الملف الذي سيتم تنزيله
  //   const fileName = "example.xlsx";

  //   // يتم استخدام saveAs لتحميل الملف
  //   saveAs(blob, fileName);
  // };
  const [loadingsaveProduct, setloadingsaveProduct] = useState(false);

  const deleteMultipleHandle = async () => {
    hideDeleteProductsDialog();

    console.log(selectedProducts);

    const idArray = selectedProducts.map((item) => item._id);
    const data = {
      contactIds: idArray,
    };
    console.log(data);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/contact/delete-contact?lang=${i18n.language}`,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage);

        //  dispatch(adminVerfiy(data));

        // setLoading(false);
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: t(res?.data?.message),
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const [exportAll, setExportAll] = useState("");
  const exportAllToExcel = () => {
    fetch(`https://togethersms.com/contact/export-group-contacts/${parms}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        console.log(data);
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Groups.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
        setExportAll(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <i class="uil uil-comment-alt fs-17 text-info cursor-pointer"></i>
          <Link
            to={`/smspro-groups_grid/${rowData.id}/quick-send/${rowData.CONTACTS}`}
          >
            <i class="uil uil-telegram-alt fs-17 text-green cursor-pointer"></i>
          </Link>
          <i
            class="uil uil-edit fs-17 text-blue cursor-pointer"
            icon="pi pi-pencil mr-2"
            onClick={() => {
              setName(rowData.name);
              setphoneNumber(rowData.phoneNumber);
              setEditRow(rowData);
              // editProduct(rowData);
            }}
          ></i>
          <AiOutlineDelete
            class="uil uil-backspace fs-17 text-red cursor-pointer"
            onClick={() => confirmDeleteProduct(rowData)}
          />
          {/* <i
            class="uil uil-backspace fs-17 text-red cursor-pointer"
            icon="pi pi-trash"
            rounded
            outlined
            severity="danger"
          ></i> */}
        </div>
      </React.Fragment>
    );
  };
  const [rowDelete, setRowDelete] = useState("");

  const handleDelete = async () => {
    // const countryCode = phoneNumber.substring(1);
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");
    console.log("khkhj", rowDelete, "asd");
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    const data = {
      contactIds: [rowDelete._id],
    };
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const res = await axios.delete(
        `https://togethersms.com/contact/delete-contact?lang=${i18n.language}`,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch(currentPage);

        //  dispatch(adminVerfiy(data));

        // setLoading(false);
        toast?.current?.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data?.message,
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast?.current?.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          width={"100%"}
          ref={dt}
          loading={loading}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          // header={header}
          selectionMode={"checkbox"}
          totalRecords={20}
        >
          <Column selectionMode="multiple" exportable={false}></Column>
          <Column
            className="fs-15 fw-bold "
            // field="phoneNumber"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2 fs-20 fw-bold">
                    {rowData.phoneNumber}
                  </div>
                </React.Fragment>
              );
            }}
            header="Contacts"
            sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-15 fw-bold"
            // body={nameBodyTemplate}
            // field="name"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2 fs-20 fw-bold">
                    {rowData.name}
                  </div>
                </React.Fragment>
              );
            }}
            header="Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            className="fs-15"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData.createdAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header={t("Created At")}
            sortable
            style={{ minWidth: "2rem" }}
          ></Column>
          <Column
            className="fs-15"
            // field="inventoryStatus"
            header="Active"
            body={statusBodyTemplate2}
            // sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-15"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10}
        rows={10}
        totalRecords={totalPages * 10}
        onPageChange={onPageChange}
      />
      <AddContact
        productDialog={productDialog}
        setProductDialog={setProductDialog}
        setchange={setchange}
        name={name}
        phoneNumber={phoneNumber}
        setphoneNumber={setphoneNumber}
        setName={setName}
        toast={toast}
        change={change}
        id={parms}
        // setLoading={setLoading}
      />
      <AddFileContact
        uploadExcel={uploadExcel}
        setUploadExcel={setUploadExcel}
        setchange={setchange}
        toast={toast}
        change={change}
        id={parms}
        setExport={setExport}
        setError={setError}
        setImportExcel={setImportExcel}
        setloadingsaveProduct={setloadingsaveProduct}
        setProductDialog={setProductDialog}
        importExcel={importExcel}
      />

      <Dialog
        visible={editRow}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Edit")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => handleEdit()}
            />
          </React.Fragment>
        }
        onHide={() => setEditRow("")}
      >
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Name")}
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              class="form-control"
              defaultValue={editRow.name}
              placeholder="Text Input"
              onChange={(e) => setName(e.target.value)}
              required
              //   autoFocus
              autoFocus
            />
            {submitted && !product.name && (
              <small className="p-error">{t("Name is required.")}</small>
            )}
            <label for="textInputExample">{t("Name")}</label>
          </div>
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Phone")}
          </label>
          <div class="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              defaultValue={editRow.phoneNumber}
              class="form-control"
              placeholder="Text Input"
              onChange={(e) => setphoneNumber2(e.target.value)}
              required
              //   autoFocus
            />
            {submitted && !product.phone && (
              <small className="p-error">{t("Phone is required.")}</small>
            )}
            <label for="textInputExample">{t("Phone")}</label>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete")}
              <b>{rowDelete.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />

          <span>
            {t("Are you sure you want to delete the selected products?")}
          </span>
        </div>
      </Dialog>
    </div>
  );
}
