import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import BlackListTable from "./BlackListTable";
import { Link } from "react-router-dom";
import BlackListTableGroup from "./BlackListTableGroup";

export default function BlackList({}) {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      {/* /header */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display-1 mb-3">{t("Blocked Contacts")}</h1>
              <p className="lead px-xxl-10"></p>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/black-list-contact">
                      {t("Blocked Contacts")}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <div className="container">
        <div className="row">
          {/* /column */}

          <div className="col-xl-10 m-auto order-2">
            {/* Send message */}
            <section id="snippet-2" className="wrapper pt-10 ">
              <h2 className="mb-5">{t("Blocked Groups")}</h2>
              <BlackListTableGroup />
              {/*/.card */}
            </section>
            <section id="snippet-2" className="wrapper pt-10 ">
              <h2 className="mb-5">{t("Blocked Contacts")}</h2>
              <BlackListTable />
              {/*/.card */}
            </section>
          </div>
          {/* <Aside /> */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
}
