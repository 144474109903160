import axios from "axios";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";

function StaffPassword({ toast }) {
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState(false);
  const [companyName, setcompanyName] = useState(false);
  const { user, token } = useSelector((state) => state.auth);
  const [t] = useTranslation();
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
  const [confirm_password, setConfirmPassword] = useState();

  const handleUpdate = async () => {
    const data = { password, confirmPassword: confirm_password };
    setLoading(true)
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      console.log(config);
      const res = await axios.post(
        `https://togethersms.com/TeamMember/create-team-member`,
        data,
        config
      );
      if (res) {
        // getData(currentPage);
        setLoading(false);
        
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res?.data.message,
          life: 3000,
        });
        // console.log(res);
      } else {
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error,
          life: 3000,
        });
      //  setError(true);
    }
  };
  return (
    <div className="col-xl-10 m-auto">
      <section id="snippet-7" className="wrapper py-10">
        <div className="card">
          <div className="card-body">
            <div className="d-flex gap-3 align-items-center mb-5">
              <h3 className="m-0 p-0">Staff Password</h3>
              <span className="my-anchor-element fs-20 text-red"> ?</span>
              <Tooltip anchorSelect=".my-anchor-element" place="top">
                يمكن ان تعطى الاكونت لشخص اخر
              </Tooltip>{" "}
            </div>
            <div className="d-flex align-items-center  mb-4 ">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("New Password")}</label>
              </div>
              <div className="form-floating  col-md-6 col-12">
                <input
                  className="form-control"
                  id="loginPassword"
                  name="password"
                  //   value={validation.values.password || ""}
                  type={passwordShow ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                  onClick={() => setPasswordShow(!passwordShow)}
                >
                  {passwordShow ? (
                    <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                  ) : (
                    <i className="ri-eye-fill uil uil-eye align-middle"></i>
                  )}
                </button>
                <label htmlFor="loginPassword">{t("New Password")}</label>
              </div>
            </div>
            <div className="d-flex align-items-center  mb-4 ">
              <div className="form-select-wrapper  col-2 d-md-block d-none">
                <label className="text-orange ">{t("Confirm")}</label>
              </div>
              <div className="form-floating  col-md-6 col-12">
                <input
                  className="form-control"
                  id="loginPassword"
                  name="password"
                  //   value={validation.values.password || ""}
                  type={passwordShowConfirm ? "text" : "password"}
                  placeholder="Enter Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                  type="button"
                  id="password-addon"
                  onClick={() => setPasswordShowConfirm(!passwordShowConfirm)}
                >
                  {passwordShow ? (
                    <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                  ) : (
                    <i className="ri-eye-fill uil uil-eye align-middle"></i>
                  )}
                </button>
                <label htmlFor="loginPassword">{t("Confirm Password")}</label>
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-primary rounded-pill btn-login mb-2"
              disabled={loading ? true : false}
              onClick={() => handleUpdate()}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                </Spinner>
              ) : null}
              {t("Edit")}
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default StaffPassword;
