import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Rating } from "primereact/rating";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { Paginator } from "primereact/paginator";
import { loginSuccess } from "../../store/slices/authSlice";
import DatePicker from "react-datepicker";
import Details from "./Details";

export default function RowExpansionDemo() {
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const toast = useRef(null);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [typeProccess, setTypeProccess] = useState("stop");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const expandAll = () => {
    let _expandedRows = {};

    data.forEach((p) => (_expandedRows[`${p._id}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const [searchText, setSearchText] = useState("");

  const allowExpansion = (rowData) => {
    return rowData.totalPhoneNumbersLength > 0;
  };
  useEffect(() => {
    setProducts();
  }, [searchText]);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const rowExpansionTemplate = (data) => {
    // const [arrayToDownload, setarrayToDownload] = useState("");

    return (
      <Details
        setTypeProccess={setTypeProccess}
        data={data}
        setLoading={setLoading}
        loading={loading}
        toast={toast}
      />
    );
  };
  const exportToExcel = () => {
    fetch(
      `https://togethersms.com/MessageRecord/export-user-archived?startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Message Archive.xlsx";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const header = (
    <div className="flex flex-wrap justify-content-end gap-2">
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={expandAll}
      >
        {t("Expand All")}
      </div>
      <div
        className="btn btn-primary rounded-pill mb-0 mx-3 mb-2 text-nowrap"
        onClick={collapseAll}
      >
        {t("Collapse All")}
      </div>
    </div>
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);

  const getDataSearch = async (page, value, first, last) => {
    setLoading(true);
    if (!value) {
      value = "";
    }
    if (!first) {
      first = "";
    }
    if (!last) {
      last = "";
    }
    // if (!startDate) {
    //   setStarttDate("");
    // }
    console.log(
      `https://togethersms.com/MessageRecord/findUserPending` +
        "?page=" +
        page +
        "&&searchField=" +
        value +
        `&?startDate=${first}&endDate=${last}`
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/MessageRecord/findUserPending` +
          "?page=" +
          page +
          "&searchField=" +
          value +
          `&?startDate=${first}&endDate=${last}`,

        config
      );
      console.log(res);
      setLoading(false);

      if (res.status === 200) {
        setData(res.data.data);
      } else {
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter, startDate, endDate);
  }, [currentPage]);
  setTimeout(() => {
    getDataSearch(currentPage, globalFilter, startDate, endDate);
  }, 30000);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const [typeSearch, setTypeSearch] = useState(false);

  return (
    <div className="card">
      <Toast ref={toast} />
      <div className="p-2">
        <div className="d-flex gap-3 text-center justify-content-center">
          <div className=" d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary  mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={() => {
                setTypeSearch(!typeSearch);
              }}
            >
              Search
            </div>
          </div>
          <div className=" d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary  mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={exportToExcel}
            >
              {t("Export all the data")}
            </div>
          </div>
        </div>
        {typeSearch ? (
          <>
            <div className="d-flex gap-2 border-0 ">
              <div className="d-flex gap-1 flex-wrap">
                <div>
                  <label>Start Date : </label>
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select start date"
                  />
                </div>
                <div>
                  <label>End Date : </label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="Select end date"
                  />
                </div>
                <div
                  className="cursor-pointer input-group-text py-2 bg-primary border-primary text-white"
                  onClick={() => {
                    getDataSearch(1, globalFilter, startDate, endDate);
                  }}
                >
                  Search
                </div>
                <div
                  className="cursor-pointer input-group-text py-2 bg-primary border-primary text-white"
                  onClick={() => {
                    setStartDate("");
                    setEndDate("");
                    getDataSearch(1, globalFilter, "", "");
                  }}
                >
                  Clear
                  {/* <BsFillCalendar2MonthFill /> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-floating  col-md-6 col-12  mt-3">
                <input
                  id="textInputExample"
                  type="text"
                  className="form-control"
                  onInput={(e) => {
                    setGlobalFilter(e.target.value);
                    setCurrentPage(1);

                    getDataSearch(1, e.target.value, startDate, endDate);
                  }}
                  placeholder="Text Input"
                />
                <label htmlFor="textInputExample">{t("Search")}</label>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
      {/* <div className="p-2">
        <div className="d-flex gap-2 border-0 ">
          <div className="d-flex gap-1 flex-wrap">
            <div>
              <label>Start Date : </label>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Select start date"
              />
            </div>
            <div>
              <label>End Date : </label>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="Select end date"
              />
            </div>
            <div
              className="cursor-pointer input-group-text bg-primary border-primary text-white"
              onClick={() => {
                getDataSearch(1, globalFilter, startDate, endDate);
              }}
            >
              Search
            </div>
            <div
              className="cursor-pointer input-group-text bg-primary border-primary text-white"
              onClick={() => {
                setStartDate("");
                setEndDate("");
                getDataSearch(1, globalFilter, "", "");
              }}
            >
              Clear
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-floating  col-md-6 col-12  mt-3">
            <input
              id="textInputExample"
              type="text"
              className="form-control"
              onInput={(e) => {
                setGlobalFilter(e.target.value);
                setCurrentPage(1);

                getDataSearch(1, e.target.value, startDate, endDate);
              }}
              placeholder="Text Input"
            />
            <label htmlFor="textInputExample">{t("Search")}</label>
          </div>
          <div className="col-lg-6 col-12 d-flex flex-wrap gap-3 mt-3">
            <div
              className="btn btn-primary rounded-pill mb-0 mb-2"
              // onClick={expandAll}
            >
              {t("Export the selected")}
            </div>
            <div
              className="btn btn-primary rounded-pill mb-0 mb-2 "
              // onClick={collapseAll}
              onClick={exportToExcel}
            >
              {t("Export all the data")}
            </div>
          </div>
        </div>
      </div> */}

      <DataTable
        style={{ minHeight: "100vh", border: "1px solid #ccc" }}
        lazy
        loading={loading}
        width={"100%"}
        value={data}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        // onRowExpand={onRowExpand}
        // onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="_id"
        rows={5}
        // rowsPerPageOptions={[5, 10, 25, 50]}
        // tableStyle={{ minWidth: "50rem" }}
        // paginator
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: ".5rem" }} />

        <Column
          expander={allowExpansion}
          style={{ width: "2rem" }}
          className="fs-14 "
        />
        <Column
          header="message"
          style={{ width: "8rem" }}
          body={(rowData) => {
            return <div>{rowData.message.slice(0, 10)}</div>;
          }}
          className="fs-14 "
        />
        <Column
          field="name"
          header="SMS no. :3666"
          sortable
          body={(rowData) => {
            return <div>{rowData.totalPhoneNumbersLength}</div>;
          }}
          className="fs-13 "
        />
        <Column
          header="Drafts"
          sortable
          body={(rowData) => {
            return (
              <React.Fragment>
                <div className="d-flex align-items-center flex-wrap gap-2">
                  <Moment
                    format="YYYY/MM/DD"
                    date={rowData.createdAt}
                    style={{ marginTop: "-10px" }}
                  />{" "}
                </div>
              </React.Fragment>
            );
          }}
          className="fs-14 "
        />
        <Column
          body={(rowData) => {
            return <div>{rowData.totalPoints}</div>;
          }}
          header="Point"
          sortable
          className="fs-14 "
        />
        <Column
          body={(rowData) => {
            return <div>{rowData.actuallySentPoints}</div>;
          }}
          header="Actual spent"
          sortable
          className="fs-14 "
        />
      </DataTable>
      <Paginator
        first={currentPage * 10 - 10} // Adjust the '10' to match the number of items per page
        rows={10} // Set the number of items per page
        totalRecords={totalPages * 10} // Assuming there are 10 items per page, and 'totalPages' is the total number of pages
        onPageChange={onPageChange}
      />
    </div>
  );
}
