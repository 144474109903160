import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { ProductService } from "./ProductService";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

import { Toolbar } from "primereact/toolbar";

import { Dialog } from "primereact/dialog";

import { Link, useNavigate, useParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import axios from "axios";
import { Paginator } from "primereact/paginator";
import Moment from "react-moment";

export default function BlackListTableGroup({  }) {
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const URL = process.env.URL_SERVER;

  useEffect(() => {
    setProducts();
  }, []);

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const confirmDeleteProduct = (product) => {
    setRowDelete(product);
    setDeleteProductDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));

    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const parms = useParams().id;

  const leftToolbarTemplate = () => {
    return (
      <div className="row w-100">
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between col-12">
          <span className="p-input-icon-left col-12 col-md-12">
            <i className="pi pi-search" />
            <div className="form-floating mt-4">
              <input
                id="textInputExample"
                style={{ width: "40vw" }}
                type="search"
                onInput={(e) => {
                  setGlobalFilter(e.target.value);
                  setCurrentPage(1);

                  getDataSearch(1, e.target.value);
                }}
                placeholder="Search..."
                className="form-control"
              />
              <label htmlFor="textInputExample">{t("Search...")}</label>
            </div>
          </span>
        </div>
      </div>
    );
  };

  // const header = (

  // );

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const handleToggleChange = (rowData, value) => {
    updateStatus(rowData);
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [rowData._id]: value,
    }));
  };

  const statusBodyTemplate2 = (rowData) => {
    return (
      <InputSwitch
        checked={!rowData.blackList || false}
        onChange={(e) => handleToggleChange(rowData, e.value)}
      />
    );
  };

  const [checkedRows, setCheckedRows] = useState({});

  const [uploadedExcelData, setUploadedExcelData] = useState([]);

  const [editRow, setEditRow] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [importExcel, setImportExcel] = useState(false);
  const [t, i18n] = useTranslation();
  const { user, error, msg, verifyEmail, vPhone, questions, token } =
    useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  ///////getData////
  const navigate = useNavigate("");
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();

  const getDataSearch = async (page, value) => {
    console.log(value);
    if (!value) {
      value = "";
    }
    setLoading(true);
    console.log(
      `https://togethersms.com/group/find-group-blacklist` +
        "?page=" +
        page +
        "&searchField=" +
        value
    );
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/group/find-group-blacklist` +
          "?page=" +
          page +
          "&searchField=" +
          value,

        config
      );
      console.log(res);
      if (res.status === 200) {
        setData(res.data.groups);
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };

  ///sendData
  const sendData = async () => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    setLoading(true);
    let data = {
      name,
      phoneNumber,
      group: parms,
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `https://togethersms.com/contact/create-contact`,
        data,
        config
      );
      console.log(res);
      if (res.status === 201) {
        //  dispatch(adminVerfiy(data));
        getDataSearch(currentPage, globalFilter);
        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };
  const updateStatus = async (e) => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    setLoading(true);

    getDataSearch(currentPage, globalFilter);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/group/update-group-blacklist/${e.group._id}`,
        data,
        config
      );
      console.log(res);
      if (res.status === 200) {
       
        getDataSearch(currentPage, globalFilter);
        setTimeout(() => {
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: res?.data?.message,
            life: 3000,
          });
          setLoading(false);
        }, 3000);
      } else {
        console.log(res);
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error?.response?.data?.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  useEffect(() => {
    getDataSearch(currentPage, globalFilter);
  }, [currentPage]);
  const onPageChange = (event) => {
    setCurrentPage(event.page + 1); // Add 1 because PrimeReact Paginator starts indexing from 0
  };

  const handleEdit = async () => {
    // const countryCode = phoneNumber.substring(1);
    hideDialog();
    console.log(editRow);
    setLoading(true);
    let data = {
      name,
      phoneNumber: phoneNumber.slice(1),
    };
    console.log(data);
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.patch(
        `https://togethersms.com/contact/update-contact/${editRow._id}`,
        data,
        config
      );
      console.log(res);
      if (res.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
        getDataSearch(currentPage, globalFilter);
        setEditRow("");
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
        setEditRow("");
      }
    } catch (error) {
      // setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      console.log(error);
      setEditRow("");
      setLoading(false);
      //  setError(true);
    }
  };
  const [uploadExcel, setUploadExcel] = useState("");
  const [exp, setExport] = useState("");
  const [err, setError] = useState("");
  const exportToExcel = () => {
    console.log(exp);
    if (exp) {
      const blob = new Blob([exp], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "error.xlsx";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      setError(false);
    }
  };
  const saveProduct = async () => {
    setSubmitted(false);
    setProductDialog(false);
    // console.log(uploadExcel);

    if (uploadExcel) {
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.post(
          `https://togethersms.com/importer/contacts-import/${parms}`,
          { file: uploadExcel },

          config
        );
        // console.log(res);
        if (res.status === 201) {
          if (res.data.errorFile) {
            setError(true);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: res.data.message,
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "success",
              summary: "Successful",
              detail: "Excel Data Added to Table",
              life: 3000,
            });
          }
          console.log(res);
          fetch(`https://togethersms.com/importer${res.data.errorFile}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((data) => {
              setExport(data);
            })
            .catch((error) => {});

          getDataSearch(currentPage, globalFilter);
        } else {
          // console.log(res);
        }
      } catch (error) {
        console.log(error);
        // setLoading(false);
        // console.log(error);
        //  setError(true);
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex gap-2 align-items-center">
          <i className="uil uil-comment-alt fs-17 text-info cursor-pointer"></i>
          <Link
            to={`/smspro-groups_grid/${rowData.id}/quick-send/${rowData.CONTACTS}`}
          >
            <i className="uil uil-telegram-alt fs-17 text-green cursor-pointer"></i>
          </Link>
          <i
            className="uil uil-edit fs-17 text-blue cursor-pointer"
            icon="pi pi-pencil mr-2"
            onClick={() => {
              setName(rowData.name);
              setphoneNumber(rowData.phoneNumber);
              setEditRow(rowData);
              // editProduct(rowData);
            }}
          ></i>
          <AiOutlineDelete
            className="uil uil-backspace fs-17 text-red cursor-pointer"
            onClick={() => confirmDeleteProduct(rowData)}
          />
          {/* <i
            className="uil uil-backspace fs-17 text-red cursor-pointer"
            icon="pi pi-trash"
            rounded
            outlined
            severity="danger"
          ></i> */}
        </div>
      </React.Fragment>
    );
  };
  const [rowDelete, setRowDelete] = useState("");

  const handleDelete = async () => {
    // const countryCode = phoneNumber.substring(1);
    setDeleteProductDialog(false);
    hideDialog();
    setLoading(true);
    setEditRow("");
    setRowDelete("");
    console.log("khkhj", rowDelete, "asd");
    // setLoading(true);
    //  dispatch(adminVerfiy(data));
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/contact/delete-contact/${rowDelete._id}`,
        config
      );
      console.log(res);
      if (res) {
        getDataSearch();

        //  dispatch(adminVerfiy(data));

        // setLoading(false);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: res.data.message,
          life: 3000,
        });
        setLoading(false);
      } else {
        console.log(res);
        setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response.data.message,
        life: 3000,
      });
      //  setError(true);
    }
  };

  const productDialogFooter2 = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveProduct} />
    </React.Fragment>
  );

  return (
    <div>
      <Toast ref={toast} />

      <div className="card-body">
        <Toolbar className="mb-4 w-100" left={leftToolbarTemplate}></Toolbar>

        <DataTable
          style={{ minHeight: "100vh", border: "1px solid #ccc" }}
          width={"100%"}
          ref={dt}
          loading={loading}
          value={data}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          // dataKey="id"
          rows={10}
          // header={header}
          selectionMode={"checkbox"}
          totalRecords={20}
        >
          <Column selectionMode="multiple" exportable={false}></Column>

          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            field="group.name"
            header="Name"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            className="fs-13"
            // body={nameBodyTemplate}
            field="totalContacts"
            header="Total Contacts"
            sortable
            style={{ minWidth: "6rem" }}
          ></Column>

          <Column
            className="fs-13"
            body={(rowData) => {
              return (
                <React.Fragment>
                  <div className="d-flex align-items-center gap-2">
                    <Moment
                      format="YYYY/MM/DD"
                      date={rowData.createAt}
                      style={{ marginTop: "-10px" }}
                    />{" "}
                  </div>
                </React.Fragment>
              );
            }}
            header={t("Created At")}
            sortable
            style={{ minWidth: "2rem" }}
          ></Column>
          <Column
            className="fs-13"
            // field="inventoryStatus"
            header="Active"
            body={statusBodyTemplate2}
            // sortable
            style={{ minWidth: "4rem" }}
          ></Column>

          <Column
            className="fs-13"
            header="Actions"
            body={actionBodyTemplate}
            exportable={false}
            // style={{ minWidth: "8rem" }}
          ></Column>
        </DataTable>
      </div>
      <Paginator
        first={currentPage * 10 - 10}
        rows={10}
        totalRecords={totalPages * 10}
        onPageChange={onPageChange}
      />

      <Dialog
        visible={productDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Add")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => sendData()}
            />
          </React.Fragment>
        }
        onHide={hideDialog}
      >
        <div className="field">
          <div className="field">
            <label htmlFor="description" className="font-bold">
              {t("Name")}
            </label>
            <div className="form-floating mb-4">
              <input
                id="textInputExample"
                type="text"
                className="form-control"
                placeholder="Text Input"
                onChange={(e) => setName(e.target.value)}
                required
                //   autoFocus
                autoFocus
              />
              {submitted && !product.name && (
                <small className="p-error">Name is required.</small>
              )}
              <label for="textInputExample">{t("Name")} </label>
            </div>
          </div>
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Phone")}
          </label>
          <div className="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              className="form-control"
              placeholder="Text Input"
              onChange={(e) => setphoneNumber(e.target.value)}
              required
              //   autoFocus
              autoFocus
            />
            {submitted && !product.phone && (
              <small className="p-error">{t("Phone is required.")}</small>
            )}
            <label for="textInputExample">{t("Phone")}</label>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={editRow}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={t("Edit")}
        modal
        className="p-fluid"
        footer={
          <React.Fragment>
            <Button
              label="Cancels"
              icon="pi pi-times"
              outlined
              onClick={hideDialog}
            />
            <Button
              label="Save"
              icon="pi pi-check"
              onClick={() => handleEdit()}
            />
          </React.Fragment>
        }
        onHide={() => setEditRow("")}
      >
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Name")}
          </label>
          <div className="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              className="form-control"
              defaultValue={editRow.name}
              placeholder="Text Input"
              onChange={(e) => setName(e.target.value)}
              required
              //   autoFocus
              autoFocus
            />
            {submitted && !product.name && (
              <small className="p-error">{t("Name is required.")}</small>
            )}
            <label for="textInputExample">{t("Name")}</label>
          </div>
        </div>
        <div className="field">
          <label htmlFor="description" className="font-bold">
            {t("Phone")}
          </label>
          <div className="form-floating mb-4">
            <input
              id="textInputExample"
              type="text"
              defaultValue={editRow.phoneNumber}
              className="form-control"
              placeholder="Text Input"
              onChange={(e) => setphoneNumber(e.target.value)}
              required
              //   autoFocus
            />
            {submitted && !product.phone && (
              <small className="p-error">{t("Phone is required.")}</small>
            )}
            <label for="textInputExample">{t("Phone")}</label>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={importExcel}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Upload Excel"
        modal
        className="p-fluid"
        footer={productDialogFooter2}
        onHide={() => setImportExcel(false)}
      >
        <>
          {!uploadExcel && (
            <div className="d-flex align-items-center justify-content-between ">
              <div className="form-select-wrapper col-3 d-md-block d-none">
                <label className="text-orange ">{t("Upload Excel")} </label>
              </div>
              <div>
                <button
                  label="Upload Excel"
                  icon="pi pi-upload"
                  className="btn btn-primary rounded-xl mx-1 fs-14"
                  onClick={() =>
                    document.getElementById("upload-excel-input").click()
                  }
                >
                  {t("Upload")}
                  <i className="uil uil-export  mx-2"></i>
                </button>
              </div>

              <input
                id="upload-excel-input"
                type="file"
                accept=".xlsx, .xls"
                style={{ display: "none" }}
                onChange={(e) => {
                  setUploadExcel(e.target.files[0]);
                }}
                //   onChange={handleUploadExcel}
              />
            </div>
          )}
          {uploadExcel && (
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex gap-2 align-items-center">
                <svg
                  className="fs-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 48 48"
                >
                  <rect
                    width="16"
                    height="9"
                    x="28"
                    y="15"
                    fill="#21a366"
                  ></rect>
                  <path
                    fill="#185c37"
                    d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"
                  ></path>
                  <rect
                    width="16"
                    height="9"
                    x="28"
                    y="24"
                    fill="#107c42"
                  ></rect>
                  <rect
                    width="16"
                    height="9"
                    x="12"
                    y="15"
                    fill="#3fa071"
                  ></rect>
                  <path
                    fill="#33c481"
                    d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"
                  ></path>
                  <path
                    fill="#21a366"
                    d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"
                  ></path>
                  <path
                    d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z"
                    opacity=".05"
                  ></path>
                  <path
                    d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z"
                    opacity=".07"
                  ></path>
                  <path
                    d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z"
                    opacity=".09"
                  ></path>
                  <linearGradient
                    id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1"
                    x1="4.725"
                    x2="23.055"
                    y1="14.725"
                    y2="33.055"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#18884f"></stop>
                    <stop offset="1" stop-color="#0b6731"></stop>
                  </linearGradient>
                  <path
                    fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)"
                    d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z"
                  ></path>
                </svg>
                <div>{uploadExcel.name}</div>
              </div>
              <button
                icon="pi pi-trash"
                severity="danger"
                onClick={() => setUploadExcel("")}
                className="btn btn-danger rounded-xl  fs-20   mb-2"
              >
                <i className="uil uil-shopping-basket "></i>
              </button>
            </div>
          )}
        </>
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={
          <React.Fragment>
            <Button
              label="No"
              icon="pi pi-times"
              outlined
              onClick={hideDeleteProductDialog}
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              severity="danger"
              onClick={() => handleDelete()}
            />
          </React.Fragment>
        }
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete")}
              <b>{rowDelete.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />
          {product && (
            <span>
              {t("Are you sure you want to delete the selected products?")}
            </span>
          )}
        </div>
      </Dialog>
    </div>
  );
}
