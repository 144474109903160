import React, { useEffect } from "react";
import img from "../../assets/ok-sign.png";
import { Link } from "react-router-dom";
function Success() {
 useEffect(() => {
   window.scrollTo(0, 0);
 }, []);
  return (
    <div className="d-flex flex-column px-3 pb-5 w-100 align-items-center justify-content-center  bg-soft-primary">
      <img src={img} style={{ width: "25%" }} />
      <h2 className="py-4 text-primary">Perfect, your campaign is Running Now</h2>
      <div className="d-flex align-items-center gap-5 flex-wrap">
        <Link to={"/"}>
          <button className="cursor-pointer input-group-text bg-primary border-primary text-white">
            Go to home
          </button>
        </Link>
        <Link to={"/message-archive"}>
          <button className="cursor-pointer input-group-text bg-primary border-primary text-white">
            Go to SMS Draft
          </button>
        </Link>
        <Link to={"/pending-sms"}>
          <button className="cursor-pointer input-group-text bg-primary border-primary text-white">
            Go to Pending Tasks
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Success;
