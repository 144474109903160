// import { IconCaretDownFilled, IconCaretUpFilled } from "@tabler/icons-react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import data from "./data";
// import "./style.css";
export default function Select({
  setPage,
  page,
  data,
  hasMore,
  setOpenGroup,
  setOpenSelect,
  setSelectGroup,
  openGroup,
  loading,
}) {
  const [address, setAddress] = useState("Choose Group");
  const [count, setCount] = useState(10);
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", click);
    return () => {
      document.removeEventListener("mousedown", click);
    };
  }, []);

  const menuShow = useRef();
  const [t, i18n] = useTranslation();
  const click = (e) => {
    if (menuShow?.current?.contains(e.target)) {
      // inside click
      return;
    } else {
      //   setSelectGroup(true);
      setOpenSelect(false);
      setShow(false);
    }
  };
  // const [loading, setLoading] = useState(false);
  return (
    <div className="container select">
      <div className="head">
        <div
          className="header"
          onClick={() => {
            setShow(!show);
          }}
        >
          <div style={{ color: "#fab758" }}>
            <h3>{openGroup ? openGroup.group.name : "Choose Group"}</h3>
          </div>

          <div style={{ position: "absolute", top: 15, right: 5 }}>
            {/* {show ? <IconCaretUpFilled /> : <IconCaretDownFilled />} */}
          </div>
        </div>
      </div>
      <div
        className="options"
        style={{ display: show ? "block" : "none" }}
        ref={menuShow}
      >
        {data.map((e, i) => (
          <div
            key={i}
            className="item"
            onClick={() => {
              setOpenGroup(e);
              setSelectGroup(true);
              setOpenSelect(false);
              setShow(false);
            }}
          >
            {e.group.name}
          </div>
        ))}
        {hasMore && (
          <button
            type="submit"
            className="btn btn-primary rounded-pill btn-login  mb-2"
            disabled={loading ? true : false}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                {" "}
                {/* Loading...{" "} */}
              </Spinner>
            ) : null}
            {t("More ..")}{" "}
          </button>
          // <div

          //   className="cursor-pointer"
          // >
          //   more...
          //   {loading ? (
          //     <Spinner size="sm" className="me-2">
          //       {" "}
          //       {/* Loading...{" "} */}
          //     </Spinner>
          //   ) : null}
          // </div>
        )}
      </div>
    </div>
  );
}
