import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";
import SenderNameTable from "./SenderNamesTable";

function SenderNamesList() {
  const { user, token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate("");
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [selectDelete, setSelectDelete] = useState();
  const URL = process.env.URL_SERVER;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const getData = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.get(
        `https://togethersms.com/CompanyActivation/get-User-CompanyActivations`,

        config
      );
      console.log(res);
      if (res.status === 200) {
        setData(res.data.companyActivations);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };
  const deleteProduct = async (e) => {
    console.log(token);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          withCredentials: false,
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.delete(
        `https://togethersms.com/CompanyActivation/delete/${selectDelete._id}`,

        config
      );
      console.log(res);

      if (res.status === 200) {
        getData();
        setDeleteProductDialog(false);
      } else {
        console.log(res);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);

      //  setError(true);
    }
  };
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        outlined
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );

  return (
    <div className="content-wrapper">
      {/* <Header /> */}

      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display31 mb-3">{t("Sender Name Activation")}</h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/sender-names-list">
                      {t("Sender Name Activation")}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-7" className="wrapper py-10">
              <div className="btn  btn-primary  mb-5">
                {/* <i className="uil uil-arrow-right"></i> */}
                <span
                  onClick={() => {
                    console.log("ASd");
                    sessionStorage.setItem(
                      "contentAddSenderName",
                      JSON.stringify({})
                    );
                    navigate("/add-sender-name");
                  }}
                >
                  {t("Add New Sender Name")}
                </span>
              </div>
              <div className="card">
                <SenderNameTable />

                <Dialog
                  visible={deleteProductDialog}
                  style={{ width: "32rem" }}
                  breakpoints={{ "960px": "75vw", "641px": "90vw" }}
                  header="Confirm"
                  modal
                  footer={deleteProductDialogFooter}
                  onHide={hideDeleteProductDialog}
                >
                  <div className="confirmation-content">
                    <i
                      className="pi pi-exclamation-triangle mr-3"
                      style={{ fontSize: "2rem" }}
                    />

                    <span>
                      {t("Are you sure you want to delete")}
                      <b> {selectDelete?.senderName}</b>?
                    </span>
                  </div>
                </Dialog>
                <div
                  id="collapse-7"
                  className="card-footer bg-dark p-0 accordion-collapse collapse"
                >
                  <div className="code-wrapper">
                    <div className="code-wrapper-inner">
                      <pre className="language-html">
                        <code>
                          &lt;div className=&quot;table-responsive&quot;&gt;
                          &lt;table className=&quot;table&quot;&gt; ...
                          &lt;/table&gt; &lt;/div&gt;
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <Aside /> */}
        </div>
      </div>
    </div>
  );
}

export default SenderNamesList;
