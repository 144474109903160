import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import OneGroup from "./OneGroup";
import { Link, useParams } from "react-router-dom";

export default function SmsproGroupsGrid({
  selectedOption,
  setSelectedOption,
}) {
  const [textInput, setTextInput] = useState("");
  const [charCount, setCharCount] = useState(0);
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setTextInput(inputValue);
    setCharCount(inputValue.length);
  };
  const currentDate = new Date();
  const [timeSend, setTimeSend] = useState("now");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [t, i18n] = useTranslation();
  const [modalOpened, setModalOpened] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [nameGroup, setNmaeGroup] = useState("");
  const id = useParams().id;
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      {/* /header */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-9 col-lg-7 col-xl-6 mx-auto">
              <h1 className="display-1 mb-3">
                {t("Group")} {nameGroup}
              </h1>
              <p className="lead px-xxl-10"></p>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb ">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("Home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/contacts"}> {t("Groups & Contacts")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to={"/smspro-groups_grid/" + id}>
                      {" "}
                      {t("Group")} {nameGroup}
                    </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}
      <div className="container">
        <div className="row">
          {/* /column */}

          <div className="col-xl-10 m-auto order-2">
            {/* Send message */}
            <section id="snippet-2" className="wrapper pt-10 ">
              <h2 className="mb-5">
                {" "}
                {t("Group")} {nameGroup}
              </h2>
              <OneGroup setNmaeGroup={setNmaeGroup} />
              {/*/.card */}
            </section>
          </div>
          {/* <Aside /> */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
  );
}
