import axios from "axios";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function Explan({}) {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const params = useParams().id;
  const [data, setData] = useState();
  const { user, token } = useSelector((state) => state.auth);

  const getData = async (e) => {
    try {
      const res = await axios.get(`https://togethersms.com/Tutorial/${params}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res);
      setData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section className="wrapper bg-soft-primary">
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <h1 className="display-1 mb-3">
                {params == 1
                  ? "كيف تقوم بإنشاء حساب على الموقع"
                  : params == 3
                  ? "كيفية إضافة رصيد"
                  : params == 4
                  ? "طريقة إرسال رسالة"
                  : params == 5
                  ? "طريقة إنشاء جروب أرقام عن طريق شيب اكسيل"
                  : params == 2 && t("طريقة تفعيل إسم شركتك على شبكات المحمول")}
              </h1>
              <p className="lead px-xxl-10"></p>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto order-2">
            <section id="snippet-2" className="wrapper pt-10 ">
              <h2 className="mb-5 text-primary"> {data?.tutorialName}</h2>

              <div>
                <h3 className="text-primary">{t("Details")} :</h3>
                <p className="fs-20">{data?.tutorialDescription}</p>
              </div>
              <div>
                <h3 className="text-primary">{t("Meta Description")} :</h3>
                <p className="fs-20">{data?.metaDescription}</p>
              </div>
              <div>
                <h3 className="text-primary">{t("Meta Keyword")} :</h3>
                <p className="fs-20">{data?.metaKeyword}</p>
              </div>
              <div>
                <a href={data?.youtubeURL} target="_blank" className="fs-20">
                  {" "}
                  <h3
                    style={{ textDecoration: "underline" }}
                    className="text-primary"
                  >
                    {t("Youtube URL")}{" "}
                  </h3>
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
