import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "../assets/shot-bulksms-egypt-logo.png";
import { useTranslation } from "react-i18next";
import { IoMdNotifications } from "react-icons/io";

function Header() {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        document
          .querySelector(".navbar")
          .classList.add("navbar-clone", "navbar-stick");
      } else {
        document
          .querySelector(".navbar")
          .classList.remove("navbar-clone", "navbar-stick");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [openLeft, setOpenLeft] = useState(false);
  const [t, i18n] = useTranslation();
  useEffect(() => {
    if (sessionStorage.getItem("i18nextLng") === "ar") {
      document.body.style.direction = "rtl";
      document.body.classList.add("ar");
    }
  }, []);
  return (
    <header className="wrapper bg-soft-primary">
      <nav className="navbar d-flex flex-column navbar-expand-lg center-nav transparent navbar-light ">
        <div className="container flex-lg-row flex-nowrap align-items-center p-xl-0 gap-4">
          <div className="navbar-brand ">
            <Link to="/">
              <img src={logo} alt="" style={{ width: "140px" }} />
            </Link>
          </div>
          <div
            className={
              openLeft
                ? `navbar-collapse offcanvas offcanvas-nav offcanvas-start show`
                : "navbar-collapse offcanvas offcanvas-nav offcanvas-start"
            }
          >
            <div className="offcanvas-header d-lg-none">
              <h3 className="text-white fs-30 mb-0">SMS</h3>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => setOpenLeft(false)}
              />
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                <li className="nav-item  ">
                  <NavLink
                    onClick={() => setOpenLeft("")}
                    className="nav-link px-3"
                    to="/"
                    data-bs-toggle="dropdown"
                  >
                    {t("Home")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>

                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/blog"
                    data-bs-toggle="dropdown"
                  >
                    {t("Features")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/pricing"
                    data-bs-toggle="dropdown"
                  >
                    {t("Pricing")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/blog"
                    data-bs-toggle="dropdown"
                  >
                    {t("Support")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/explans"
                    data-bs-toggle="dropdown"
                  >
                    {t("How it works ?")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item  ">
                  <NavLink
                    className="nav-link px-3"
                    to="/contact"
                    data-bs-toggle="dropdown"
                  >
                    {t("Contact us")}
                  </NavLink>

                  {/*/.dropdown-menu */}
                </li>
              </ul>
              <span>
                <Link
                  to={"/login"}
                  className="btn btn-primary rounded-pill me-2 d-sm-none"
                >
                  {t("Login")}
                </Link>
              </span>
              <div className="offcanvas-footer d-lg-none">
                <div>
                  <a
                    href="mailto:first.last@email.com"
                    className="link-inverse"
                  >
                    info@email.com
                  </a>
                  <br /> 00 (123) 456 78 90 <br />
                  <nav className="nav social social-white mt-4">
                    <a href="/">
                      <i className="uil uil-twitter" />
                    </a>
                    <a href="/">
                      <i className="uil uil-facebook-f" />
                    </a>
                    <a href="/">
                      <i className="uil uil-dribbble" />
                    </a>
                    <a href="/">
                      <i className="uil uil-instagram" />
                    </a>
                    <a href="/">
                      <i className="uil uil-youtube" />
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-other w-100 d-flex  justify-content-end">
            <ul className="navbar-nav flex-row align-items-center  ">
              <li className="px-1 nav-link">
                <IoMdNotifications className="fs-22 fw-bold" />
              </li>
              <li className="nav-item dropdown p language-select px-md-1 text-uppercase d-lg-block ">
                <div
                  className="nav-link dropdown-item dropdown-toggle fs-14"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {i18n.language === "ar" ? "Ar" : "En"}
                </div>
                <ul className="dropdown-menu mobile">
                  <li className="nav-item">
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("en");
                        document.body.style.direction = "ltr";

                        sessionStorage.setItem("i18nextLng", "en");
                        document.body.classList.remove("ar");
                      }}
                    >
                      En
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      className="dropdown-item cursor-pointer"
                      onClick={() => {
                        i18n.changeLanguage("ar");
                        document.body.style.direction = "rtl";
                        document.body.classList.add("ar");

                        sessionStorage.setItem("i18nextLng", "ar");
                      }}
                    >
                      Ar
                    </div>
                  </li>
                </ul>
              </li>

              {user ? (
                <> </>
              ) : (
                <>
                  <span>
                    <Link
                      to={"/login"}
                      className="btn btn-primary rounded-pill me-2 d-none d-sm-block"
                    >
                      {t("Login")}
                    </Link>
                  </span>
                  <span
                    style={{ textDecoration: "underline" }}
                    className="d-none d-lg-block"
                  >
                    <Link to={"/register"} className="  me-2">
                      {t("Sign Up")}
                    </Link>
                  </span>
                  <li className="nav-item d-lg-none mx-1">
                    <button
                      className="hamburger offcanvas-nav-btn"
                      onClick={() => setOpenLeft(true)}
                    >
                      <span />
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
