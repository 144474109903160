import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function AddFileContact({
  uploadExcel,
  setUploadExcel,
  setExport,
  setError,
  setImportExcel,
  setloadingsaveProduct,
  setProductDialog,
  importExcel,
  //   setLoading,
  toast,
  setchange,
  change,
  id,
  loadingsaveProduct,
}) {
  const hideDialog = () => {
    // setSubmitted(false);
    setUploadExcel("");
  };

  const [t, i18n] = useTranslation();
  const { user, token } = useSelector((state) => state.auth);
  const saveProduct = async () => {
    setProductDialog(false);
    setloadingsaveProduct(true);
    // console.log(uploadExcel);
    setImportExcel(false);
    toast?.current?.show({
      severity: "success",
      summary: "Successful",
      detail: t("Numbers are being uploaded..."),
      life: 3000,
    });
    if (uploadExcel) {
      console.log(uploadExcel);
      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            withCredentials: false,
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.post(
          `https://togethersms.com/importer/contacts-import/${id}?lang=${i18n.language}`,
          { file: uploadExcel },

          config
        );
        // console.log(res);
        if (res.status === 201) {
          setloadingsaveProduct(false);

          if (res.data.errorFile) {
            setError(true);
            setExport(res.data.errorFile);
            toast?.current?.show({
              severity: "error",
              summary: "Error",
              detail: res.data.message,
              life: 3000,
            });
          } else {
            toast?.current?.show({
              severity: "success",
              summary: "Successful",
              detail: "Excel Data Added to Table",
              life: 3000,
            });
          }
          console.log(
            `https://togethersms.com/importer/public/error-files/${res.data.errorFile}`
          );
          fetch(
            `https://togethersms.com/importer/public/error-files/${res.data.errorFile}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
            .then((response) => {
              console.log(response, "response");
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.blob();
            })
            .then((data) => {
              console.log("file", data);
              setExport(data);
            })
            .catch((error) => {});

          setchange(!change);
        } else {
          // console.log(res);
        }
      } catch (error) {
        setloadingsaveProduct(false);

        console.log(error);
        toast?.current?.show({
          severity: "error",
          summary: "Error",
          detail: error?.response?.data?.message,
          life: 3000,
        });
      }
    }
  };
  const productDialogFooter2 = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      {uploadExcel && (
        <Button
          label="Save"
          icon="pi pi-check"
          onClick={saveProduct}
          disabled={loadingsaveProduct}
        />
      )}
    </React.Fragment>
  );

  const exportAllToExcel = () => {
    fetch(`https://togethersms.com/importer/download-sample-excel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((data) => {
        console.log(data);
        if (data) {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "Sample";
          a.style.display = "none";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((error) => {});
  };
   const exportAllToPdf = () => {
     fetch(`https://togethersms.com/importer/download-sample-text`, {
       method: "GET",
       headers: {
         Authorization: `Bearer ${token}`,
       },
     })
       .then((response) => {
         if (!response.ok) {
           throw new Error("Network response was not ok");
         }
         return response.blob();
       })
       .then((data) => {
         console.log(data);
         if (data) {
           const blob = new Blob([data], {
             type: data.type,
           });
           const url = window.URL.createObjectURL(blob);
           const a = document.createElement("a");
           a.href = url;
           a.download = "Sample";
           a.style.display = "none";
           document.body.appendChild(a);
           a.click();
           window.URL.revokeObjectURL(url);
           document.body.removeChild(a);
         }
       })
       .catch((error) => {});
   };
  return (
    <Dialog
      visible={importExcel}
      style={{ width: "32rem" }}
      breakpoints={{ "960px": "75vw", "641px": "90vw" }}
      header={t("Upload Excel or Text")}
      modal
      className="p-fluid"
      footer={productDialogFooter2}
      onHide={() => setImportExcel(false)}
    >
      <>
        {!uploadExcel && (
          <div className="d-flex align-items-center justify-content-between ">
            <div class="form-select-wrapper col-5 d-md-block d-none">
              <label className="text-orange ">
                {t("Upload Excel or Text")}{" "}
              </label>
            </div>
            <div>
              <button
                label="Upload Excel"
                icon="pi pi-upload"
                className="btn btn-primary rounded-xl mx-1 fs-14"
                onClick={() =>
                  document.getElementById("upload-excel-input").click()
                }
              >
                {t("Upload")}
                <i class="uil uil-export  mx-2"></i>
              </button>
            </div>

            <input
              id="upload-excel-input"
              type="file"
              accept=".xlsx, .xls,.txt"
              style={{ display: "none" }}
              onChange={(e) => {
                // console.log(e.target.value);
                setUploadExcel(e.target.files[0]);
              }}
              //   onChange={handleUploadExcel}
            />
          </div>
        )}
        {uploadExcel && (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex gap-2 align-items-center">
              {uploadExcel.name.split(".").pop() == "txt" ? (
                <svg
                  style={{ width: "30px", height: "30px" }}
                  fill="#000000"
                  version="1.1"
                  id="Capa_1"
                  width="800px"
                  height="800px"
                  viewBox="0 0 548.291 548.291"
                >
                  <g>
                    <path
                      d="M486.201,196.124h-13.166V132.59c0-0.396-0.062-0.795-0.115-1.196c-0.021-2.523-0.825-5-2.552-6.963L364.657,3.677
		c-0.033-0.031-0.064-0.042-0.085-0.075c-0.63-0.704-1.364-1.29-2.143-1.796c-0.229-0.154-0.461-0.283-0.702-0.418
		c-0.672-0.366-1.387-0.671-2.121-0.892c-0.2-0.055-0.379-0.134-0.577-0.188C358.23,0.118,357.401,0,356.562,0H96.757
		C84.894,0,75.256,9.649,75.256,21.502v174.616H62.09c-16.968,0-30.729,13.753-30.729,30.73v159.812
		c0,16.961,13.761,30.731,30.729,30.731h13.166V526.79c0,11.854,9.638,21.501,21.501,21.501h354.776
		c11.853,0,21.501-9.647,21.501-21.501V417.392h13.166c16.966,0,30.729-13.764,30.729-30.731V226.854
		C516.93,209.872,503.167,196.124,486.201,196.124z M96.757,21.502h249.054v110.006c0,5.943,4.817,10.751,10.751,10.751h94.972
		v53.864H96.757V21.502z M202.814,225.042h41.68l14.063,29.3c4.756,9.756,8.336,17.622,12.147,26.676h0.48
		c3.798-10.242,6.9-17.392,10.95-26.676l13.587-29.3h41.449l-45.261,78.363l47.638,82.185h-41.927l-14.525-29.06
		c-5.956-11.197-9.771-19.528-14.299-28.825h-0.478c-3.334,9.297-7.381,17.628-12.381,28.825l-13.336,29.06h-41.455l46.455-81.224
		L202.814,225.042z M66.08,255.532v-30.489h123.382v30.489h-43.828v130.049h-36.434V255.532H66.08z M451.534,520.962H96.757v-103.57
		h354.776V520.962z M471.764,255.532h-43.831v130.049h-36.442V255.532h-43.119v-30.489h123.393V255.532z"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  className="fs-10 w-10"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="100"
                  height="100"
                  viewBox="0 0 48 48"
                >
                  <rect
                    width="16"
                    height="9"
                    x="28"
                    y="15"
                    fill="#21a366"
                  ></rect>
                  <path
                    fill="#185c37"
                    d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"
                  ></path>
                  <rect
                    width="16"
                    height="9"
                    x="28"
                    y="24"
                    fill="#107c42"
                  ></rect>
                  <rect
                    width="16"
                    height="9"
                    x="12"
                    y="15"
                    fill="#3fa071"
                  ></rect>
                  <path
                    fill="#33c481"
                    d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"
                  ></path>
                  <path
                    fill="#21a366"
                    d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"
                  ></path>
                  <path
                    d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z"
                    opacity=".05"
                  ></path>
                  <path
                    d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z"
                    opacity=".07"
                  ></path>
                  <path
                    d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z"
                    opacity=".09"
                  ></path>
                  <linearGradient
                    id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1"
                    x1="4.725"
                    x2="23.055"
                    y1="14.725"
                    y2="33.055"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0" stop-color="#18884f"></stop>
                    <stop offset="1" stop-color="#0b6731"></stop>
                  </linearGradient>
                  <path
                    fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)"
                    d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z"
                  ></path>
                </svg>
              )}

              <div>{uploadExcel.name}</div>
            </div>
            <button
              icon="pi pi-trash"
              severity="danger"
              onClick={() => setUploadExcel("")}
              className="btn btn-danger rounded-xl  fs-20   mb-2"
            >
              <i class="uil uil-shopping-basket "></i>
            </button>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between mt-6">
          <div class="form-select-wrapper col-8 d-md-block d-none">
            <label className="text-orange ">
              {t("download sample excelsheet")}{" "}
            </label>
          </div>
          <div>
            <button
              label="Upload Excel"
              icon="pi pi-upload"
              className="btn btn-primary rounded-xl mx-1 fs-14"
              onClick={() => exportAllToExcel()}
            >
              {t("Download")}
              <i class="uil uil-import  mx-2"></i>
            </button>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-6">
          <div class="form-select-wrapper col-8 d-md-block d-none">
            <label className="text-orange ">{t("download sample text")} </label>
          </div>
          <div>
            <button
              label="Upload Excel"
              icon="pi pi-upload"
              className="btn btn-primary rounded-xl mx-1 fs-14"
              onClick={() => exportAllToPdf()}
            >
              {t("Download")}
              <i class="uil uil-import  mx-2"></i>
            </button>
          </div>
        </div>
      </>
    </Dialog>
  );
}

export default AddFileContact;
