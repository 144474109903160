import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Alert, Spinner } from "react-bootstrap";

function ResetPassword() {
  const [t] = useTranslation();

  //   const [error, serErrror] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm_password, setConfirmPassword] = useState();
  const [password, setPassword] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("tokenSms");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordShowConfirm, setPasswordShowConfirm] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    console.log(token);

    setLoading(true);
    try {
      const res = await axios.post(
        `https://togethersms.com/user/reset-password`,
        { password, confirmPassword: confirm_password },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 201) {
        //   dispatch(verifyEmail());
        navigate("/login");
        setLoading(false);
      } else {
        console.log(res);
        // setError(true);
      }
    } catch (error) {
      // setLoading(false);
      console.log(error);
      setLoading(false);
    }
  };
  const validatePassword = () => {
    if (password === confirm_password) {
      setMessage(false);
      handleSubmit();
    } else {
      setMessage(true);
      console.log("kjlk");
    }
  };
  return (
    <div className="content-wrapper">
      {/* <Header /> */}
      <section
        className="wrapper bg-soft-primary"
        // style={{ backgroundImage: img }}
      >
        <div className="container pt-5 text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h1 className="display-1 mb-3">{t("Reset Password")} </h1>
              <nav className="d-inline-block" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">{t("Home")}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {t("Reset Password")}
                  </li>
                </ol>
              </nav>
              {/* /nav */}
            </div>
            {/* /column */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>
      {/* /section */}

      <div className="container ">
        <div className="row">
          <div className="col-lg-7 col-xl-6 col-xxl-5 mx-auto ">
            <div className="card">
              <div className="card-body p-11 text-center">
                <form
                  className="text-start mb-3"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validatePassword();
                  }}
                >
                  {message && (
                    <Alert
                      color="danger"
                      className="bg-pale-orange text-red px-3"
                      style={{ marginTop: "13px" }}
                    >
                      {"Passwords do not match."}
                    </Alert>
                  )}
                  <div className="form-floating password-field mb-4 col-12 ">
                    <input
                      className="form-control"
                      id="loginPassword"
                      name="password"
                      //   value={validation.values.password || ""}
                      type={passwordShow ? "text" : "password"}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      {passwordShow ? (
                        <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                      ) : (
                        <i className="ri-eye-fill uil uil-eye align-middle"></i>
                      )}
                    </button>
                    <label htmlFor="loginPassword">{t("New Password")}</label>
                  </div>
                  <div className="form-floating password-field mb-4 col-12 ">
                    <input
                      className="form-control"
                      id="loginPassword"
                      name="password"
                      //   value={validation.values.password || ""}
                      type={passwordShowConfirm ? "text" : "password"}
                      placeholder="Enter Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() =>
                        setPasswordShowConfirm(!passwordShowConfirm)
                      }
                    >
                      {passwordShowConfirm ? (
                        <i className="ri-eye-fill uil uil-eye-slash align-middle"></i>
                      ) : (
                        <i className="ri-eye-fill uil uil-eye align-middle"></i>
                      )}
                    </button>
                    <label htmlFor="loginPassword">
                      {t("Confirm Password")}
                    </label>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                    disabled={loading ? true : false}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        {/* Loading...{" "} */}
                      </Spinner>
                    ) : null}
                    {t("Send")}
                  </button>
                </form>
                {/* /form */}

                {/*/.social */}
              </div>
              {/*/.card-body */}
            </div>
            {/*/.card */}
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
      {/* </section> */}
    </div>
  );
}

export default ResetPassword;
