import React, { useEffect, useState } from "react";

import Select from "react-select";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DataTableComponent from "../../components/DataTable";
// import { colourOptions } from "../data";

const options = [
  { value: "01019092115", label: "01019092115" },
  { value: "01019092116", label: "01019092116" },
  { value: "01019092117", label: "01019092117" },
  { value: "01019092117", label: "01019092117" },
  { value: "01019092117", label: "01019092117" },
  { value: "01019092117", label: "01019092117" },
  { value: "01019092117", label: "01019092117" },
  { value: "01019092117", label: "01019092117" },
];
export default function Individuals({ selectedOption, setSelectedOption,setModalOpened, modalOpened }) {
  const [openGroup, setOpenGroup] = useState(false);
  useEffect(() => {
    console.log(selectedOption);
  }, [selectedOption]);
  const [type, setType] = useState("coma");
      const [t, i18n] = useTranslation();

  return (
    <div className="container">
      <div className="row">
        {/* /column */}
        <div className="col-xl-12 order-2">
          <section id="snippet-6" className="wrapper ">
            <h2 className="mb-5">
              {t("Sending to specific individuals within a group")}
            </h2>
            <div className="card">
              <div className="card-body">
                <div className="form-select-wrapper">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={() => setOpenGroup(true)}
                  >
                    {/* <option selected>Open this select menu</option> */}
                    <option value={1}>One</option>
                    <option value={2}>Two</option>
                    <option value={3}>Three</option>
                  </select>
                </div>
              </div>
              {openGroup && (
                <div className="card-body">
                  <DataTableComponent />{" "}
                  <div className="form-floating mb-2 mt-5">
                    <textarea
                      id="textareaExample"
                      className="form-control"
                      placeholder="Textarea"
                      style={{ height: "150px" }}
                      required
                      defaultValue={""}
                      value={selectedOption?.map((e) => e.value)}
                    />
                    <label htmlFor="textareaExample">{t("Send to")}</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onClick={() => setType("coma")}
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      {" "}
                      {t("Coma")}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => setType("line")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault2"
                    >
                      {" "}
                      {t("New Line")}
                    </label>
                  </div>
                </div>
              )}
              <div className="card-body">
                <div
                  onClick={() => setModalOpened(false)}
                  class="btn btn-soft-yellow rounded-pill"
                >
                  {t("Ok")}
                </div>
              </div>

              {/*/.card-footer */}
            </div>
            {/*/.card */}
          </section>
        </div>

        {/* /column */}

        {/* /column */}
      </div>
      {/* /.row */}
    </div>
  );
}
