import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
const backendURL = "https://togethersms.com/";
export const loginUser = createAsyncThunk(
  "loginUser",
  async ({ user, setLoading, navigate }, thunkAPI) => {
    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${backendURL}user/login`, user, config);
      console.log(res);

      if (res.status === 201) {
        navigate("/");
        setLoading(false);
        return res.data;
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      return error;
    }
  }
);

export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (user, thunkAPI) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${backendURL}/logout`, user, config);
      // navigator("/home");
    } catch (error) {
      // return custom error message from backend if present
    }
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    msg: false,
    signIn: false,
    user: Cookies.get("userSms") ? JSON.parse(Cookies.get("userSms")) : null,
    token: Cookies.get("userSmsToken") || null,
    loading: false,
    error: false,
    verifyEmail: sessionStorage.getItem("verifyEmail")
      ? sessionStorage.getItem("verifyEmail")
      : false,
    vPhone: sessionStorage.getItem("vPhone")
      ? sessionStorage.getItem("vPhone")
      : false,
    questions: sessionStorage.getItem("questions")
      ? sessionStorage.getItem("questions")
      : false,
    data: false,
    admin: JSON.parse(sessionStorage.getItem("admin"))
      ? JSON.parse(sessionStorage.getItem("admin"))
      : [],

    // currentUser: null,
  },
  reducers: {
    loginGoogle: (state, actions) => {
      // sessionStorage.setItem("user", JSON.stringify(actions.payload));
      state.user = actions.payload;
    },
    loginStart: (state, actios) => {
      state.msg = false;
      Cookies.set("userSmsToken", actios.payload?.token, { expires: 0.5 });
      Cookies.set("userSms", JSON.stringify(actios.payload), {
        expires: 0.5,
      });
      sessionStorage.setItem("userSms", JSON.stringify(actios.payload));
      state.token = actios.payload?.token;

      sessionStorage.setItem("userSmsToken", actios.payload?.token);
      // state.token = payload.token;
      // sessionStorage.setItem("token", payload.token);

      state.user = actios.payload;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      Cookies.set("userSms", JSON.stringify(action.payload), { expires: 0.5 });
      sessionStorage.setItem("userSms", JSON.stringify(action.payload));
    },
    getData: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      Cookies.set("userSms", JSON.stringify(action.payload), { expires: 0.5 });

      sessionStorage.setItem("userSms", JSON.stringify(action.payload));
    },
    verifyEmail: (state, action) => {
      state.verifyEmail = true;
      sessionStorage.setItem("verifyEmail", true);
    },
    verifyPhone: (state, action) => {
      state.vPhone = true;
      sessionStorage.setItem("vPhone", true);
    },
    verifyQuestions: (state, action) => {
      state.questions = true;
      sessionStorage.setItem("questions", true);
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = true;
    },
    logout: (state, action) => {
      sessionStorage.removeItem("userSms");
      // sessionStorage.removeItem("CurrentStudy");
      sessionStorage.removeItem("tokenSms");
      state.user = "";
      Cookies.remove("userSms");
      Cookies.remove("userSmsToken");
    },
    adminVerfiy: (state, action) => {
      state.admin = action.payload;
      sessionStorage.setItem("admin", JSON.stringify(action.payload));
    },
    deleteSign: (state, action) => {
      state.signIn = false;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      if (
        payload?.message == "Login success" ||
        payload?.message == "Team member Login success"
      ) {
        state.msg = false;
        Cookies.set("userSmsToken", payload?.token, { expires: 0.5 });
        Cookies.set("userSms", JSON.stringify(payload), { expires: 0.5 });
        sessionStorage.setItem("userSms", JSON.stringify(payload));
        state.token = payload?.token;

        sessionStorage.setItem("userSmsToken", payload?.token);
        // state.token = payload.token;
        // sessionStorage.setItem("token", payload.token);

        state.user = payload;
      } else {
        state.msg = true;
        state.error = payload?.response?.data?.message;
      }

      state.success = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  getData,
  loginFailure,
  logout,
  loginGoogle,
  verifyPhone,
  deleteSign,
  verifyEmail,
  verifyQuestions,
  adminVerfiy,
} = authSlice.actions;
export default authSlice.reducer;
